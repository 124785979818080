import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useReducer,
} from "react";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { Navigate, useNavigate, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import { LAMPORTS_PER_SOL, PublicKey, Keypair } from "@solana/web3.js";
import moment from "moment";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Modal } from "react-bootstrap";
import S3 from "react-aws-s3";
import { PreLoader } from "../components/pre-loader";
import bacl_arrow_img from "../images/Arrow 3 (Stroke).png";
import nft_img from "../images/art.png";
import token_img from "../images/token.png";
import wallet_img from "../images/wallet.png";
import twitter_img from "../images/twitter.png";
import reach_us_img from "../images/contact-us.png";
import isURL from "validator/lib/isURL";
import discord_img from "../images/discord.png";
import { useStakedNfts } from "../hooks/useStakedNfts";
import { useUnstakedNfts } from "../hooks/useUnstakedNfts";

import {
  createRaffleInitTransaction,
  RaffleData,
  getAllTokens,
  getAllNFT,
} from "../util/raffle";

import "./create-raffle.css";

const hourMilliSeconds = 1000 * 3600;

const addDays = (d, num) => {
  let time = d.getTime();
  time += hourMilliSeconds * 24 * num;
  return new Date(time);
};

const getNearestHour = () => {
  const now = new Date().getTime();
  return new Date(now - (now % hourMilliSeconds) + hourMilliSeconds);
};

export const CreateRaffle = () => {
  const { connection } = useConnection();
  const { wallet } = useWallet();
  const { publicKey, sendTransaction } = useWallet();
  const navigate = useNavigate();
  const [refreshHandle, forceRefresh] = useReducer((x) => !x, true);
  const stakedNfts = useStakedNfts(refreshHandle);
  const unstakedNfts = useUnstakedNfts(refreshHandle);
  const [h, setH] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNFT, setIsLoadingNFT] = useState(false);
  const [isWhiteListed, setIsWhiteListed] = useState(false);
  const [raffleType, setraffleType] = useState("");
  const [raffleFor, setraffleFor] = useState("");
  const [raffleWord, setraffleWord] = useState("");
  const [raffleName, setraffleName] = useState("");
  const [DaoName, setDaoName] = useState("");
  const [DaoUrl, setDaoUrl] = useState("");
  const [priceType, setpriceType] = useState({});
  const [priceType1, setpriceType1] = useState({});
  const [priceTypeOption, setpriceTypeOption] = useState(0);
  const [priceTypeOption1, setpriceTypeOption1] = useState(0);
  const [ticketSupply, setticketSupply] = useState("");
  const [ticketThreshold, setticketThreshold] = useState(0);
  const [ticketType, setticketType] = useState("1");
  const [ticketPrice, setticketPrice] = useState("");
  const [ticketPrice1, setticketPrice1] = useState("");
  const [ticketPrice2, setticketPrice2] = useState("");
  const [ticketTokenAddress1, setticketTokenAddress1] = useState("");
  const [ticketTokenAddress2, setticketTokenAddress2] = useState("");
  const [maxTicketPerWallet, setmaxTicketPerWallet] = useState("");
  const [winnerCount, setwinnerCount] = useState<any | null>(null);
  const [priceAmountPerWinner, setpriceAmountPerWinner] = useState("");
  const [endTime, setendTime] = useState(addDays(getNearestHour(), 0));
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [disabledHours, setDisabledHours] = useState(
    setHours(setMinutes(new Date(), 0), 0)
  );
  const [whitelistedToken, setwhitelistedToken] = useState([]);
  const [selectedToken, setselectedToken] = useState("");
  const [selectedNFTCmid, setselectedNFTCmid] = useState("");
  const [selectedNFTImage, setselectedNFTImage] = useState("");
  const [currentUserType, setcurrentUserType] = useState("");

  const [discordLink, setdiscordLink] = useState("");
  const [twitterLink, settwitterLink] = useState("");

  const [NFTModal, setNFTModal] = useState(false);

  const [TokenModal, setTokenModal] = useState(false);
  const [selectedFilName, setselectedFilName] = useState("");
  const [uploadedFile, setuploadedFile] = useState();
  const [file, setFile] = useState<any | null>(null);
  const fileInput: any = useRef();
  const [fileNameFrReq, setFileNameFrReq] = useState<any | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [twitterName, setTwitterName] = useState("");
  const [creatorGuideModal, setcreatorGuideModal] = useState(false);
  const [rent, setRent] = useState(0);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  useEffect(() => {
    if (stakedNfts.length > 0 || unstakedNfts.length > 0) {
      setH(1);
    }
    // stakedNfts.map((nft, index) => {

    //   console.log(nft)
    // });

    // unstakedNfts.map((nft, index) => {
    //   console.log(nft)
    //   });
  }, [wallet, stakedNfts, unstakedNfts]);

  const ticket_list = JSON.parse(process.env.REACT_APP_TICKET_TOKEN);

  const ticket_list_1 = JSON.parse(process.env.REACT_APP_TICKET_TOKEN_1);

  const sendAndConfirmTransaction = useCallback(
    async (transaction) => {
      let { blockhash } = await connection.getRecentBlockhash();
      transaction.feePayer = publicKey!;
      transaction.recentBlockhash = blockhash;

      let signature = await sendTransaction(transaction, connection, {skipPreflight:false});
      await connection.confirmTransaction(signature, "confirmed");

      // if (onChange) onChange();

      return signature;
    },
    [connection, publicKey, sendTransaction]
  );

  const sendAndConfirmAccountTransaction = useCallback(
    async (transaction, dataAddress: Keypair) => {
      try {
        let { blockhash } = await connection.getRecentBlockhash();
        transaction.feePayer = publicKey!;
        transaction.recentBlockhash = blockhash;
        transaction.sign(dataAddress);

        let signature = await sendTransaction(transaction, connection, {skipPreflight:false});
        await connection.confirmTransaction(signature, "confirmed");

        // if (onChange) onChange();
        return signature;
      } catch (error: any) {
        setIsLoading(false);
        if (error.message !== "User rejected the request.") {
          if (error.message.indexOf("custom program error: 0x103") > -1) {
            Swal.fire({
              // title: "Error in transaction. Please try again later",
              text: "Raffle End Date should be minimum of 24 hour",
              confirmButtonText: "Close",
            });
          } else if (error.message.indexOf("custom program error: 0x1") > -1) {
            Swal.fire({
              // title: "Error in transaction. Please try again later",
              text: "You don't have enough SOL balance to create Raffle, Please try after adding the balance on your wallet",
              confirmButtonText: "Close",
            });
          } else {
            Swal.fire({
              // title: "Error in transaction. Please try again later",
              text: error.message,
              confirmButtonText: "Close",
            });
          }
        }
      }
    },
    [connection, publicKey, sendTransaction]
  );

  let moveStep = async (e) => {
    if (e === "step1") {
      setStep1(true);
      // setselectedFilName("");

      setStep2(false);
    } else if (e === "step2") {
      let error = false;
      if (raffleFor === "") {
        Swal.fire({
          text: "Please select raffle price type",
          confirmButtonText: "Close",
        });
        error = true;
      }
      if (raffleFor === "wallet" || raffleFor === "token") {
        if (raffleFor === "token" && selectedToken === "") {
          Swal.fire({
            text: "Please select the token",
            confirmButtonText: "Close",
          });
          error = true;
        }

        if (raffleName === "") {
          Swal.fire({
            text: "Raffle name can't be empty",
            confirmButtonText: "Close",
          });
          error = true;
          return;
        }

        if (winnerCount === "" || winnerCount === null) {
          Swal.fire({
            text: "Winner Count Can't be empty",
            confirmButtonText: "Close",
          });
          error = true;
          return;
        }

        if (winnerCount <= 0) {
          Swal.fire({
            text: "Winner Count Should be greater then zero",
            confirmButtonText: "Close",
          });
          error = true;
          return;
        }

        if (!file) {
          Swal.fire({
            text: "Please upload raffle image",
            confirmButtonText: "Close",
          });
          error = true;
          return;
        }
      }

      if (raffleFor === "wallet" || selectedToken !== "") {
        if (raffleFor !== "nft") {
          if (uploadedFile) {
            let newFileName;
            newFileName = Math.random() + "_" + uploadedFile["name"];

            setFileNameFrReq(newFileName);
          }

          // let file = fileInput!.current.files[0];
          // if (file !== undefined) {
          //   setFile(file);

          //   let newFileName = fileInput!.current.files[0].name.replace(
          //     /\..+$/,
          //     ""
          //   );
          //   newFileName = Math.random() + "_" + file.name;
          //   setFileNameFrReq(newFileName);
          // }
        }
        if (raffleFor === "nft") {
          await setwinnerCount("1");
          await setpriceAmountPerWinner("1");
        }
        if (raffleFor === "wallet") {
          await setpriceAmountPerWinner("1");
        }
        if (!error) {
          setStep1(false);
          setStep2(true);
        }
      } else {
        Swal.fire({
          text: "Please select the category",
          confirmButtonText: "Close",
        });
      }
    }
  };

  let onRaffleChange = async (e) => {
    setselectedToken("");
    setraffleType(e);
  };

  let onPriceTypeChange = async (e) => {
    setpriceType(ticket_list[e]);
    setpriceTypeOption(e);
  };

  let onPriceTypeChange1 = async (e) => {
    setpriceType1(ticket_list_1[e]);
    setpriceTypeOption1(e);
  };

  let onTicketTypeChange = async (e) => {
    // setpriceType(ticket_list[e]);
    // setpriceTypeOption(e);
    setticketType(e);
  };

  let onRaffleTypeChange = async (e) => {
    setraffleFor(e);
  };

  const validateUrl = (e) => {
    // setVal(e.target.value);
    if (isURL(e.target.value)) {
      setdiscordLink(e.target.value);
    } else {
      Swal.fire({
        text: "Invalid URL",
        confirmButtonText: "Close",
      });
    }
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(votingEndTime.toISOString());
    let isfileRquired = false;

    if (raffleFor === "token" || raffleFor === "wallet") {
      if (!isURL(discordLink)) {
        Swal.fire({
          text: "Invalid Discord URL",
          confirmButtonText: "Close",
        });
        return;
      }
      if (!isURL(twitterLink)) {
        Swal.fire({
          text: "Invalid Twitter URL",
          confirmButtonText: "Close",
        });
        return;
      }

      if (file) {
        isfileRquired = true;
      } else {
        isfileRquired = false;
      }
    } else {
      isfileRquired = true;
    }

    if (ticketType === "1") {
      if (Number(ticketPrice) <= 0) {
        Swal.fire({
          text: "Ticket price is invaild",
          confirmButtonText: "Close",
        });
        return;
      }
    }

    if (ticketType === "2") {
      if (Number(ticketPrice) <= 0) {
        Swal.fire({
          text: "Ticket price is invaild",
          confirmButtonText: "Close",
        });
        return;
      }

      if (Number(ticketPrice1) <= 0) {
        Swal.fire({
          text: "Ticket price 2 is invaild",
          confirmButtonText: "Close",
        });
        return;
      }
    }

    if (ticketSupply !== "" && Number(ticketSupply) > 5000) {
      Swal.fire({
        text: "Ticket size should be less then or equal to 5000",
        confirmButtonText: "Close",
      });
      return;
    }


    if (maxTicketPerWallet === "") {
      setmaxTicketPerWallet(ticketSupply);
    }

    if (
      ticketSupply !== "" &&
      ticketPrice !== "" &&
      // maxTicketPerWallet !== "" &&
      // winnerCount !== "" &&
      // priceAmountPerWinner !== "" &&
      isfileRquired
    ) {
      if (raffleFor === "token" || raffleFor === "wallet") {
        if (Number(winnerCount) > Number(ticketSupply)) {
          Swal.fire({
            text: "Ticket size should be greater then winner count",
            confirmButtonText: "Close",
          });
          return;
        }
      }

      if (ticketType === "2") {
        if (priceType["Token_name"] === priceType1["Token_name"]) {
          Swal.fire({
            text: "Ticket Price and Ticket Price 2 should be different token",
            confirmButtonText: "Close",
          });
          return;
        }
      }

      setIsLoading(true);
      let endTimeasms = Math.floor(moment(endTime).valueOf() / 1000);

      localStorage.setItem("raffleType", raffleType);
      localStorage.setItem("discordLink", discordLink);
      localStorage.setItem("twitterLink", twitterLink);

      localStorage.setItem("raffleName", raffleName);
      localStorage.setItem("raffleFor", raffleFor);
      localStorage.setItem("ticketSupply", ticketSupply);
      localStorage.setItem("ticketPrice", ticketPrice);

      if (maxTicketPerWallet === "") {
        localStorage.setItem("maxTicketPerWallet", ticketSupply);
      } else {
        localStorage.setItem("maxTicketPerWallet", maxTicketPerWallet);
      }

      localStorage.setItem("winnerCount", winnerCount);
      localStorage.setItem("priceAmountPerWinner", priceAmountPerWinner);
      localStorage.setItem("prize_token", selectedToken);
      localStorage.setItem("priceType", JSON.stringify(priceType));
      localStorage.setItem("daoName", DaoName);
      localStorage.setItem("daoUrl", DaoUrl);
      if (raffleFor === "nft") {
        localStorage.setItem("cmid", selectedNFTCmid);
        localStorage.setItem("nftId", selectedToken);
        console.log(selectedNFTImage)
        let img = await fetch(selectedNFTImage);
        let img_n = (Math.random() + 1).toString(36).substring(2);

        let imageBlog = await img.blob();

        let img_t = imageBlog["type"].split("/");
        img_n = img_n + "." + img_t[1];

        localStorage.setItem("fileNameFrReq", selectedNFTImage);
        const config = {
          bucketName: process.env.REACT_APP_BUCKET_NAME,
          region: process.env.REACT_APP_REGION,
          accessKeyId: process.env.REACT_APP_ACCESS_ID,
          secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
          s3Url: process.env.REACT_APP_S3URL,
        };

        const ReactS3Client = new S3(config);
        // ReactS3Client.uploadFile(imageBlog, img_n).then((data) => {
        //   if (data.status === 204) {
        //     console.log("success");
        //     uploadToDb(
        //       endTimeasms,
        //       ticketPrice,
        //       ticketPrice1,
        //       ticketSupply,
        //       selectedToken,
        //       priceType,
        //       priceType1,
        //       ticketType,
        //       twitterName,
        //       selectedNFTCmid,
        //       imageBlog
        //     );
        //   } else {
        //     console.log("fail");
        //     Swal.fire({
        //       text: "Image not uploaded. please try again",
        //       confirmButtonText: "Close",
        //     });
        //   }
        // });
        uploadToDb(
                endTimeasms,
                ticketPrice,
                ticketPrice1,
                ticketSupply,
                selectedToken,
                priceType,
                priceType1,
                ticketType,
                twitterName,
                selectedNFTCmid,
                selectedNFTImage
              );
      } else {
        localStorage.setItem("nftId", "");
        localStorage.setItem("fileNameFrReq", fileNameFrReq);
        if (file) {
          const config = {
            bucketName: process.env.REACT_APP_BUCKET_NAME,
            region: process.env.REACT_APP_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_ID,
            secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
            s3Url: process.env.REACT_APP_S3URL,
          };

          const ReactS3Client = new S3(config);
          ReactS3Client.uploadFile(uploadedFile, fileNameFrReq).then((data) => {
            if (data.status === 204) {
              console.log("success");

              uploadToDb(
                endTimeasms,
                ticketPrice,
                ticketPrice1,
                ticketSupply,
                selectedToken,
                priceType,
                priceType1,
                ticketType,
                twitterName,
                selectedNFTCmid,
                uploadedFile
              );
            } else {
              console.log("fail");
              Swal.fire({
                text: "Image not uploaded. please try again",
                confirmButtonText: "Close",
              });
            }
          });
          // uploadToDb(
          //   endTimeasms,
          //   ticketPrice,
          //   ticketPrice1,
          //   ticketSupply,
          //   selectedToken,
          //   priceType,
          //   priceType1,
          //   ticketType,
          //   twitterName,
          //   selectedNFTCmid,
          //   uploadedFile
          // );
        }
      }
    } else {
      Swal.fire({
        text: "Some fields are empty or invalid.Please review all the fields",
        confirmButtonText: "Close",
      });
      setIsLoading(false);
    }
  };

  let uploadToDb = async (
    endTimeasms,
    ticketPrice,
    ticketPrice1,
    ticketSupply,
    selectedToken,
    priceType,
    priceType1,
    ticketType,
    t_name,
    cmid,
    file
  ) => {
    try {
      let r_name = localStorage.getItem("raffleName");
      if (raffleFor === "wallet") {
        r_name =
          localStorage.getItem("raffleName") +
          " - " +
          localStorage.getItem("winnerCount") +
          " SPOTS";
      }

      let ticket_price = 0;
      let ticket_price_1 = 0;
      let ticket_prize_type;
      let ticket_prize_type_1;
      let ticket_type;
      if (ticketType === "1") {
        ticket_price = ticketPrice;
        ticket_price_1 = 0;
        ticket_prize_type = priceType.Token_name;
        ticket_prize_type_1 = "";
        ticket_type = "single";
      } else {
        ticket_price = ticketPrice;
        ticket_price_1 = ticketPrice1;
        ticket_prize_type = priceType.Token_name;
        ticket_prize_type_1 = priceType1.Token_name;
        ticket_type = "multi";
      }

      const form_data = new FormData();
      form_data.append("attachemnet", file);
      form_data.append("raffleName", r_name);
      form_data.append("raffleType", localStorage.getItem("raffleType"));
      form_data.append("rafflePriceType", localStorage.getItem("raffleFor"));
      form_data.append("creatorWalletAddress", publicKey.toString());
      form_data.append("individualNameorAddress", publicKey.toString());
      form_data.append("nftId", localStorage.getItem("prize_token"));
      form_data.append(
        "projectTwitterLink",
        localStorage.getItem("twitterLink")
      );
      form_data.append(
        "projectDiscordLink",
        localStorage.getItem("discordLink")
      );
      form_data.append("nft_mint_address", selectedToken);
      form_data.append("tickt_size", ticketSupply);
      form_data.append("ticket_type", ticket_type);

      form_data.append("ticket_prize", ticketPrice);
      form_data.append("ticket_prize_1", ticket_price_1.toString());

      form_data.append("ticket_prize_type", ticket_prize_type);
      form_data.append("ticket_prize_type_1", ticket_prize_type_1);

      form_data.append("raffleEndTime", endTimeasms.toString());
      form_data.append("isH", "0");
      form_data.append("creatorTwitterId", t_name);
      form_data.append("nft_cmid_address", cmid);

      let percent_of_ticket_supply =
        (60 / 100) * Number(localStorage.getItem("ticketSupply"));
      let threshold_tickets = 900;
      if (percent_of_ticket_supply < threshold_tickets) {
        threshold_tickets = percent_of_ticket_supply;
      }

      let response = await fetch(
        process.env.REACT_APP_API_URL + "getRaffleWord",
        {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          // body: form_data
          body: JSON.stringify({
            raffleName: r_name,
            raffleType: localStorage.getItem("raffleType"),
            attachemnet: localStorage.getItem("fileNameFrReq"),
            rafflePriceType: localStorage.getItem("raffleFor"),
            creatorWalletAddress: publicKey.toString(),
            daoName: localStorage.getItem("daoUrl"),
            nftId: localStorage.getItem("prize_token"),
            projectTwitterLink: localStorage.getItem("twitterLink"),
            projectDiscordLink: localStorage.getItem("discordLink"),
            nft_mint_address: selectedToken,
            tickt_size: ticketSupply,
            ticket_type: ticket_type,
            ticket_prize: ticketPrice,
            ticket_prize_1: ticket_price_1,
            ticket_prize_type: ticket_prize_type,
            ticket_prize_type_1: ticket_prize_type_1,
            raffleEndTime: endTimeasms.toString(),
            isH: 0,
            creatorTwitterId: t_name,
            nft_cmid_address: cmid,
            threshold: threshold_tickets
          }),
        }
      );

      const data = await response.json();

      setraffleWord(data);
      localStorage.setItem("raffleword", data);
      localStorage.setItem("endTime", endTimeasms.toString());
      setIsLoading(true);
      await RaffleInit(
        data["raffle_word"],
        data["id"],
        ticketPrice,
        ticketPrice1,
        priceType,
        priceType1,
        ticketType,
        selectedToken
      );
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const RaffleInit = useCallback(
    async (
      r_word,
      r_id,
      t_price,
      t_price1,
      p_type,
      p_type1,
      t_type,
      s_token
    ) => {
      if (!publicKey) return;
      var dataAddress;
      dataAddress = Keypair.generate();

      let isDao = 0;
      let hasEscrow = 0;
      let checkWLtoken;
      let prize_token;
      let prize_amount;
      let priceType = JSON.parse(localStorage.getItem("priceType"));

      if (localStorage.getItem("raffleType") === "dao") {
        isDao = 1;
      }
      if (localStorage.getItem("raffleFor") === "token") {
        hasEscrow = 1;
        checkWLtoken = new PublicKey(s_token);
        prize_token = new PublicKey(s_token);
        prize_amount =
          Number(localStorage.getItem("priceAmountPerWinner")) * 1000000000;
      }
      if (localStorage.getItem("raffleFor") === "nft") {
        hasEscrow = 1;

        checkWLtoken = new PublicKey(localStorage.getItem("cmid"));

        prize_token = new PublicKey(s_token);
        prize_amount = Number(localStorage.getItem("priceAmountPerWinner"));
      }
      if (localStorage.getItem("raffleFor") === "wallet") {
        checkWLtoken = new PublicKey(process.env.REACT_APP_WALLET_PRICE_KEY);
        prize_token = new PublicKey(process.env.REACT_APP_WALLET_PRICE_KEY);
        prize_amount = Number(localStorage.getItem("priceAmountPerWinner"));
      }

      let ticket_price = 0;
      let ticket_price_1 = 0;
      let ticket_price_2 = 0;
      let ticket_token_1;
      let ticket_token_2;

      if (t_type === "1") {
        if (p_type.Token_name === "SOL") {
          ticket_price = Number(t_price * Math.pow(10, 9));
          ticket_price_1 = 0;
          ticket_price_2 = 0;
          ticket_token_1 = p_type1.Address;
          ticket_token_2 = p_type1.Address;
        } else {
          ticket_price = 0;
          ticket_price_1 = Number(t_price) * Math.pow(10, p_type["Decimal"]);
          ticket_price_2 = 0;
          ticket_token_1 = p_type.Address;
          ticket_token_2 = p_type1.Address;
        }
      } else {
        if (p_type.Token_name === "SOL") {
          ticket_price = Number(t_price * Math.pow(10, 9));
          ticket_price_1 = Number(t_price1) * Math.pow(10, p_type1["Decimal"]);
          ticket_price_2 = 0;
          ticket_token_1 = p_type1.Address;
          ticket_token_2 = p_type1.Address;
        } else {
          ticket_price = 0;
          ticket_price_1 = Number(t_price) * Math.pow(10, p_type["Decimal"]);
          ticket_price_2 = Number(t_price1) * Math.pow(10, p_type1["Decimal"]);
          ticket_token_1 = p_type.Address;
          ticket_token_2 = p_type1.Address;
        }
      }
      let sig: any;

      let percent_of_ticket_supply =
        (60 / 100) * Number(localStorage.getItem("ticketSupply"));
      let threshold_tickets = 900;
      if (percent_of_ticket_supply < threshold_tickets) {
        threshold_tickets = percent_of_ticket_supply;
      }

      sig = await sendAndConfirmAccountTransaction(
        await createRaffleInitTransaction(
          connection,
          publicKey,
          r_word,
          Number(localStorage.getItem("winnerCount")),
          prize_amount,
          localStorage.getItem("endTime"),
          Number(localStorage.getItem("ticketSupply")),
          ticket_price,
          ticket_price_1,
          ticket_price_2,
          Number(localStorage.getItem("maxTicketPerWallet")),
          isDao,
          hasEscrow,
          new PublicKey(ticket_token_1),
          new PublicKey(ticket_token_2),
          prize_token,
          dataAddress.publicKey,
          checkWLtoken,
          0,
          0,
          new PublicKey("2a2aGyHFkGqsDeK8T9WHqsF7MYa7wJ9XWhfvKAixehDj"),
          threshold_tickets
        ),
        dataAddress
      );
      if (sig) {
        let access_res = await fetch(
          process.env.REACT_APP_API_URL + "updateRaffle",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({
              raffleWord: r_word,
              id: r_id,
              transactionHash: sig,
            }),
          }
        );
        let access_key = await access_res.json();
        setIsLoading(false);
        Swal.fire({
          text: "Your Raffle has been created.",
          confirmButtonText: "Click to view Raffle",
        }).then((sig) => {
          navigate("/raffle/" + r_id, {
            replace: true,
          });
        });
      }
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  const handleCloseNFTModal = () => {
    setNFTModal(false);
    setselectedToken("");
    setraffleFor("");
  };

  const handleCloseTokenModal = () => {
    setTokenModal(false);
    setselectedToken("");
    setraffleFor("");
  };

  const get_raffle_word = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getRaffleWord",
        { mode: "cors" }
      );
      const data = await response.text();

      setraffleWord(data);
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmitTokenModal = () => {
    if (selectedToken === "") {
      Swal.fire({
        text: "Please select the NFT to create raffle",
        confirmButtonText: "Close",
      });
    } else {
      setTokenModal(false);
      setNFTModal(false);
    }
  };

  let onChooseToken = async (type) => {
    setraffleFor(type);
    let res;
    if (type === "nft") {
      setIsLoadingNFT(true);
      res = await getAllNFT(connection, publicKey);

      setwhitelistedToken(res);
      setIsLoadingNFT(false);
    } else if (type === "token") {
      res = await getAllTokens(connection, publicKey);

      setwhitelistedToken(res);
    } else if (type === "wallet") {
      setselectedToken("");
    }
  };

  const handleImageUpload = (event) => {
    if (event.target.files.length > 0) {
      let fileExtension = event.target.files[0].name.substring(
        event.target.files[0].name.lastIndexOf(".") + 1
      );

      if (
        fileExtension.toLowerCase() === "gif" ||
        fileExtension.toLowerCase() === "jpg" ||
        fileExtension.toLowerCase() === "jpeg" ||
        fileExtension.toLowerCase() === "png"
      ) {
        setuploadedFile(event.target.files[0]);
        setselectedFilName(event.target.files[0].name);
        setFile(event.target.files[0].name);
      } else {
        event.target.value = null;
        Swal.fire({
          text: "Please select the gif/jpg/jpeg/png format image",
          confirmButtonText: "Close",
        });
      }
    }
  };

  useEffect(() => {
    async function data() {
      setpriceTypeOption(0);

      await setpriceType(ticket_list[0]);
      await setpriceType1(ticket_list_1[0]);
      await get_whitelst_data();
    }
    data();
  }, [publicKey]);

  const get_whitelst_data = async () => {
    if (!publicKey) return;
    setIsLoading(true);
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getWhitelist/" + publicKey.toString(),
        { mode: "cors" }
      );

      const data = await response.json();
      if (response.status === 404) {
        setIsWhiteListed(false);
      }
      if (response.status === 200) {
        setIsWhiteListed(true);
      }
      if (data["addressType"] !== "") {
        if (data["addressType"] === "dao") {
          setDaoName(data["daoName"]);
          setDaoUrl(data["daoUrl"]);
          setTwitterName(data["twitterId"]);
          await onRaffleChange(data["addressType"]);
        }
        setcurrentUserType(data["addressType"]);
      }
      setIsLoading(false);
      return data;
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  const contact_us = () => {
    window.open(
      "https://twitter.com/TheVaultDAO",
      "_blank",
      "noopener,noreferrer"
    );
  };

  let show_threshold = async (ticket_size) => {
    let percent_of_ticket_supply = (60 / 100) * ticket_size;
    let threshold_tickets = 900;
    if (percent_of_ticket_supply < threshold_tickets) {
      threshold_tickets = percent_of_ticket_supply;
    }

    setticketThreshold(threshold_tickets);
  };

  const authTwitter = async () => {
    navigate("/my-account");
  };

  const rentCalculation = async (ticket_s) => {
    let size = 8 + 32 + 32 + 32 * ticket_s + 8 + 32 * 1 + 8 + 32 * 1 + 24;
    let size2 =
      8 +
      32 +
      32 +
      1 +
      1 +
      8 +
      8 +
      8 +
      8 +
      8 +
      8 +
      8 +
      32 +
      32 +
      32 +
      8 +
      8 +
      1 +
      1 +
      32 +
      8 +
      8;
    let rent = await connection.getMinimumBalanceForRentExemption(size + size2);
    let r = rent / Math.pow(10, 9);
    setRent(r);
  };

  const handleCloseCreatorGuideModal = () => {
    setcreatorGuideModal(false);
  };

  return (
    <>
      {publicKey ? (
        <>
          <Modal
            show={NFTModal}
            onHide={handleCloseNFTModal}
            backdrop="static"
            className="nft-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Choose NFT for Raffle Prize
                <div className="sub-note">
                  If you do not find the NFT below to choose the{" "}
                  <a
                    className="msg-note"
                    href="https://twitter.com/TheVaultDAO"
                    rel="noreferrer"
                    target="_blank"
                  >
                    contact us
                  </a>{" "}
                  to whitelist the same{" "}
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isLoadingNFT && <PreLoader />}
              {!isLoadingNFT && (
                <form>
                  <div className="row g-3 g-lg-6">
                    {whitelistedToken.map((token, index) => (
                      <div className="col-md-3">
                        <a>
                          <input
                            type="radio"
                            name="emotion"
                            id={token["address"]}
                            className="input-hidden"
                            onChange={() => {
                              setraffleName(token?.name);
                              setselectedToken(token["address"]);
                              setselectedNFTCmid(token["cmid"]);
                              setselectedNFTImage(token["image"]);
                            }}
                          />
                          <label htmlFor={token.address}>
                            <img
                              alt=""
                              height="150"
                              src={token?.image}
                              width="150"
                            />
                          </label>
                          <div className="text-center m-5">
                            <span className="fs-4 text-white fw-bold">
                              {token?.name}
                            </span>
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                </form>
              )}
            </Modal.Body>
            {!isLoadingNFT && (
              <Modal.Footer>
                <div className="text-center mt-9">
                  <button
                    type="button"
                    className="btn modal-btn-cancel me-3"
                    onClick={handleCloseNFTModal}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSubmitTokenModal}
                    type="button"
                    className="btn modal-btn-submit"
                    data-kt-modal-action-type="submit"
                  >
                    <span className="indicator-label">Select</span>
                  </button>
                </div>
              </Modal.Footer>
            )}
          </Modal>
          <Modal
            show={TokenModal}
            onHide={handleCloseTokenModal}
            backdrop="static"
            className="token-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Choose Token for Raffle Prize
                <div className="msg-note">
                  Contact us to get your Token verified{" "}
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <div className="row g-3 g-lg-6">
                  {whitelistedToken.map((token, index) => (
                    <div className="col-md-4">
                      <a>
                        <input
                          type="radio"
                          name="emotion"
                          id={token["address"]}
                          className="input-hidden"
                          onChange={() => setselectedToken(token["address"])}
                        />
                        <label htmlFor={token.address}>
                          <img
                            alt=""
                            height="150"
                            src={token?.image}
                            width="150"
                          />
                        </label>
                        <div className="text-center m-5">
                          <span className="fs-6 text-gray-800 text-hover-primary fw-bold wl-token-name">
                            {token?.name}
                          </span>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
                <div className="text-center mt-9">
                  <button
                    type="button"
                    className="btn modal-btn-cancel me-3"
                    onClick={handleCloseTokenModal}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSubmitTokenModal}
                    type="button"
                    className="btn modal-btn-submit"
                    data-kt-modal-action-type="submit"
                  >
                    <span className="indicator-label">Select</span>
                  </button>
                </div>
              </form>
            </Modal.Body>
          </Modal>

          <Modal
            show={creatorGuideModal}
            onHide={handleCloseCreatorGuideModal}
            backdrop="static"
            className="token-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Creator’s Guide for Raffle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="msg-note">Terms & Conditions</div>
              <div className="guide-container">
                <ol>
                  <li>
                    When you create a raffle, the NFT prize you have chosen will
                    be transferred from your wallet into an escrow wallet.
                  </li>
                  <li>You can choose to sell up to 5000 tickets per raffle.</li>
                  <li>
                    You can choose SOL or SOL + $SPL Token as ticket price.
                  </li>
                  <li>
                    You will be charged an up-front rent fee in SOL, which will
                    be taken in proportion to the number of tickets you choose
                    to raffle. The rent fee is refundable after the raffle is
                    closed.
                  </li>
                  <li>
                    You can specify the amount of time a raffle runs at the
                    creation of the raffle. Raffles minimum run time is 24
                    hours.
                  </li>
                  <li>
                    {" "}
                    Raffle platform fee is 3% from the ticket sales, Vault-x
                    holders will get a 100% fee waiver for staking or holding a
                    Vault-x NFT.
                  </li>
                  <li> Raffle CANNOT be edited once created.</li>
                  <li>
                    {" "}
                    When the raffle draw ends, you will have an option to reveal
                    the winner otherwise it will be revealed automatically
                    within 24 hours.{" "}
                  </li>
                  <li>
                    Raffle Cancellation:
                    <ol>
                      <li>
                        You can cancel the raffle if no tickets are sold, your
                        NFT and rent fee will be returned back to you.
                      </li>
                      <li>
                        If the ticket sales is less than the minimum threshold
                        value (60% of ticket supply with max cap of 900 tickets
                        ) then you will have option to cancel raffle by paying
                        0.2 SOL penalty fees and your NFT will be returned back
                        to your wallet. Participants can claim their refunds as
                        per tickets bought. When all the participants claim
                        their refunds then you can close the raffle and take
                        back your rent fees.
                      </li>
                    </ol>
                  </li>
                  <li>
                    {" "}
                    Raffle, its agents, directors, or officers shall not assume
                    any liability or responsibility for your use of Raffle,
                    promoting or marketing the raffles.
                  </li>
                  <li>
                    {" "}
                    If you are not able to choose your NFT for Raffle due to NFT
                    Collection is not verified or any other reason, please
                    contact {""}{" "}
                    <img width="22px" alt="discord_img" src={discord_img} />
                    <span className="discord-id">
                      {" "}
                      Jack_Reacher_VaultX#7528
                    </span>{" "}
                    for immediate assistance.{" "}
                  </li>
                  <li>
                    {" "}
                    Please link your twitter for better visibility. Once Raffle
                    is created then it will be automatically posted at{" "}
                    <a
                      href="https://twitter.com/Raffle_X_live"
                      rel="noreferrer"
                      className="btn twiiter-connect-btn me-2"
                      target="_blank"
                    >
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="inline-block w-6 ml-2 text-white-40 fill-current"
                      >
                        <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                      </svg>
                      Raffle_X_live
                    </a>
                    Follow this twitter for more updates.{" "}
                  </li>
                </ol>
              </div>
            </Modal.Body>
          </Modal>
          {isLoading && <PreLoader />}
          {!isLoading && (
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start  create-raffle-page-container"
            >
              <div
                className={`${
                  isMobile
                    ? "px-5"
                    : "d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep first"
                }`}
              >
                <div className="d-flex flex-column flex-lg-row-fluid py-10">
                  <div className="d-flex flex-center flex-column flex-column-fluid">
                    <div className="w-lg-800px w-xl-900px p-10 p-lg-15 mx-auto card create-raffle-card">
                      {!isWhiteListed && (
                        <>
                          <div className="">
                            <img
                              className="reach-us-img"
                              alt=""
                              src={reach_us_img}
                            />
                            <div className="reach-us-to-whitelist">
                              Oops ! Your Wallet is not Whitelisted for any
                              DAO's,{" "}
                              <span
                                className="reach-us-link"
                                onClick={contact_us}
                              >
                                Reach us
                              </span>{" "}
                              to get your DAO verified
                              {/* <a
                                href="https://twitter.com/TheVaultDAO"
                                rel="noreferrer"
                                target="_blank"
                                className="reach-us-link"
                              >
                                {" "}
                                Reach us
                              </a> */}
                            </div>
                          </div>
                        </>
                      )}
                      {isWhiteListed && (
                        <form
                          onSubmit={handleSubmit}
                          className="my-auto pb-5 fv-plugins-bootstrap5 fv-plugins-framework"
                          id="kt_create_account_form"
                        >
                          <div
                            className="creator-guide"
                            onClick={() => {
                              setcreatorGuideModal(true);
                            }}
                          >
                            <i className="bi bi-info-square"></i> Creator guide
                          </div>
                          {step1 && (
                            <div className="current step-1">
                              <div className="w-100">
                                <div className="pb-10 pb-lg-15">
                                  <h2 className="f-size-30 d-flex align-items-center text-white">
                                    Create New Raffle
                                  </h2>
                                </div>
                                {currentUserType === "dao" && (
                                  <div className="row mb-7">
                                    <div className="col-lg-6">
                                      <label className="d-flex align-items-center fs-6 fw-semibold mb-2 create-raffle-label">
                                        <span className="">DAO Name</span>
                                      </label>

                                      <div className="f-size-20 text-white">
                                        {DaoName}
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <label className="d-flex align-items-center fs-6 fw-semibold mb-2 create-raffle-label">
                                        <span className="">Created By</span>
                                      </label>

                                      {twitterName !== "" ? (
                                        <div className="f-size-20 twitter-user-name">
                                          <a
                                            href={
                                              "https://twitter.com/" +
                                              twitterName
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {twitterName}
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 512 512"
                                              className="inline-block w-6 ml-2 text-blue-400 fill-current"
                                            >
                                              <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                                            </svg>
                                          </a>
                                        </div>
                                      ) : (
                                        <div>
                                          <button
                                            onClick={authTwitter}
                                            className="btn twiiter-connect-btn me-2"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 512 512"
                                              className="inline-block w-6 ml-2 text-white-40 fill-current"
                                            >
                                              <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                                            </svg>
                                            Link Twiiter
                                          </button>
                                        </div>
                                        // <div className="f-size-20 text-white">
                                        //   {" "}
                                        //   Please click{" "}
                                        //   <Link
                                        //     to={"/my-account"}
                                        //     className="link"
                                        //   >
                                        //     here
                                        //   </Link>{" "}
                                        //   link your Twitter
                                        // </div>
                                      )}
                                    </div>
                                  </div>
                                )}

                                {currentUserType !== "dao" && (
                                  <div
                                    className="fv-row mb-8 fv-plugins-icon-container"
                                    data-select2-id="select2-data-122-epah"
                                  >
                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2 create-raffle-label">
                                      <span className="required">
                                        Raffle Type
                                      </span>
                                    </label>

                                    <select
                                      className="form-select form-select-solid"
                                      name="created_for"
                                      onChange={(e) =>
                                        onRaffleChange(e.target.value)
                                      }
                                    >
                                      {/* <option value="seller">Seller</option> */}
                                      <option>Select raffle type</option>
                                      <option value="individual">
                                        Individual
                                      </option>
                                      <option value="dao">DAO</option>
                                    </select>

                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                  </div>
                                )}
                                <div className="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                  {(raffleType === "individual" ||
                                    raffleType === "dao") && (
                                    <label className="d-flex align-items-center fs-6 fw-semibold mb-2  create-raffle-label">
                                      <span className="required">
                                        Raffle Type
                                      </span>
                                    </label>
                                  )}

                                  <div className="row">
                                    {(raffleType === "individual" ||
                                      raffleType === "dao") && (
                                      <div
                                        className="col-lg-6"
                                        onClick={() => {
                                          setNFTModal(true);
                                          onChooseToken("nft");
                                        }}
                                      >
                                        <input
                                          type="radio"
                                          className="btn-check"
                                          name="account_type"
                                          value="nft"
                                          id="individual"
                                          checked={raffleFor === "nft"}
                                        />
                                        <label className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10">
                                          <span className="svg-icon svg-icon-3x me-5">
                                            <img src={nft_img} />
                                          </span>

                                          <span className="d-block fw-semibold text-start">
                                            <span className="text-white fw-bold d-block f-size-20 mb-2">
                                              NFT
                                            </span>
                                          </span>
                                        </label>

                                        <div className="fv-plugins-message-container invalid-feedback"></div>
                                      </div>
                                    )}
                                    {/* {(raffleType === "individual" ||
                                raffleType === "dao") && (
                                <div
                                  className="col-lg-4"
                                  onClick={() => {
                                    setTokenModal(true);
                                    onChooseToken("token");
                                  }}
                                >
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name="account_type"
                                    value="dao"
                                    checked={raffleFor === "token"}
                                    id="token"
                                  />
                                  <label
                                    className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center"
                                    htmlFor="kt_create_account_form_account_type_corporate"
                                  >
                                    <span className="svg-icon svg-icon-3x me-5">
                                      <img src={token_img} />
                                    </span>

                                    <span className="d-block fw-semibold text-start">
                                      <span className="text-white fw-bold d-block fs-4 mb-2">
                                        Choose Token
                                      </span>
                                    </span>
                                  </label>
                                </div>
                              )} */}
                                    {raffleType === "dao" && (
                                      <div
                                        className="col-lg-6"
                                        onClick={() => {
                                          onChooseToken("wallet");
                                        }}
                                      >
                                        <input
                                          type="radio"
                                          className="btn-check"
                                          name="account_type"
                                          value="wallet"
                                          checked={raffleFor === "wallet"}
                                          id="wallet"
                                        />
                                        <label
                                          className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center"
                                          htmlFor="kt_create_account_form_account_type_corporate"
                                        >
                                          <span className="svg-icon svg-icon-3x me-5">
                                            <img src={wallet_img} />
                                          </span>

                                          <span className="d-block fw-semibold text-start">
                                            <span className="text-white fw-bold d-block f-size-20 mb-2">
                                              Whitelist
                                            </span>
                                          </span>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                  {/* {selectedToken === "" && (
                            <div
                              className="row mb-2"
                              data-kt-buttons="true"
                              data-kt-initialized="1"
                            >
                              {(raffleType === "individual" ||
                                raffleType === "dao") && (
                                <div className="col">
                                  <button
                                    type="button"
                                    className="btn btn-primary me-3"
                                    id="kt_file_manager_new_folder"
                                    onClick={() => {
                                      setTokenModal(true);
                                      onChooseToken("nft");
                                    }}
                                  >
                                    Add NFT
                                  </button>
                                </div>
                              )}
                              {(raffleType === "individual" ||
                                raffleType === "dao") && (
                                <div className="col">
                                  <button
                                    type="button"
                                    className="btn btn-primary me-3"
                                    id="kt_file_manager_new_folder"
                                    onClick={() => {
                                      setTokenModal(true);
                                      onChooseToken("token");
                                    }}
                                  >
                                    Add Whitlisted Token
                                  </button>
                                </div>
                              )}
                              {raffleType === "dao" && (
                                <div className="col">
                                  <button
                                    type="button"
                                    className="btn btn-primary me-3"
                                    id="kt_file_manager_new_folder"
                                    onClick={() => {
                                      setTokenModal(true);
                                      onChooseToken("wallet");
                                    }}
                                  >
                                    Add Whitlisted Wallat
                                  </button>
                                </div>
                              )}
                            </div>
                          )} */}
                                  {selectedToken !== "" && (
                                    <div className="row mb-7">
                                      <div className="col-lg-12">
                                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2 create-raffle-label">
                                          <span className="required">
                                            Selected NFT
                                          </span>
                                        </label>

                                        <div className="fs-5 text-white">
                                          {selectedToken}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {/* <div className="mt-5">
                            {raffleFor === "NFT" && (
                              <button
                                type="button"
                                className="btn btn-primary me-3"
                                id="kt_file_manager_new_folder"
                                onClick={() => setNFTModal(true)}
                              >
                                <span className="svg-icon svg-icon-2">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      opacity="0.3"
                                      d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </span>
                                Add NFT
                              </button>
                            )}
                             {raffleFor === "whitelist_by_token" && (
                              <button
                                type="button"
                                className="btn btn-primary me-3"
                                id="kt_file_manager_new_folder"
                                onClick={() => {setTokenModal(true); onChooseToken();}}
                              >
                                <span className="svg-icon svg-icon-2">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      opacity="0.3"
                                      d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </span>
                                Add Token
                              </button>
                            )}
                          </div> */}
                                  {(raffleFor === "token" ||
                                    raffleFor === "wallet") && (
                                    <div className="row fv-row mb-7 fv-plugins-icon-container">
                                      <div className="col-xl-12">
                                        <label className="form-label mb-3 create-raffle-label">
                                          Raffle Name
                                        </label>

                                        <input
                                          type="text"
                                          className="form-control form-control-lg form-control-solid"
                                          value={raffleName}
                                          onChange={(e) =>
                                            setraffleName(e.target.value)
                                          }
                                          placeholder=""
                                        />
                                      </div>
                                    </div>
                                  )}

                                  {(raffleFor === "token" ||
                                    raffleFor === "wallet") && (
                                    <div className="row row-cols-1 row-cols-sm-1 rol-cols-md-1 row-cols-lg-1 ">
                                      <div className="col-xl-6">
                                        <label className="form-label mb-3  create-raffle-label">
                                          Winner Count
                                        </label>

                                        <input
                                          type="number"
                                          min="1"
                                          className="form-control form-control-lg form-control-solid"
                                          value={winnerCount}
                                          onChange={(e) =>
                                            setwinnerCount(e.target.value)
                                          }
                                          placeholder=""
                                        />
                                      </div>
                                      <div className="col-xl-6">
                                        <div className="image-upload d-flex flex-column fv-row mb-8">
                                          <label htmlFor="file-input text-left mb-10">
                                            <span className="fs-6 fw-bold required create-raffle-label">
                                              Raffle Image/Gif
                                            </span>
                                          </label>
                                          <div className="upload-btn-wrapper">
                                            <button className="upload-btn ">
                                              Upload a file
                                            </button>
                                            <input
                                              type="file"
                                              name="myfile"
                                              ref={fileInput}
                                              onChange={(event) =>
                                                handleImageUpload(event)
                                              }
                                              accept="image/*"
                                            />
                                          </div>
                                          {!selectedFilName && (
                                            <span className="sub-note ">
                                              Upload image size should be
                                              maximum of 2 MB and 400 X 400
                                              pixels size.
                                            </span>
                                          )}
                                          {selectedFilName && (
                                            <span className="uploaded-file-name">
                                              {selectedFilName}
                                            </span>
                                          )}
                                          {/* <input
                                            id="file-input"
                                            className="raffle-image-upload"
                                            type="file"
                                            ref={fileInput}
                                            onChange={(event) =>
                                              handleImageUpload(event)
                                            }
                                            accept="image/*"
                                          /> */}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                          {step2 && (
                            <div className="pending step-2">
                              <div className="w-100">
                                <div className="pb-10 pb-lg-15">
                                  <div
                                    className="d-flex pointer w-300px"
                                    onClick={() => moveStep("step1")}
                                  >
                                    <div className="py-2">
                                      <div className="back-arrow">
                                        <img src={bacl_arrow_img} />
                                      </div>
                                    </div>

                                    <div className="py-2 px-4 mb-3">
                                      <span className="raffle-detail-label">
                                        Back to
                                      </span>

                                      <div className="raffle-detail-text">
                                        Previous Step
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row fv-row mb-7 fv-plugins-icon-container">
                                  <div className="col-xl-2">
                                    <label className="form-label mb-3  create-raffle-label">
                                      Ticket Size
                                    </label>

                                    <input
                                      type="number"
                                      min="1"
                                      className="form-control form-control-lg form-control-solid"
                                      value={ticketSupply}
                                      onChange={(e) => {
                                        setticketSupply(e.target.value);
                                        show_threshold(e.target.value);
                                        rentCalculation(e.target.value);
                                      }}
                                      placeholder=""
                                    />

                                    {ticketSupply !== "" && (
                                      <span className="ticket-threshold">
                                        Rent : {rent.toFixed(2)} SOL
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-xl-4">
                                    <label className="form-label mb-3  create-raffle-label">
                                      Ticket Type
                                    </label>

                                    <select
                                      className="raffle-price-type-select form-select form-select-solid"
                                      name="created_for"
                                      value={ticketType}
                                      onChange={(e) =>
                                        onTicketTypeChange(e.target.value)
                                      }
                                    >
                                      <option value="1">
                                        Single Ticket Price
                                      </option>
                                      <option value="2">
                                        Dual Ticket Price
                                      </option>
                                    </select>
                                    {ticketThreshold > 0 && (
                                      <div className="ticket-threshold">
                                        Minimum Threshold Value :{" "}
                                        {ticketThreshold}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-xl-3">
                                    <label className="form-label mb-3  create-raffle-label">
                                      Ticket Price
                                    </label>
                                    <div className="d-flex align-items-center">
                                      <input
                                        type="number"
                                        min="1"
                                        className="form-control raffle-price-text form-control-lg form-control-solid w-100px"
                                        value={ticketPrice}
                                        onChange={(e) =>
                                          setticketPrice(e.target.value)
                                        }
                                        placeholder=""
                                      />
                                      <select
                                        className="raffle-price-select form-select form-select-solid"
                                        name="created_for"
                                        value={priceTypeOption}
                                        onChange={(e) =>
                                          onPriceTypeChange(e.target.value)
                                        }
                                      >
                                        {ticket_list.map((t, k) => (
                                          <option value={k}>
                                            {t["Token_name"]}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  {ticketType === "2" && (
                                    <div className="col-xl-3">
                                      <label className="form-label mb-3  create-raffle-label">
                                        Ticket Price 2
                                      </label>
                                      <div className="d-flex align-items-center">
                                        <input
                                          type="number"
                                          min="1"
                                          className="form-control raffle-price-text form-control-lg form-control-solid w-100px"
                                          value={ticketPrice1}
                                          onChange={(e) =>
                                            setticketPrice1(e.target.value)
                                          }
                                          placeholder=""
                                        />
                                        <select
                                          className="raffle-price-select form-select form-select-solid"
                                          name="created_for"
                                          value={priceTypeOption1}
                                          onChange={(e) =>
                                            onPriceTypeChange1(e.target.value)
                                          }
                                        >
                                          {ticket_list_1.map((t, k) => (
                                            <option value={k}>
                                              {t["Token_name"]}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="row fv-row mb-7 fv-plugins-icon-container">
                                  <div className="col-xl-6">
                                    <label className="form-label mb-3 create-raffle-label">
                                      Max Ticket Per Wallet
                                    </label>

                                    <input
                                      type="number"
                                      min="1"
                                      className="form-control form-control-lg form-control-solid"
                                      value={maxTicketPerWallet}
                                      onChange={(e) =>
                                        setmaxTicketPerWallet(e.target.value)
                                      }
                                      placeholder="Unlimited"
                                    />
                                  </div>

                                  <div className="col-xl-6">
                                    <label className="form-label mb-3 create-raffle-label">
                                      Raffle End Date
                                    </label>

                                    <DatePicker
                                      minDate={moment().add(0, "days").toDate()}
                                      timeInputLabel="Time:"
                                      showTimeInput
                                      minTime={disabledHours}
                                      maxTime={setHours(
                                        setMinutes(new Date(), 0),
                                        23
                                      )}
                                      className="form-control form-control-solid"
                                      dateFormat="MM/dd/yyyy h:mm aa"
                                      selected={endTime}
                                      onChange={(date) => setendTime(date)}
                                    />
                                    <div className="mt-2 ticket-threshold">
                                      Min : 24 Hours
                                    </div>
                                  </div>
                                </div>

                                <div className="row fv-row mb-7 fv-plugins-icon-container">
                                  {raffleFor === "token" && (
                                    <div className="col-xl-6">
                                      <label className="form-label mb-3  create-raffle-label">
                                        Price Amount for winner
                                      </label>

                                      <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        value={priceAmountPerWinner}
                                        onChange={(e) =>
                                          setpriceAmountPerWinner(
                                            e.target.value
                                          )
                                        }
                                        placeholder=""
                                      />
                                    </div>
                                  )}
                                </div>
                                {(raffleFor === "token" ||
                                  raffleFor === "wallet") && (
                                  <div className="row fv-row mb-7 fv-plugins-icon-container">
                                    <div className="col-xl-12">
                                      <label className="form-label mb-3 create-raffle-label">
                                        Whitelist - Project Discord Link
                                      </label>

                                      <input
                                        type="text"
                                        value={discordLink}
                                        className="form-control form-control-lg form-control-solid"
                                        onChange={(e) =>
                                          setdiscordLink(e.target.value)
                                        }
                                        placeholder=""
                                      />
                                    </div>
                                  </div>
                                )}
                                {(raffleFor === "token" ||
                                  raffleFor === "wallet") && (
                                  <div className="row fv-row mb-7 fv-plugins-icon-container">
                                    <div className="col-xl-12">
                                      <label className="form-label mb-3 create-raffle-label">
                                        Whitelist - Project Twitter Link
                                      </label>

                                      <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid"
                                        value={twitterLink}
                                        onChange={(e) =>
                                          settwitterLink(e.target.value)
                                        }
                                        placeholder=""
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                          <div className="d-flex flex-stack pt-15">
                            <div className="mr-2">
                              {step1 && (
                                <button
                                  type="button"
                                  className="btn btn-lg create-raffle-btn"
                                  onClick={() => moveStep("step2")}
                                >
                                  Continue
                                  <span className="svg-icon svg-icon-4 ms-1">
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        opacity="0.5"
                                        x="18"
                                        y="13"
                                        width="13"
                                        height="2"
                                        rx="1"
                                        transform="rotate(-180 18 13)"
                                        fill="currentColor"
                                      ></rect>
                                      <path
                                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>
                                </button>
                              )}
                              {step2 && (
                                <div className="text-center">
                                  <button
                                    type="submit"
                                    className="btn bt-lg create-raffle-btn"
                                    onClick={handleSubmit}
                                  >
                                    Create Raffle
                                    <span className="svg-icon svg-icon-4 ms-1">
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          opacity="0.5"
                                          x="18"
                                          y="13"
                                          width="13"
                                          height="2"
                                          rx="1"
                                          transform="rotate(-180 18 13)"
                                          fill="currentColor"
                                        ></rect>
                                        <path
                                          d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                          fill="currentColor"
                                        ></path>
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>

                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};
