import React, { useEffect, useState, useCallback } from "react";

import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { useLocation, useNavigate, Navigate, Link } from "react-router-dom";
import {
  RaffleData,
  createBuyTicketsTransaction,
  createRevealWinnerTransaction,
  createRevealWLWinnersTransaction,
  createClaimPrizeTransaction,
  createCancelRaffleTransaction,
} from "../util/raffle";
import { PublicKey } from "@solana/web3.js";
import moment from "moment";
import "./my-account.css";
import logo_white from "../images/logo-white.png";
import collection_img from "../images/Group 193.png";
import nft_img_1 from "../images/956.png";
import my_account_img from "../images/Rectangle 31.png";
import flame_img from "../images/Flame.png";
import { useTimer } from "react-timer-hook";
import Swal from "sweetalert2";
import { PreLoader } from "../components/pre-loader";
import no_raffle_img from "../images/empty-box.png";
import {
  Metadata,
  MetadataDataData,
} from "@metaplex-foundation/mpl-token-metadata";
function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => window.location.reload(),
  });

  return (
    <div>
      <div id="countdown" className="countdownHolder">
        <span className="countDays">
          <span className="position">
            <span className="digit static">{days}d</span>
          </span>
        </span>
        <span className="countDiv countDiv0"></span>
        <span className="countHours">
          <span className="position">
            {" "}
            <span className="digit static">{hours}h</span>{" "}
          </span>
        </span>
        <span className="countDiv countDiv1"></span>
        <span className="countMinutes">
          <span className="position">
            {" "}
            <span className="digit static">{minutes}m</span>{" "}
          </span>
        </span>
        <span className="countDiv countDiv1"></span>
        <span className="countSeconds">
          <span className="position">
            {" "}
            <span className="digit static">{seconds}s</span>{" "}
          </span>
        </span>
      </div>
    </div>
  );
}

export const Myaccount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const location = useLocation();
  const [raffle, setRaffle] = useState({});
  const [my_account, setMy_account] = useState();
  const [twitter_id, setTwitter_id] = useState("");
  const [createdRaffle, setcreatedRaffle] = useState([]);
  const [purchasedRaffle, setpurchasedRaffle] = useState([]);
  const [sticky, setSticky] = useState("");
  const [purchasedTicketsCount, setpurchasedTicketsCount] = useState<
    any | null
  >(null);

  const [createdTicketsCount, setcreatedTicketsCount] = useState<any | null>(
    null
  );
  const [participants, setParticipants] = useState();
  const [showPurchasedTable, setShowPurchasedTable] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const navigate = useNavigate();

  const [isPollTimeEnd, setisPollTimeEnd] = useState(false);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 960;
        if (ismobile !== isMobile) {
          setIsMobile(ismobile);
          setShowPurchasedTable(isMobile);
        }
      },
      false
    );
  }, [isMobile]);

  const get_my_details = async () => {
    if (!publicKey) return;
    try {
      setIsLoading(true);
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getMyProfile/" + publicKey.toString(),
        // process.env.REACT_APP_API_URL + "getMyProfile/AeeHqWbs5y41RAYAshgN8pvoXXX7oVvNN7XjuuCDqpzk",

        { mode: "cors" }
      );
      if (response.status === 400) {
        setpurchasedTicketsCount(0);
        setcreatedTicketsCount(0);
      }
      const data = await response.json();
      setMy_account(data);
      setTwitter_id(data["TwitterId"]);
      let a = [];
      let b = [];
      let t_c = 0;
      let a_c = 0;
      if (
        data["purchasedTickets"] !== null &&
        data["purchasedTickets"].length > 0
      ) {
        for (let raffle of data["purchasedTickets"]) {
          // if (
          //   raffle["transactionHash"] !== null &&
          //   raffle["transactionHash"] !== ""
          // ) {
          // let t = await RaffleData(connection, raffle["raffle_id"]);
          // if (t !== undefined) {

          // t["raffle_id"] = raffle["raffle_id"];
          // t["raffleName"] = raffle["raffleName"];

          // t_c =
          //   t["entries"].filter((x) => x === publicKey.toString()).length +
          //   t_c;

          const time = new Date(Number(raffle["raffleEndTime"]) * 1000);

          raffle["timer"] = time.getTime();

          let now = moment.utc();
          var day = moment.unix(raffle["raffleEndTime"]);
          var daysDiff = day.diff(now, "days");
          var hoursDiff = day.diff(now, "hours");
          var minutesDiff = day.diff(now, "minutes");
          var secondsDiff = day.diff(now, "seconds");

          if (daysDiff > 0) {
            raffle["raffle_ended"] = false;
          } else {
            if (hoursDiff > 0 || minutesDiff > 0 || secondsDiff > 0) {
              raffle["raffle_ended"] = false;
            } else {
              raffle["raffle_ended"] = true;
            }
          }
          a.push(raffle);
          // }
          // }
        }
      }

      if (data["createdRaffle"] !== null && data["createdRaffle"].length > 0) {
        for (let raffle of data["createdRaffle"]) {
          // if (
          //   raffle["transactionHash"] !== null &&
          //   raffle["transactionHash"] !== ""
          // ) {
          // let t = await RaffleData(connection, raffle["raffle_id"]);

          // if (t !== undefined) {
          // let t;
          // t["id"] = raffle["id"];
          // t["raffle_id"] = raffle["raffle_id"];
          // t["raffleName"] = raffle["raffleName"];

          // a_c =
          //   t["entries"].filter((x) => x === publicKey.toString()).length +
          //   a_c;

          const time = new Date(raffle["raffleEndTime"] * 1000);

          raffle["timer"] = time.getTime();

          let now = moment.utc();
          let day = moment.unix(raffle["raffleEndTime"]);
          let daysDiff = day.diff(now, "days");
          let hoursDiff = day.diff(now, "hours");
          let minutesDiff = day.diff(now, "minutes");
          let secondsDiff = day.diff(now, "seconds");

          if (daysDiff > 0) {
            raffle["raffle_ended"] = false;
          } else {
            if (hoursDiff > 0 || minutesDiff > 0 || secondsDiff > 0) {
              raffle["raffle_ended"] = false;
            } else {
              raffle["raffle_ended"] = true;
            }
          }

          b.push(raffle);
          // }
          // }
        }
      }

      setpurchasedTicketsCount(data["purchasedTickets"].length);
      setcreatedTicketsCount(data["createdRaffle"].length);
      setpurchasedRaffle(a);
      setcreatedRaffle(b);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const authTwitter = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "auth/twitter/request",
      { mode: "cors" }
    );
    const data = await response.json();
    if ("url" in data) {
      localStorage.setItem("t_state", data["state"]);
      localStorage.setItem("t_code_challenge", data["code_challenge"]);
      window.location.href = data["url"];
    }
  };

  useEffect(() => {
    let url = window.location.href.split("/");
    if (url.length > 2) {
      if (url[3] === "my-account" && url[4] === "twitter") {
        console.log(url);
      }
    }
    // get_dao_by_id();
  }, [location]);

  useEffect(() => {
    get_my_details();

    // get_dao_by_id();
  }, [publicKey]);

  const tab_toggler = async () => {
    setShowPurchasedTable(!showPurchasedTable);
  };

  let call_raffle_details = (param) => (e) => {
    navigate("/raffle/" + param.id);
  };

  // on render, set listener
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    let featured_height = document.querySelector(".user-card");

    let scroll;
    if (featured_height !== null) {
      scroll = document.querySelector(".user-card").clientHeight;
    } else {
      scroll = 250;
    }

    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= scroll ? "is-sticky" : "";
    setSticky(stickyClass);
  };

  return (
    <>
      {publicKey ? (
        <>
          <div className="desk-top-view">
            {isLoading && <PreLoader />}
            {/* {!isLoading && ( */}
            <>
              <div className="my-account container-custom container-xxl d-lg-flex flex-column w-100">
                <div className="card mb-5 mt-5 mb-xl-10 user-card">
                  <div className="card-body pt-9 pb-0">
                    <div
                      className={`${
                        isMobile
                          ? "flex-wrap flex-sm-nowrap mb-3"
                          : "d-flex flex-wrap flex-sm-nowrap mb-3"
                      }`}
                    >
                      <div className="flex-grow-1">
                        <div
                          className={`${
                            isMobile
                              ? "justify-content-between align-items-start flex-wrap mb-2"
                              : "d-flex justify-content-between align-items-start flex-wrap mb-2"
                          }`}
                        >
                          <div
                            className={`${
                              isMobile ? "flex-column" : "d-flex flex-column"
                            }`}
                          >
                            <div
                              className={`${
                                isMobile
                                  ? "align-items-center mb-1"
                                  : "d-flex align-items-center mb-1"
                              }`}
                            >
                              <a href="#" className="fs-1 fw-bolder me-3">
                                My Account
                              </a>
                            </div>
                            <div
                              className={`${
                                isMobile
                                  ? " align-items-center mb-2"
                                  : "d-flex align-items-center mb-2"
                              }`}
                            >
                              <span className="text-hover-primary fs-5 fw-bold me-1">
                                {publicKey ? publicKey.toString() : ""}
                              </span>
                            </div>
                          </div>

                          <div className="d-flex my-4">
                            {twitter_id === "" && (
                              <button
                                onClick={authTwitter}
                                className="btn twiiter-connect-btn me-2"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                  className="inline-block w-6 ml-2 text-white-40 fill-current"
                                >
                                  <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                                </svg>
                                Link Twiiter
                              </button>
                            )}
                            {twitter_id !== "" && (
                              <div>
                                <div className="twitter-user-name-label">
                                  Twitter Username
                                </div>
                                <div className="twitter-user-name">
                                  <a
                                    href={"https://twitter.com/" + twitter_id}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {twitter_id}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                      className="inline-block w-6 ml-2 text-blue-400 fill-current"
                                    >
                                      <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                                    </svg>
                                  </a>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="d-flex flex-wrap flex-stack">
                          <div className="d-flex flex-column flex-grow-1 pe-8">
                            <div className="d-flex flex-wrap">
                              <div>
                                {/* <img className="my-account-img" src={my_account_img} /> */}
                              </div>

                              <div className="my-account-small-box min-w-125px py-3 px-4 me-6 mb-3">
                                <div className="label">Raffles Created</div>
                                <div className="d-flex align-items-center">
                                  <div className="text">
                                    {createdRaffle !== undefined
                                      ? createdRaffle.length
                                      : 0}
                                  </div>
                                </div>
                              </div>
                              <div className="my-account-small-box-2 min-w-125px py-3 px-4 me-6 mb-3">
                                <div className="label">Tickets Purchased</div>
                                <div className="d-flex align-items-center">
                                  <div className="text">
                                    {purchasedRaffle !== undefined
                                      ? purchasedTicketsCount
                                      : 0}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <>
                  {/* <div className="slider-container-myaccount">
                    <label className="switch-myaccount">
                      <input
                        type="checkbox"
                        onChange={() => tab_toggler()}
                        id="toggle_vat"
                        className="toggle_vat"
                      />
                      <div className="slider round">
                        <span className="slider_text">
                          <span className="off">Purchased</span>
                          <span className="on">Created</span>
                        </span>
                      </div>
                    </label>
                  </div> */}

                  <div
                    className={`${
                      sticky === ""
                        ? "row pt-5 mt-5"
                        : "row pt-8 mt-5 is-sticky"
                    }`}
                  >
                    <div className="col-xl-12">
                      <div className="d-flex">
                        <div
                          onClick={() => tab_toggler()}
                          className={`${
                            showPurchasedTable
                              ? "home-tab active py-3 px-5 mx-2 mb-3"
                              : "home-tab py-3 px-5 mx-2 mb-3"
                          }`}
                        >
                          Purchased
                        </div>
                        <div
                          onClick={() => tab_toggler()}
                          className={`${
                            !showPurchasedTable
                              ? "home-tab active py-3 px-5 mx-2 mb-3"
                              : "home-tab py-3 px-5 mx-2 mb-3"
                          }`}
                        >
                          Created
                        </div>
                      </div>
                    </div>
                  </div>
                  {showPurchasedTable && (
                    <>
                      <>
                        {purchasedRaffle.map((raffle, index) => (
                          <div
                            className={`${
                              publicKey &&
                              raffle["winnerList"] !== null &&
                              raffle["winnerList"]["list"].length === 1 &&
                              raffle["winnerList"]["list"][0] ===
                                publicKey.toString()
                                ? "card my-account-card winner-card"
                                : "card my-account-card"
                            }`}
                          >
                            <div className="row no-gutters">
                              <div className="col-md-4 col-4 col-sm-4 col-lg-2">
                                <img
                                  src={raffle["imageData"]}
                                  className="raffle-img"
                                  alt="..."
                                />
                              </div>
                              <div className="col-md-8 col-8 col-sm-8 col-lg-8">
                                <div className="raffle-info">
                                  <div
                                    className={`${isMobile ? "" : "d-flex"}`}
                                  >
                                    <div className="raffle-ticket-type py-2 px-4">
                                      <span className="raffle-detail-label">
                                        Raffle Name
                                      </span>
                                      {raffle["rafflePriceType"] === "nft" ? (
                                        <div className="collec-name">
                                          {raffle["collectionName"]}{" "}
                                          <img
                                            className="collection-img"
                                            src={collection_img}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="raffle-detail-text">
                                        {raffle["raffleName"]}
                                      </div>
                                    </div>
                                    <div className="py-2 px-4">
                                      <span className="raffle-detail-label ">
                                        Threshold
                                      </span>

                                      <div className="raffle-detail-text">
                                        {Number(raffle["threshold"]).toFixed()}{" "}
                                        {raffle["ticket_bought"] <
                                        raffle["threshold"] ? (
                                          <>
                                            <i className="ticket-threshold-icon-warning fa fa-exclamation-circle"></i>
                                          </>
                                        ) : (
                                          <>
                                            <i className="ticket-threshold-icon-success fa fa-check-circle"></i>
                                          </>
                                        )}
                                      </div>
                                    </div>

                                    <div className="py-2 px-4">
                                      <span className="raffle-detail-label ">
                                        Tickets Sold
                                      </span>

                                      <div className="raffle-detail-text">
                                        {raffle["ticket_bought"] ===
                                        raffle["tickt_size"] ? (
                                          <span className="ticket-sold sold-out">
                                            SOLD OUT
                                          </span>
                                        ) : (
                                          <div>
                                            <span className="ticket-sold">
                                              {" "}
                                              {raffle["ticket_bought"]}{" "}
                                            </span>
                                            /
                                            <span className="ticket-remain">
                                              {raffle["tickt_size"]}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                    <div className="py-2 px-4">
                                      <span className="raffle-detail-label ">
                                        Ticket Cost
                                      </span>

                                      <div className="raffle-detail-text">
                                        {raffle["ticket_prize"]}{" "}
                                        {raffle["ticket_prize_type"]}
                                        {raffle["ticket_type"] === "multi" &&
                                          "  + " +
                                            raffle["ticket_prize_1"] +
                                            " " +
                                            raffle["ticket_prize_type_1"]}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={`${isMobile ? "" : "d-flex"}`}
                                  >
                                    <div className="raffle-ticket-type py-2 px-4 mb-3">
                                      <span className="raffle-detail-label">
                                        {raffle["raffle_ended"]
                                          ? "Ended On"
                                          : "Ends In"}
                                      </span>

                                      <div className="raffle-detail-text">
                                        {raffle["raffle_ended"] ? (
                                          <>
                                            {moment
                                              .unix(raffle["raffleEndTime"])
                                              .format("MMMM DD, YYYY")}
                                          </>
                                        ) : (
                                          <MyTimer
                                            expiryTimestamp={raffle["timer"]}
                                          />
                                        )}
                                      </div>
                                    </div>

                                    {raffle["winnerList"] !== null &&
                                    raffle["winnerList"]["list"].length ===
                                      1 ? (
                                      <div className=" py-2 px-4 mb-3">
                                        <>
                                          <span className="raffle-detail-label">
                                            Winner
                                          </span>

                                          <div className="raffle-winner-my">
                                            {raffle["winnerList"][
                                              "list"
                                            ][0].substring(0, 4)}
                                            ...
                                            {raffle["winnerList"][
                                              "list"
                                            ][0].slice(-4)}
                                          </div>
                                        </>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div className="py-2 px-4">
                                      <span className="raffle-detail-label ">
                                        Tickets Bought
                                      </span>

                                      <div className="raffle-detail-text">
                                        {raffle["ticket_hold"]}
                                        {raffle["raffle_ended"] ? (
                                          ""
                                        ) : (
                                          <span className="winning-change">
                                            {"("}
                                            {(
                                              (100 /
                                                Number(
                                                  raffle["ticket_bought"]
                                                )) *
                                              Number(raffle["ticket_hold"])
                                            ).toFixed(2)}
                                            {" % Winning Chance)"}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-2 col-12 view-col">
                                {raffle["close"] === 1 ? (
                                  <div className="view-btn">Raffle Closed</div>
                                ) : (
                                  <div
                                    className="view-btn"
                                    onClick={call_raffle_details(raffle)}
                                  >
                                    {
                                        publicKey &&
                                        raffle["winnerList"] !== null &&
                                        raffle["winnerList"]["list"].length === 1 &&
                                        raffle["winnerList"]["list"][0] ===
                                          publicKey.toString() ? <i className="fa-solid fa-crown"></i>:''
                                    }

                                    View Raffle
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                        {purchasedRaffle.length === 0 && (
                          <div className="row mt-5">
                            <div className="col-xl-12 text-center">
                              <img
                                width="170"
                                src={no_raffle_img}
                                alt="no_raffle"
                              />
                              <div className="fs-2 text-muted">
                                No purchased Raffles
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    </>
                  )}
                  {!showPurchasedTable && (
                    <>
                      <>
                        {createdRaffle.map((raffle, index) => (
                          <div
                            className="card my-account-card"
                          >
                            <div className="row no-gutters">
                              <div className="col-md-4 col-4 col-sm-4 col-lg-2">
                                <img
                                  src={raffle["imageData"]}
                                  className="raffle-img"
                                  alt="..."
                                />
                              </div>
                              <div className="col-md-8 col-8 col-sm-8 col-lg-8">
                                <div className="raffle-info">
                                  <div
                                    className={`${isMobile ? "" : "d-flex"}`}
                                  >
                                    <div className="raffle-ticket-type py-2 px-4">
                                      <span className="raffle-detail-label">
                                        Raffle Name
                                      </span>
                                      {raffle["rafflePriceType"] === "nft" ? (
                                        <div className="collec-name">
                                          {raffle["collectionName"]}{" "}
                                          <img
                                            className="collection-img"
                                            src={collection_img}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="raffle-detail-text">
                                        {raffle["raffleName"]}
                                      </div>
                                    </div>
                                    <div className="py-2 px-4">
                                      <span className="raffle-detail-label ">
                                        Tickets Sold
                                      </span>

                                      <div className="raffle-detail-text">
                                        {raffle["ticket_bought"] ===
                                        raffle["tickt_size"] ? (
                                          <span className="ticket-sold sold-out">
                                            SOLD OUT
                                          </span>
                                        ) : (
                                          <div
                                            className={`${
                                              raffle["raffle_ended"]
                                                ? "ticket-not-sold"
                                                : ""
                                            }`}
                                          >
                                            <span className="ticket-sold">
                                              {" "}
                                              {raffle["ticket_bought"]}{" "}
                                            </span>
                                            /
                                            <span className="ticket-remain">
                                              {raffle["tickt_size"]}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                    <div className="py-2 px-4">
                                      <span className="raffle-detail-label ">
                                        Ticket Cost
                                      </span>

                                      <div className="raffle-detail-text">
                                        {raffle["ticket_prize"]}{" "}
                                        {raffle["ticket_prize_type"]}
                                        {raffle["ticket_type"] === "multi" &&
                                          "  + " +
                                            raffle["ticket_prize_1"] +
                                            " " +
                                            raffle["ticket_prize_type_1"]}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={`${isMobile ? "" : "d-flex"}`}
                                  >
                                    <div className="raffle-ticket-type py-2 px-4">
                                      <span className="raffle-detail-label">
                                        {raffle["raffle_ended"]
                                          ? "Ended On"
                                          : "Ends In"}
                                      </span>

                                      <div className="raffle-detail-text">
                                        {raffle["raffle_ended"] ? (
                                          <>
                                            {moment
                                              .unix(raffle["raffleEndTime"])
                                              .format("MMMM DD, YYYY")}
                                          </>
                                        ) : (
                                          <MyTimer
                                            expiryTimestamp={raffle["timer"]}
                                          />
                                        )}
                                      </div>
                                    </div>
                                    <div className="py-2 px-4">
                                      <span className="raffle-detail-label ">
                                        Threshold
                                      </span>

                                      <div className="raffle-detail-text">
                                        {Number(raffle["threshold"]).toFixed()}{" "}
                                        {raffle["ticket_bought"] <
                                        raffle["threshold"] ? (
                                          <>
                                            <i className="ticket-threshold-icon-warning fa fa-exclamation-circle"></i>
                                          </>
                                        ) : (
                                          <>
                                            <i className="ticket-threshold-icon-success fa fa-check-circle"></i>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    {raffle["winnerList"] !== null &&
                                    raffle["winnerList"]["list"].length ===
                                      1 ? (
                                      <div className=" py-2 px-4 mb-3">
                                        <>
                                          <span className="raffle-detail-label">
                                            Winner
                                          </span>

                                          <div className="raffle-winner-my">
                                            {raffle["winnerList"][
                                              "list"
                                            ][0].substring(0, 4)}
                                            ...
                                            {raffle["winnerList"][
                                              "list"
                                            ][0].slice(-4)}
                                          </div>
                                        </>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-2 col-12 view-col">
                                {raffle["close"] === 1 ? (
                                  <div className="view-btn">Raffle Closed</div>
                                ) : (
                                  <div
                                    className="view-btn"
                                    onClick={call_raffle_details(raffle)}
                                  >
                                    View Raffle
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                        {createdRaffle.length === 0 && (
                          <div className="row mt-5">
                            <div className="col-xl-12 text-center">
                              <img
                                width="170"
                                src={no_raffle_img}
                                alt="no_raffle"
                              />
                              <div className="fs-2 text-muted">
                                No created Raffles
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    </>
                  )}
                </>
              </div>
            </>
            {/* )} */}
          </div>
          <div className="mobile-view text-white fs-2">
            <img src={logo_white} />
            <div>Please use this app in desktop for better experience</div>
          </div>
        </>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};
