import React, {
  FC,
  useCallback,
  useEffect,
  useReducer,
  useState,
  useRef,
} from "react";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { RaffleData } from "../util/raffle";
import nft_img_1 from "../images/956.png";
import heading_img_1 from "../images/Heading.png";
import logo_white from "../images/logo-white.png";
import flame_img from "../images/Flame.png";
import live_img from "../images/live-streaming.png";
import expired_img from "../images/expired.png";
import raffle_img from "../images/brand.png";
import dao_collection_img from "../images/collection.png";
import collection_img from "../images/Group 193.png";
import no_raffle_img from "../images/empty-box.png";
import "./home.css";
import moment from "moment";
import { useTimer } from "react-timer-hook";
import { PreLoader } from "../components/pre-loader";
import Swal from "sweetalert2";
import { PublicKey } from "@solana/web3.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Metadata,
  MetadataDataData,
} from "@metaplex-foundation/mpl-token-metadata";

function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => console.log("end"),
  });

  return (
    <div
      style={{ textAlign: "center" }}
      className="raffle-time badge  fs-8 fw-bold"
    >
      <div id="countdown" className="countdownHolder">
        Ends In :{" "}
        <span className="countDays">
          <span className="position">
            <span className="digit static">{days}d</span>
          </span>
        </span>
        <span className="countDiv countDiv0"></span>
        <span className="countHours">
          <span className="position">
            {" "}
            <span className="digit static">{hours}h</span>{" "}
          </span>
        </span>
        <span className="countDiv countDiv1"></span>
        <span className="countMinutes">
          <span className="position">
            {" "}
            <span className="digit static">{minutes}m</span>{" "}
          </span>
        </span>
        <span className="countDiv countDiv1"></span>
        <span className="countSeconds">
          <span className="position">
            {" "}
            <span className="digit static">{seconds}s</span>{" "}
          </span>
        </span>
      </div>
    </div>
  );
}

export const Home = () => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const [isLoading, setIsLoading] = useState(false);
  const [isPreLoading, setIsPreLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [limit, setLimit] = useState(10);
  const [allRaffle, setallRaffle] = useState([]);
  const [allDao, setallDao] = useState([]);
  // const [pastRaffle, setpastRaffle] = useState([]);
  const [recentRaffle, setrecentRaffle] = useState([]);
  const [search, setsearch] = useState("");
  const [foundRaffle, setFoundRaffle] = useState([]);
  const [homeActiveTab, setHomeActiveTab] = useState("active");
  const [featuredRaffle, setFeaturedRaffle] = useState([]);
  const [activeRaffle, setActiveRaffle] = useState([]);
  const [activeRaffleCurrentPage, setActiveRaffleCurrentPage] = useState(1);
  const [isActiveRaffleNextPage, setIsActiveRaffleNextPage] = useState(false);
  const [isActiveRafflePrevPage, setIsActiveRafflePrevPage] = useState(false);

  const [pastRaffle, setPastRaffle] = useState([]);
  const [pastRaffleCurrentPage, setPastRaffleCurrentPage] = useState(1);
  const [isPastRaffleNextPage, setIsPastRaffleNextPage] = useState(false);

  const [cancelledRaffle, setCancelledRaffle] = useState([]);
  const [cancelledRaffleCurrentPage, setCancelledRaffleCurrentPage] =
    useState(1);
  const [isCancelledRaffleNextPage, setIsCancelledRaffleNextPage] =
    useState(false);

  const [sticky, setSticky] = useState("");
  const [showScroll, setShowScroll] = useState(false);
  const navigate = useNavigate();

  const ticket_list = JSON.parse(process.env.REACT_APP_TICKET_TOKEN);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  var settings = {
    dots: true,
    infinite: true,
    arrows: true,
    // variableWidth:true,
    autoplay: true,
    speed: 500,
    slidesToShow: 4,
    // slidesToShow: featuredRaffle.length > 4 ? 4 : featuredRaffle.length,
    slidesToScroll: 1,
    className: "featured-slider",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getAllRaffle = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getDashboard",
        { mode: "cors" }
      );
      let data = await response.json();
      setallDao(data["dao_s"]);
      // data = data.reverse();
      // if (data.length > 5) {
      //   setIsLoadingPage(true);
      // }
      let a = [];
      let b = [];
      let c = [];
      for (const raffle of data["raffles"].reverse()) {
        if (
          raffle["transactionHash"] !== null &&
          raffle["transactionHash"] !== ""
        ) {
          let t = raffle;

          const time = new Date(Number(t.raffleEndTime) * 1000);

          t["timer"] = time.getTime();

          let now = moment.utc();
          var day = moment.unix(Number(t.raffleEndTime));
          var daysDiff = day.diff(now, "days");
          var hoursDiff = day.diff(now, "hours");
          var minutesDiff = day.diff(now, "minutes");
          var secondsDiff = day.diff(now, "seconds");

          if (daysDiff > 0) {
            t["raffle_ended"] = false;
          } else {
            if (hoursDiff > 0 || minutesDiff > 0 || secondsDiff > 0) {
              t["raffle_ended"] = false;
            } else {
              t["raffle_ended"] = true;
            }
          }
          if (t["raffle_ended"] === true) {
            b.push(t);
          } else {
            a.push(t);
          }

          c.push(t);
        }
      }

      setallRaffle(c);
      setrecentRaffle(a);
      // setpastRaffle(b);
      setIsLoading(false);

      // await setFoundUsers(data);
      // await setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      return;
    }
  };

  const getFeaturedRaffle = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "featuredRaffle?page=1",
        { mode: "cors" }
      );
      if (response.status === 200) {
        let data = await response.json();

        for (const raffle of data["results"].reverse()) {
          if (
            raffle["transactionHash"] !== null &&
            raffle["transactionHash"] !== ""
          ) {
            let t = raffle;
            const time = new Date(Number(t.raffleEndTime) * 1000);
            t["timer"] = time.getTime();
            await setFeaturedRaffle((featuredRaffle) => [...featuredRaffle, t]);
          }
        }

        // setActiveRaffle(a);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      return;
    }
  };

  const getActiveRaffle = async (load_type, page = 0) => {
    if (load_type === "lazy") {
      setIsPreLoading(true);
    } else {
      setIsPreLoading(true);
    }

    try {
      if (load_type === "refresh") {
        console.log("refresh");
        await setActiveRaffleCurrentPage(page);
      } else {
        console.log("lazy");
        page = activeRaffleCurrentPage;
      }

      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "activeRaffle?page=" +
          page +
          "&Page=20",
        { mode: "cors" }
      );
      if (response.status === 200) {
        let data = await response.json();
        if (data["next"] !== null) {
          await setIsActiveRaffleNextPage(true);
          await setActiveRaffleCurrentPage(page + 1);
        } else {
          await setIsActiveRaffleNextPage(false);
          // await setHomeActiveTab('past');
          // if(pastRaffle.length === 0){
          //   getPastRaffle()
          // }
        }
        if (data["previous"] !== null) {
          setIsActiveRafflePrevPage(true);
        } else {
          setIsActiveRafflePrevPage(false);
        }

        let a = [];

        for (const raffle of data["results"]) {
          // if (
          //   raffle["transactionHash"] !== null &&
          //   raffle["transactionHash"] !== ""
          // ) {
          let t = raffle;
          const time = new Date(Number(t.raffleEndTime) * 1000);
          t["timer"] = time.getTime();
          a.push(t);
          await setActiveRaffle((activeRaffle) => [...activeRaffle, t]);
          // }
        }

        // setActiveRaffle(a);
        setIsLoading(false);
        setIsPreLoading(false);
      } else {
        await setIsActiveRaffleNextPage(false);
        setIsLoading(false);
        setIsPreLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      setIsPreLoading(false);
      console.log(e);
      return;
    }
  };

  const getPastRaffle = async (load_type, page = 0) => {
    setIsPreLoading(true);
    try {
      if (load_type === "refresh") {
        console.log("refresh");
        await setPastRaffleCurrentPage(page);
      } else {
        console.log("lazy");
        page = pastRaffleCurrentPage;
      }

      const response = await fetch(
        process.env.REACT_APP_API_URL + "pastRaffle?page=" + page + "&Page=20",
        { mode: "cors" }
      );
      if (response.status === 200) {
        let data = await response.json();
        if (data["next"] !== null) {
          setIsPastRaffleNextPage(true);
          setPastRaffleCurrentPage(pastRaffleCurrentPage + 1);
        } else {
          setIsPastRaffleNextPage(false);
        }

        let a = [];

        for (const raffle of data["results"]) {
          // if (
          //   raffle["transactionHash"] !== null &&
          //   raffle["transactionHash"] !== ""
          // ) {
          let t = raffle;
          const time = new Date(Number(t.raffleEndTime) * 1000);
          t["timer"] = time.getTime();
          a.push(t);
          await setPastRaffle((pastRaffle) => [...pastRaffle, t]);
          // }
        }

        // setActiveRaffle(a);
        setIsLoading(false);
        setIsPreLoading(false);
      } else {
        await setIsPastRaffleNextPage(false);
        setIsLoading(false);
        setIsPreLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      setIsPreLoading(false);
      console.log(e);
      return;
    }
  };

  const getCancelledRaffle = async (load_type, page = 0) => {
    setIsPreLoading(true);

    try {
      if (load_type === "refresh") {
        console.log("refresh");
        await setCancelledRaffleCurrentPage(page);
      } else {
        console.log("lazy");
        page = cancelledRaffleCurrentPage;
      }

      const response = await fetch(
        process.env.REACT_APP_API_URL +
          "cancelledRaffle?page=" +
          page +
          "&Page=20",
        { mode: "cors" }
      );
      if (response.status === 200) {
        let data = await response.json();
        if (data["next"] !== null) {
          await setIsCancelledRaffleNextPage(true);
          await setCancelledRaffleCurrentPage(cancelledRaffleCurrentPage + 1);
        } else {
          await setIsCancelledRaffleNextPage(false);
        }

        let a = [];

        for (const raffle of data["results"]) {
          // if (
          //   raffle["transactionHash"] !== null &&
          //   raffle["transactionHash"] !== ""
          // ) {
          let t = raffle;
          const time = new Date(Number(t.raffleEndTime) * 1000);
          t["timer"] = time.getTime();
          a.push(t);
          await setCancelledRaffle((cancelledRaffle) => [
            ...cancelledRaffle,
            t,
          ]);
          // }
        }

        // setActiveRaffle(a);
        setIsLoading(false);
        setIsPreLoading(false);
      } else {
        setIsLoading(false);
        setIsPreLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      setIsPreLoading(false);
      console.log(e);
      return;
    }
  };

  const getAllDao = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getAllDao",
        { mode: "cors" }
      );
      let data = await response.json();

      setallDao(data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  const getRaffleData = async () => {};

  let call_raffle_details = (param) => (e) => {
    if (param.close) {
      Swal.fire({
        text: "This Raffle was closed by creator.",
        confirmButtonText: "Close",
      });
    } else {
      navigate("/raffle/" + param.id);
    }
  };

  let home_tab_change = async (e) => {
    setHomeActiveTab(e);

    if (e === "active") {
      // await setActiveRaffleCurrentPage(1);
      // await setIsActiveRaffleNextPage(false);
      // await setActiveRaffle([]);

      let visted_page = activeRaffleCurrentPage;
      await setActiveRaffle([]);
      for (let i = 1; i <= visted_page; i++) {
        await getActiveRaffle("refresh", i);
      }
    } else if (e === "past") {
      let visted_page = pastRaffleCurrentPage;
      await setPastRaffle([]);
      for (let i = 1; i <= visted_page; i++) {
        await getPastRaffle("refresh", i);
      }
    } else if (e === "cancelled") {
      let visted_page = cancelledRaffleCurrentPage;
      await setCancelledRaffle([]);
      for (let i = 1; i <= visted_page; i++) {
        await getCancelledRaffle("refresh", i);
      }
    } else if (e === "dao_list") {
      getAllDao();
    }
  };

  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = allRaffle.filter((search) => {
        if (search.raffleName !== null) {
          return search.raffleName.toLowerCase().match(keyword.toLowerCase());
        } else {
          return "";
        }

        // Use the toLowerCase() method to make it case-insensitive
      });
      setFoundRaffle(results);
    } else {
      setFoundRaffle([]);

      // If the text field is empty, show all users
    }
    setsearch(keyword);
  };

  // on render, set listener
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    let featured_height = document.querySelector(".featured-container");

    let scroll;
    if (featured_height !== null) {
      scroll = document.querySelector(".featured-container").clientHeight;
    } else {
      scroll = 250;
    }

    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= scroll ? "is-sticky" : "";
    setSticky(stickyClass);

    if (scrollTop >= 700) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    async function data() {
      setIsPreLoading(true);
      // await setIsLoading(true);
      // await getAllDao();
      await setFeaturedRaffle([]);
      await getFeaturedRaffle();
      // await getAllRaffle();
      await setActiveRaffle([]);
      await getActiveRaffle("refresh", 1);
      // console.log("d");
      // await get_floor_price("the_vaultx_dao");
    }
    data();
  }, [navigate]);

  const notify = () => console.log("refresh");

  const refreshData = async () => {
    await getAllRaffle();
  };

  const get_floor_price = async (c_name) => {
    try {
      const response = await fetch(
        "https://api-mainnet.magiceden.dev/v2/collections/" +
          c_name +
          "/stats/",

        {
          headers: new Headers({
            "User-Agent": "Mozilla/4.0",
          }),
          mode: "cors",
        }
      );
      const data = await response.json();
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  let find_ticket_token = (ticket_price, address) => {
    if (address !== undefined && address !== null) {
      let tokenType = ticket_list.find((o) => o.Address === address);

      let p = ticket_price / Math.pow(10, tokenType["Decimal"]);
      return p + " " + tokenType["Token_name"];
    }
  };

  // useEffect(() => {
  //   const intervalCall = setInterval(() => {
  //     refreshData();
  //   }, 100000);
  //   return () => {
  //     // clean up
  //     clearInterval(intervalCall);
  //   };
  // }, []);

  const fetchMoreActiveRaffle = () => {
    if (isActiveRaffleNextPage) {
      getActiveRaffle("lazy");
    }
  };

  const fetchMorePastRaffle = () => {
    if (isPastRaffleNextPage) {
      getPastRaffle("lazy");
    }
  };

  const fetchMoreCancelledRaffle = () => {
    if (isCancelledRaffleNextPage) {
      getCancelledRaffle("lazy");
    }
  };

  return (
    <>
      <div className="desk-top-view">
        {isLoading && <PreLoader />}
        {/* {!isLoading && ( */}
        <div
          id="home_content_container"
          className="d-flex flex-column flex-column-fluid"
        >
          {/* <section id="hero_section">
              <div className="toolbar py-5 py-lg-15" id="kt_toolbar">
                <div
                  id="kt_toolbar_container"
                  className="container-xxl text-center"
                >
                  <div className="align-items-center">
                    <h1 className="heading-1 mt-3 text-white">
                      Discover <img src={heading_img_1} /> Collect and Sell
                      Super Raffles.
                    </h1>
                    <h2 className="heading-2 mt-5 text-white">
                      Explore and enter the best Raffles on solana
                    </h2>
                  </div>

                  <div className="d-flex flex-column">
                    <div className="d-lg-flex align-lg-items-center">
                      <div className="search-container-box  rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px my-5">
                        <div className="row flex-grow-1 mb-5 mb-lg-0">
                          <div className="col-lg-12 d-flex align-items-center mb-3 mb-lg-0">
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  opacity="0.5"
                                  x="17.0365"
                                  y="15.1223"
                                  width="8.15546"
                                  height="2"
                                  rx="1"
                                  transform="rotate(45 17.0365 15.1223)"
                                  fill="currentColor"
                                ></rect>
                                <path
                                  d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </span>

                            <input
                              type="text"
                              className="form-control form-control-flush flex-grow-1"
                              name="search"
                              value={search}
                              onChange={filter}
                              placeholder="Your Search"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {foundRaffle.length > 0 && (
                      <ul className="drop">
                        {foundRaffle.map((raffle, index) => (
                          <li onClick={call_raffle_details(raffle)}>
                            <div>
                              <span>
                                {raffle["imageData"] !== null ? (
                                  <img
                                    className="search-filer-img"
                                    alt="raffle_imagedd"
                                    src={
                                      raffle["imageData"]
                                    }
                                  />
                                ) : (
                                  <img alt="raffle_image" src={nft_img_1} />
                                )}
                              </span>
                              <span className="text-white search-result-text">
                                {raffle.raffleName}
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>

                  <div className="d-flex align-items-center py-3 py-md-1"></div>
                </div>
              </div>
            </section> */}

          <div className="container-xxl" id="kt_content">
            {featuredRaffle.length > 0 && (
              <div className="featured-container">
                {featuredRaffle.length > 0 && (
                  <div className="row featured-row">
                    <div className="col-xl-8">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="fw-bold text-white featured-raffle-text">
                          Featured Raffles
                        </span>
                      </h3>
                    </div>

                    <div className="col-xl-4">
                      <div className="card-toolbar mb-10"></div>
                    </div>
                  </div>
                )}
                <div className="row featured-row">
                  {(isMobile && featuredRaffle.length === 1) || (!isMobile && featuredRaffle.length <= 4) ? (
                    <>
                      {featuredRaffle.map((raffle, index) => (
                        <div className="col-xl-3">
                          <div className="card card-xl-stretch mb-xl-10 theme-dark-bg-body  featured-raffle raffle-home-card">
                            <div className="card-body d-flex  flex-column">
                              <div className="raffle-img-on-card text-center">
                                {raffle["imageData"] !== null ? (
                                  <img
                                    alt="raffle_imagedd"
                                    src={raffle["imageData"]}
                                  />
                                ) : (
                                  <img alt="raffle_image" src={nft_img_1} />
                                )}
                              </div>
                              {!raffle["raffle_ended"] && (
                                <MyTimer expiryTimestamp={raffle["timer"]} />
                              )}
                              {raffle["raffle_ended"] && (
                                <div className="raffle-time fs-8 fw-bold">
                                  Ended On :{" "}
                                  {moment
                                    .unix(raffle["raffleEndTime"])
                                    .format("MMM Do, YY")}
                                </div>
                              )}
                              {raffle["rafflePriceType"] === "nft" ? (
                                <div className="collection-name pl-0">
                                  {raffle["collectionName"]}{" "}
                                  <img
                                    className="collection-img"
                                    src={collection_img}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="raffle-name mb-0">
                                {raffle["raffleName"]}
                              </div>



                              <div className="mb-1 ticket-container">
                                <div className="d-flex">
                                  <div className="py-3 mx-2 tickets-sold">
                                    <div className="fw-semibold ticket-sold-label">
                                      Tickets Sold
                                    </div>
                                    <div className="fs-6 fw-bold text-blue">
                                      <span className="ticket-sold">
                                        {" "}
                                        {raffle["ticket_bought"]}{" "}
                                      </span>
                                      /
                                      <span className="ticket-remain">
                                        {raffle["tickt_size"]}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="py-3 mx-2">
                                    <div className="fw-semibold ticket-sold-label">
                                      Threshold
                                    </div>
                                    <div className="fs-6 fw-bold text-blue">
                                      <span className="ticket-sold">
                                        {" "}
                                        {Number(raffle["threshold"]).toFixed()}{" "}
                                        {raffle["ticket_bought"] <
                                        raffle["threshold"] ? (
                                          <>
                                            <i className="ticket-threshold-icon-warning fa fa-exclamation-circle"></i>
                                          </>
                                        ) : (
                                          <>
                                            <i className="ticket-threshold-icon-success fa fa-check-circle"></i>
                                          </>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="py-3 mx-2">
                                  <div className="fw-semibold ticket-price-label">
                                    Ticket Price
                                  </div>
                                  <div className="fs-6 fw-bold">
                                    <span className="ticket-price">
                                      {" "}
                                      {raffle["ticket_prize"]}{" "}
                                      {raffle["ticket_prize_type"]}
                                      {/* {find_ticket_token(
                                  raffle["ticket_price"],
                                  raffle["ticket_mint"]
                                )} */}
                                    </span>
                                    {raffle["ticket_type"] === "multi" && (
                                      <span className="ticket-price-2">
                                        {"+"} {raffle["ticket_prize_1"]}{" "}
                                        {raffle["ticket_prize_type_1"]}
                                        {/* {find_ticket_token(
                                  raffle["ticket_price"],
                                  raffle["ticket_mint"]
                                )} */}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <button
                                className="btn view-raffle-btn"
                                onClick={call_raffle_details(raffle)}
                              >
                                <span className="view-raffle-text">
                                  {raffle.close === 1
                                    ? "Raffle Closed"
                                    : "View Raffle"}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ):""}
                  {(isMobile && featuredRaffle.length > 1) || (featuredRaffle.length > 4) ? (
                    <Slider {...settings}>
                      {featuredRaffle.map((raffle, index) => (
                        <div className="col-xl-3">
                          <div className="card card-xl-stretch mb-xl-10 theme-dark-bg-body  featured-raffle raffle-home-card">
                            <div className="card-body d-flex  flex-column">
                              <div className="raffle-img-on-card text-center">
                                {raffle["imageData"] !== null ? (
                                  <img
                                    alt="raffle_imagedd"
                                    src={raffle["imageData"]}
                                  />
                                ) : (
                                  <img alt="raffle_image" src={nft_img_1} />
                                )}
                              </div>
                              {!raffle["raffle_ended"] && (
                                <MyTimer expiryTimestamp={raffle["timer"]} />
                              )}
                              {raffle["raffle_ended"] && (
                                <div className="raffle-time fs-8 fw-bold">
                                  Ended On :{" "}
                                  {moment
                                    .unix(raffle["raffleEndTime"])
                                    .format("MMM Do, YY")}
                                </div>
                              )}
                              {raffle["rafflePriceType"] === "nft" ? (
                                <div className="collection-name pl-0">
                                  {raffle["collectionName"]}{" "}
                                  <img
                                    className="collection-img"
                                    src={collection_img}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="raffle-name mb-0">
                                {raffle["raffleName"]}
                              </div>



                              <div className="mb-1 ticket-container">
                                <div className="d-flex">
                                  <div className="py-3 mx-2 tickets-sold">
                                    <div className="fw-semibold ticket-sold-label">
                                      Tickets Sold
                                    </div>
                                    <div className="fs-6 fw-bold text-blue">
                                      <span className="ticket-sold">
                                        {" "}
                                        {raffle["ticket_bought"]}{" "}
                                      </span>
                                      /
                                      <span className="ticket-remain">
                                        {raffle["tickt_size"]}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="py-3 mx-2">
                                    <div className="fw-semibold ticket-sold-label">
                                      Threshold
                                    </div>
                                    <div className="fs-6 fw-bold text-blue">
                                      <span className="ticket-sold">
                                        {" "}
                                        {Number(raffle["threshold"]).toFixed()}{" "}
                                        {raffle["ticket_bought"] <
                                        raffle["threshold"] ? (
                                          <>
                                            <i className="ticket-threshold-icon-warning fa fa-exclamation-circle"></i>
                                          </>
                                        ) : (
                                          <>
                                            <i className="ticket-threshold-icon-success fa fa-check-circle"></i>
                                          </>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="py-3 mx-2">
                                  <div className="fw-semibold ticket-price-label">
                                    Ticket Price
                                  </div>
                                  <div className="fs-6 fw-bold">
                                    <span className="ticket-price">
                                      {" "}
                                      {raffle["ticket_prize"]}{" "}
                                      {raffle["ticket_prize_type"]}
                                      {/* {find_ticket_token(
                                    raffle["ticket_price"],
                                    raffle["ticket_mint"]
                                  )} */}
                                    </span>
                                    {raffle["ticket_type"] === "multi" && (
                                      <span className="ticket-price-2">
                                        {"+"} {raffle["ticket_prize_1"]}{" "}
                                        {raffle["ticket_prize_type_1"]}
                                        {/* {find_ticket_token(
                                    raffle["ticket_price"],
                                    raffle["ticket_mint"]
                                  )} */}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <button
                                className="btn view-raffle-btn"
                                onClick={call_raffle_details(raffle)}
                              >
                                <span className="view-raffle-text">
                                  {raffle.close === 1
                                    ? "Raffle Closed"
                                    : "View Raffle"}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  ):''}
                </div>
              </div>
            )}
            <div
              className={`${
                sticky === "" ? "row pt-5 mt-5" : "row pt-8 mt-5 is-sticky"
              }`}
            >
              <div className="col-xl-8">
                <div className="d-flex">
                  <div
                    onClick={() => home_tab_change("active")}
                    className={`${
                      homeActiveTab === "active"
                        ? "home-tab active py-3 px-5 mx-2 mb-3"
                        : "home-tab py-3 px-5 mx-2 mb-3"
                    }`}
                  >
                    Active
                  </div>
                  <div
                    onClick={() => home_tab_change("past")}
                    className={`${
                      homeActiveTab === "past"
                        ? "home-tab active py-3 px-5 mx-2 mb-3"
                        : "home-tab py-3 px-5 mx-2 mb-3"
                    }`}
                  >
                    Past
                  </div>
                  <div
                    onClick={() => home_tab_change("cancelled")}
                    className={`${
                      homeActiveTab === "cancelled"
                        ? "home-tab active py-3 px-5 mx-2 mb-3"
                        : "home-tab py-3 px-5 mx-2 mb-3"
                    }`}
                  >
                    Cancelled
                  </div>
                  <div
                    onClick={() => home_tab_change("dao_list")}
                    className={`${
                      homeActiveTab === "dao_list"
                        ? "home-tab active py-3 px-5 mx-2 mb-3"
                        : "home-tab py-3 px-5 mx-2 mb-3"
                    }`}
                  >
                    DAO
                  </div>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="card-toolbar mb-10">
                  {/* <div className="d-flex align-items-center float-right">
                    <span className="fs-7 fw-bold text-gray-700 pe-4 text-nowrap d-none d-xxl-block">
                      Sort By:
                    </span>

                    <select
                      className="form-select form-select-sm form-select-solid sort-by-select"
                      aria-hidden="true"
                    >
                      <option
                        value=""
                        data-select2-id="select2-data-128-3ifn"
                      ></option>
                      <option value="1" data-select2-id="select2-data-9-0hl4">
                        All
                      </option>
                      <option value="2" data-select2-id="select2-data-129-sfxc">
                        Individual
                      </option>
                      <option value="3" data-select2-id="select2-data-130-pd72">
                        DAO
                      </option>
                    </select>
                  </div> */}
                </div>
              </div>
            </div>

            {homeActiveTab === "active" && (
              <>
                <InfiniteScroll
                  dataLength={activeRaffle.length}
                  next={fetchMoreActiveRaffle}
                  hasMore={true}
                  loader=""
                  scrollThreshold="10px"
                >
                  {activeRaffle.length > 0 && (
                    <div className="row gy-5 g-xl-10 mt-5">
                      {activeRaffle.map((raffle, index) => (
                        <div className="col-xl-3">
                          <div className="card card-xl-stretch mb-xl-10 theme-dark-bg-body raffle-home-card">
                            <div className="card-body d-flex  flex-column">
                              <div className="raffle-img-on-card text-center">
                                {raffle["imageData"] !== null ? (
                                  <img
                                    alt="raffle_imagedd"
                                    src={raffle["imageData"]}
                                  />
                                ) : (
                                  <img alt="raffle_image" src={nft_img_1} />
                                )}
                              </div>
                              {!raffle["raffle_ended"] && (
                                <MyTimer expiryTimestamp={raffle["timer"]} />
                              )}
                              {raffle["raffle_ended"] && (
                                <div className="raffle-time fs-8 fw-bold">
                                  Ended On :{" "}
                                  {moment
                                    .unix(raffle["raffleEndTime"])
                                    .format("MMM Do, YY")}
                                </div>
                              )}
                              {raffle["rafflePriceType"] === "nft" ? (
                                <div className="collection-name pl-0">
                                  {raffle["collectionName"]}{" "}
                                  <img
                                    className="collection-img"
                                    src={collection_img}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="raffle-name mb-0">
                                {raffle["raffleName"]}
                              </div>

                              {/* <div className="fw-semibold text-gray-400 mb-6 overflow">
                        {raffle["creator"]}
                      </div> */}

                              <div className="mb-1 ticket-container">
                                <div className="d-flex">
                                  <div className="py-3 mx-2 tickets-sold">
                                    <div className="fw-semibold ticket-sold-label">
                                      Tickets Sold
                                    </div>
                                    <div className="fs-6 fw-bold text-blue">
                                      <span className="ticket-sold">
                                        {" "}
                                        {raffle["ticket_bought"]}{" "}
                                      </span>
                                      /
                                      <span className="ticket-remain">
                                        {raffle["tickt_size"]}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="py-3 mx-2">
                                    <div className="fw-semibold ticket-sold-label">
                                      Threshold
                                    </div>
                                    <div className="fs-6 fw-bold text-blue">
                                      <span className="ticket-sold">
                                        {" "}
                                        {Number(raffle["threshold"]).toFixed()}{" "}
                                        {raffle["ticket_bought"] <
                                        raffle["threshold"] ? (
                                          <>
                                            <i className="ticket-threshold-icon-warning fa fa-exclamation-circle"></i>
                                          </>
                                        ) : (
                                          <>
                                            <i className="ticket-threshold-icon-success fa fa-check-circle"></i>
                                          </>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="py-2 mx-2">
                                  <div className="fw-semibold ticket-price-label">
                                    Ticket Price
                                  </div>
                                  <div className="fs-6 fw-bold">
                                    <span className="ticket-price">
                                      {" "}
                                      {raffle["ticket_prize"]}{" "}
                                      {raffle["ticket_prize_type"]}
                                      {/* {find_ticket_token(
                                    raffle["ticket_price"],
                                    raffle["ticket_mint"]
                                  )} */}
                                    </span>
                                    {raffle["ticket_type"] === "multi" && (
                                      <span className="ticket-price-2">
                                        {"+"} {raffle["ticket_prize_1"]}{" "}
                                        {raffle["ticket_prize_type_1"]}
                                        {/* {find_ticket_token(
                                    raffle["ticket_price"],
                                    raffle["ticket_mint"]
                                  )} */}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <button
                                className="btn view-raffle-btn"
                                onClick={call_raffle_details(raffle)}
                              >
                                <span className="view-raffle-text">
                                  {raffle.close === 1
                                    ? "Raffle Closed"
                                    : "View Raffle"}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </InfiniteScroll>
                {activeRaffle.length === 0 && !isPreLoading && (
                  <div className="row gy-5 g-xl-10">
                    <div className="col-xl-12 text-center">
                      <img width="170" src={no_raffle_img} alt="no_raffle" />
                      <div className="fs-2 text-muted">No active Raffles</div>
                    </div>
                  </div>
                )}
              </>
            )}

            {homeActiveTab === "past" && (
              <>
                <InfiniteScroll
                  dataLength={pastRaffle.length}
                  next={fetchMorePastRaffle}
                  hasMore={true}
                  loader=""
                  scrollThreshold="10px"
                >
                  {pastRaffle.length > 0 && (
                    <div className="row gy-5 g-xl-10 mt-5">
                      {pastRaffle.map((raffle, index) => (
                        <div className="col-xl-3">
                          <div
                            className={`${
                              publicKey &&
                              raffle["winnerList"] !== null &&
                              raffle["winnerList"]["list"].length === 1 &&
                              raffle["winnerList"]["list"][0] ===
                                publicKey.toString()
                                ? "card card-xl-stretch mb-xl-10 theme-dark-bg-body raffle-home-card winner"
                                : "card card-xl-stretch mb-xl-10 theme-dark-bg-body raffle-home-card"
                            }`}
                          >
                            <div className="card-body d-flex  flex-column">
                              <div className="raffle-img-on-card text-center">
                                {raffle["imageData"] !== null ? (
                                  <img
                                    alt="raffle_imagedd"
                                    src={raffle["imageData"]}
                                  />
                                ) : (
                                  <img alt="raffle_image" src={nft_img_1} />
                                )}
                              </div>

                              <div className="raffle-time fs-8 fw-bold">
                                Ended On :{" "}
                                {moment
                                  .unix(raffle["raffleEndTime"])
                                  .format("MMM Do, YY")}
                              </div>

                              {raffle["rafflePriceType"] === "nft" ? (
                                <div className="collection-name pl-0">
                                  {raffle["collectionName"]}{" "}
                                  <img
                                    className="collection-img"
                                    src={collection_img}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="raffle-name mb-0">
                                {raffle["raffleName"]}
                              </div>

                              {/* <div className="fw-semibold text-gray-400 mb-6 overflow">
                        {raffle["creator"]}
                      </div> */}

                              <div className="mb-1 ticket-container">
                                <div className="d-flex">
                                  <div className="py-3 mx-2 tickets-sold">
                                    <div className="fw-semibold ticket-sold-label">
                                      Tickets Sold
                                    </div>
                                    <div className="fs-6 fw-bold text-blue">
                                      <span className="ticket-sold">
                                        {" "}
                                        {raffle["ticket_bought"]}{" "}
                                      </span>
                                      /
                                      <span className="ticket-remain">
                                        {raffle["tickt_size"]}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="py-3 mx-2">
                                    <div className="fw-semibold ticket-sold-label">
                                      Threshold
                                    </div>
                                    <div className="fs-6 fw-bold text-blue">
                                      <span className="ticket-sold">
                                        {" "}
                                        {Number(raffle["threshold"]).toFixed()}{" "}
                                        {raffle["ticket_bought"] <
                                        raffle["threshold"] ? (
                                          <>
                                            <i className="ticket-threshold-icon-warning fa fa-exclamation-circle"></i>
                                          </>
                                        ) : (
                                          <>
                                            <i className="ticket-threshold-icon-success fa fa-check-circle"></i>
                                          </>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="py-2 mx-2">
                                  <div className="fw-semibold ticket-price-label">
                                    Ticket Price
                                  </div>
                                  <div className="fs-6 fw-bold">
                                    <span className="ticket-price">
                                      {" "}
                                      {raffle["ticket_prize"]}{" "}
                                      {raffle["ticket_prize_type"]}
                                      {/* {find_ticket_token(
                                    raffle["ticket_price"],
                                    raffle["ticket_mint"]
                                  )} */}
                                    </span>
                                    {raffle["ticket_type"] === "multi" && (
                                      <span className="ticket-price-2">
                                        {"+"} {raffle["ticket_prize_1"]}{" "}
                                        {raffle["ticket_prize_type_1"]}
                                        {/* {find_ticket_token(
                                    raffle["ticket_price"],
                                    raffle["ticket_mint"]
                                  )} */}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                {raffle["winnerList"] !== null &&
                                raffle["winnerList"]["list"].length === 1 ? (
                                  <div className="py-2 mx-2">
                                    <div className="fw-semibold ticket-price-label">
                                      Winner
                                    </div>
                                    <div className="fs-6 fw-bold">
                                      <span className="raffle-winner">
                                        {raffle["winnerList"][
                                          "list"
                                        ][0].substring(0, 4)}
                                        ...
                                        {raffle["winnerList"]["list"][0].slice(
                                          -4
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <button
                                className="btn view-raffle-btn"
                                onClick={call_raffle_details(raffle)}
                              >
                                {publicKey &&
                                raffle["winnerList"] !== null &&
                                raffle["winnerList"]["list"].length === 1 &&
                                raffle["winnerList"]["list"][0] ===
                                  publicKey.toString() ? (
                                  <i className="bi bi-bookmark-star"></i>
                                ) : (
                                  ""
                                )}
                                <span className="view-raffle-text">
                                  {raffle.close === 1
                                    ? "Raffle Closed"
                                    : "View Raffle"}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </InfiniteScroll>
                {pastRaffle.length === 0 && !isPreLoading && (
                  <div className="row gy-5 g-xl-10">
                    <div className="col-xl-12 text-center">
                      <img width="170" src={no_raffle_img} alt="no_raffle" />
                      <div className="fs-2 text-muted">No past Raffles</div>
                    </div>
                  </div>
                )}
              </>
            )}

            {homeActiveTab === "cancelled" && (
              <>
                <InfiniteScroll
                  dataLength={cancelledRaffle.length}
                  next={fetchMoreCancelledRaffle}
                  hasMore={true}
                  loader=""
                  scrollThreshold="10px"
                >
                  {cancelledRaffle.length > 0 && (
                    <div className="row gy-5 g-xl-10 mt-5">
                      {cancelledRaffle.map((raffle, index) => (
                        <div className="col-xl-3">
                          <div className="card card-xl-stretch mb-xl-10 theme-dark-bg-body raffle-home-card">
                            <div className="card-body d-flex  flex-column">
                              <div className="raffle-img-on-card text-center">
                                {raffle["imageData"] !== null ? (
                                  <img
                                    alt="raffle_imagedd"
                                    src={raffle["imageData"]}
                                  />
                                ) : (
                                  <img alt="raffle_image" src={nft_img_1} />
                                )}
                              </div>

                                <div className="raffle-time fs-8 fw-bold">
                                  Ended On :{" "}
                                  {moment
                                    .unix(raffle["raffleEndTime"])
                                    .format("MMM Do, YY")}
                                </div>

                              {raffle["rafflePriceType"] === "nft" ? (
                                <div className="collection-name pl-0">
                                  {raffle["collectionName"]}{" "}
                                  <img
                                    className="collection-img"
                                    src={collection_img}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="raffle-name mb-0">
                                {raffle["raffleName"]}
                              </div>

                              {/* <div className="fw-semibold text-gray-400 mb-6 overflow">
                        {raffle["creator"]}
                      </div> */}

                              <div className="mb-1 ticket-container">
                                <div className="d-flex">
                                  <div className="py-3 mx-2 tickets-sold">
                                    <div className="fw-semibold ticket-sold-label">
                                      Tickets Sold
                                    </div>
                                    <div className="fs-6 fw-bold text-blue">
                                      <span className="ticket-sold">
                                        {" "}
                                        {raffle["ticket_bought"]}{" "}
                                      </span>
                                      /
                                      <span className="ticket-remain">
                                        {raffle["tickt_size"]}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="py-3 mx-2">
                                    <div className="fw-semibold ticket-sold-label">
                                      Threshold
                                    </div>
                                    <div className="fs-6 fw-bold text-blue">
                                      <span className="ticket-sold">
                                        {" "}
                                        {Number(raffle["threshold"]).toFixed()}{" "}
                                        {raffle["ticket_bought"] <
                                        raffle["threshold"] ? (
                                          <>
                                            <i className="ticket-threshold-icon-warning fa fa-exclamation-circle"></i>
                                          </>
                                        ) : (
                                          <>
                                            <i className="ticket-threshold-icon-success fa fa-check-circle"></i>
                                          </>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="py-2 mx-2">
                                  <div className="fw-semibold ticket-price-label">
                                    Ticket Price
                                  </div>
                                  <div className="fs-6 fw-bold">
                                    <span className="ticket-price">
                                      {" "}
                                      {raffle["ticket_prize"]}{" "}
                                      {raffle["ticket_prize_type"]}
                                      {/* {find_ticket_token(
                                    raffle["ticket_price"],
                                    raffle["ticket_mint"]
                                  )} */}
                                    </span>
                                    {raffle["ticket_type"] === "multi" && (
                                      <span className="ticket-price-2">
                                        {"+"} {raffle["ticket_prize_1"]}{" "}
                                        {raffle["ticket_prize_type_1"]}
                                        {/* {find_ticket_token(
                                    raffle["ticket_price"],
                                    raffle["ticket_mint"]
                                  )} */}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <button
                                className="btn view-raffle-btn"
                                onClick={call_raffle_details(raffle)}
                              >
                                <span className="view-raffle-text">
                                  {raffle.close === 1
                                    ? "Raffle Closed"
                                    : "View Raffle"}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </InfiniteScroll>

                {cancelledRaffle.length === 0 && !isPreLoading && (
                  <div className="row gy-5 g-xl-10">
                    <div className="col-xl-12 text-center">
                      <img width="170" src={no_raffle_img} alt="no_raffle" />
                      <div className="fs-2 text-muted">
                        No cancelled Raffles
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {homeActiveTab === "dao_list" && (
              <div className="row gy-5 g-xl-10 mt-5">
                {allDao.map((dao, index) => (
                  <div className="col-xl-4">
                    <div className="table-responsive">
                      <table className="table dao-table align-middle gs-0 gy-4">
                        <thead>
                          <tr className="border-0">
                            <th className="p-0 w-50px"></th>
                            <th className="p-0 min-w-150px"></th>
                            <th className="p-0 min-w-50px"></th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr className="dao-table-row">
                            <td>
                              <div className="symbol symbol-45px me-2">
                                <span className="">
                                  <img
                                    src={
                                      dao.imageData !== null
                                        ? dao.imageData
                                        : dao_collection_img
                                    }
                                    className="dao-image-home"
                                    alt="dao_image"
                                  />
                                </span>
                              </div>
                            </td>
                            <td>
                              <Link
                                to={`/dao/${dao.daoUrl}`}
                                className="dao-name-home"
                              >
                                {dao.daoName}
                              </Link>
                              <span className="d-block dao-raffle-count">
                                Total Raffles : {dao.raffle_count}
                              </span>
                            </td>

                            <td className="text-end">
                              <Link
                                to={`/dao/${dao.daoUrl}`}
                                className="btn btn-sm btn-icon dao-link-bg"
                              >
                                <span className="svg-icon svg-icon-2">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x="18"
                                      y="13"
                                      width="13"
                                      height="2"
                                      rx="1"
                                      transform="rotate(-180 18 13)"
                                      fill="currentColor"
                                    ></rect>
                                    <path
                                      d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </span>
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {isPreLoading && (
              <div className="spinner-container-v1">
                <div className="spinner-v1"></div>
              </div>
            )}
            <div>
              {showScroll && (
                <a className="back-to-top-button" onClick={scrollToTop}></a>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-view text-white fs-2">
        <img src={logo_white} />
        <div>Please use this app in desktop for better experience</div>
      </div>
    </>
  );
};
