import {
  AccountMeta,
  Connection,
  LAMPORTS_PER_SOL,
  MemcmpFilter,
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  Transaction,
  TransactionInstruction,
  Keypair,
} from "@solana/web3.js";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  Token,
  AccountLayout,
} from "@solana/spl-token";
import {
  Metadata,
  MetadataDataData,
} from "@metaplex-foundation/mpl-token-metadata";
import * as bs58 from "bs58";
import { serialize, deserialize, deserializeUnchecked } from "borsh";
/* global BigInt */

export const PROGRAM_ID = new PublicKey(process.env.REACT_APP_PROGRAM_ID);
const ENTROPY_PRICE = new PublicKey(process.env.REACT_APP_ENTROPY_PRICE);

const FEES_VAULT = new PublicKey(process.env.REACT_APP_FEEWALLET);
const FEES_VAULT_TOKEN = new PublicKey(
  "AqmpPpsjnM7KvtVdAYtC6nNKGGMZaUtXDEY32Z7AGw9a"
);

class Assignable {
  constructor(properties) {
    Object.keys(properties).map((key) => {
      return (this[key] = properties[key]);
    });
  }
}
class Payload extends Assignable {}

function createInstructionData(
  instruction: string,
  raffle_program_word: String,
  winner_count,
  prize_amount_per_winner,
  end_timestamp,
  max_entries,
  ticket_price,
  max_tickets_per_wallet,
  is_dao,
  has_escrow,
  winner_list,
  ticket_amount,
  bought_tickets,
  is_holder,
  ticket_price_1,
  ticket_price_2,
  is_dao_only,
  threshold_tickets
): any {
  const RafflePayloadSchema = new Map([
    [
      Payload,
      {
        kind: "struct",
        fields: [
          ["id", "u8"],
          ["winner_count", "u64"],
          ["raffle_program_word", "string"],
          ["prize_amount_per_winner", "u64"],
          ["end_timestamp", "u64"],
          ["max_entries", "u64"],
          ["ticket_price", "u64"],
          ["max_tickets_per_wallet", "u64"],
          ["is_dao", "u8"],
          ["has_escrow", "u8"],
          ["is_holder", "u8"],
          ["ticket_price_1", "u64"],
          ["ticket_price_2", "u64"],
          ["is_dao_only", "u8"],
          ["threshold_tickets", "u64"],
        ],
      },
    ],
  ]);

  const BuyTicketdPayloadSchema = new Map([
    [
      Payload,
      {
        kind: "struct",
        fields: [
          ["id", "u8"],
          ["raffle_program_word", "string"],
          ["ticket_amount", "u64"],
          ["bought_ticket", "u64"],
          ["is_holder", "u8"],
        ],
      },
    ],
  ]);

  const RevealWLWinnersPayloadSchema = new Map([
    [
      Payload,
      {
        kind: "struct",
        fields: [
          ["id", "u8"],
          ["raffle_program_word", "string"],
          ["winner_list", ["u64"]],
          ["is_holder", "u8"],
        ],
      },
    ],
  ]);

  const RaffleWordPayloadSchema = new Map([
    [
      Payload,
      {
        kind: "struct",
        fields: [
          ["id", "u8"],
          ["raffle_program_word", "string"],
        ],
      },
    ],
  ]);
  const RevealNFTWinnerPayloadSchema = new Map([
    [
      Payload,
      {
        kind: "struct",
        fields: [
          ["id", "u8"],
          ["raffle_program_word", "string"],
          ["is_holder", "u8"],
        ],
      },
    ],
  ]);
  const WLPayloadSchema = new Map([
    [
      Payload,
      {
        kind: "struct",
        fields: [["id", "u8"]],
      },
    ],
  ]);

  if (instruction === "RaffleInit") {
    const Data = new Payload({
      id: 0,
      winner_count,
      raffle_program_word,
      prize_amount_per_winner,
      end_timestamp,
      max_entries,
      ticket_price,
      max_tickets_per_wallet,
      is_dao,
      has_escrow,
      ticket_price_1,
      ticket_price_2,
      is_holder,
      is_dao_only,
      threshold_tickets,
    });
    return Buffer.from(serialize(RafflePayloadSchema, Data));
  } else if (instruction === "BuyTickets") {
    const Data = new Payload({
      id: 1,
      raffle_program_word,
      ticket_amount,
      bought_ticket: bought_tickets,
      is_holder,
    });
    return Buffer.from(serialize(BuyTicketdPayloadSchema, Data));
  } else if (instruction === "RevealWinner") {
    const Data = new Payload({
      id: 2,
      raffle_program_word,
      is_holder,
    });
    return Buffer.from(serialize(RevealNFTWinnerPayloadSchema, Data));
  } else if (instruction === "RevealWLWinners") {
    const Data = new Payload({
      id: 3,
      raffle_program_word,
      winner_list,
      is_holder,
    });
    return Buffer.from(serialize(RevealWLWinnersPayloadSchema, Data));
  } else if (instruction === "ClaimPrize") {
    const Data = new Payload({
      id: 4,
      raffle_program_word,
    });
    return Buffer.from(serialize(RaffleWordPayloadSchema, Data));
  } else if (instruction === "ClaimRefund") {
    const Data = new Payload({
      id: 10,
      raffle_program_word,
    });
    return Buffer.from(serialize(RaffleWordPayloadSchema, Data));
  } else if (instruction === "ClaimWL") {
    const Data = new Payload({
      id: 7,
      raffle_program_word,
    });
    return Buffer.from(serialize(RaffleWordPayloadSchema, Data));
  } else if (instruction === "PrizeTransfer") {
    const Data = new Payload({
      id: 8,
      raffle_program_word,
    });
    return Buffer.from(serialize(RaffleWordPayloadSchema, Data));
  } else if (instruction === "CancelRaffle") {
    const Data = new Payload({
      id: 5,
      raffle_program_word,
    });
    return Buffer.from(serialize(RaffleWordPayloadSchema, Data));
  } else if (instruction === "CloseRaffle") {
    const Data = new Payload({
      id: 9,
      raffle_program_word,
    });
    return Buffer.from(serialize(RaffleWordPayloadSchema, Data));
  } else if (instruction === "AddToWL") {
    const Data = new Payload({
      id: 6,
    });
    return Buffer.from(serialize(WLPayloadSchema, Data));
  }
  throw new Error(`Unrecognized instruction: ${instruction}`);
}

function parseUint64Le(data: Uint8Array, offset: number = 0): bigint {
  let number = BigInt(0);
  for (let i = 0; i < 8; i++)
    number += BigInt(data[offset + i]) << BigInt(i * 8);
  return number;
}

function getAssociatedTokenAddress(
  walletAddress: PublicKey,
  tokenAddress: PublicKey,
  allowOffCurve: boolean = false
): Promise<PublicKey> {
  return Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    tokenAddress,
    walletAddress,
    allowOffCurve
  );
}

function transactionKey(
  pubkey: PublicKey,
  isSigner: boolean,
  isWritable: boolean = true
): AccountMeta {
  return {
    pubkey,
    isSigner,
    isWritable,
  };
}

export async function getRaffleDataAddress(
  programWord: String
): Promise<PublicKey> {
  let [address] = await PublicKey.findProgramAddress(
    [Buffer.from(programWord)],
    PROGRAM_ID
  );
  return address;
}

export async function RaffleData(
  connection: Connection,
  raffleWord: String
): Promise<any> {
  let raffleDataAddress = await getRaffleDataAddress(raffleWord);
  let raffleAccountInfo = await connection.getAccountInfo(raffleDataAddress);
  if (!raffleAccountInfo)
      return undefined
    // throw new Error(`${raffleDataAddress} not initialized`);
  let { data } = raffleAccountInfo;
  // console.log(data);
  let tempData = {
    timestamp: Number(parseUint64Le(data, 0)),
    creator: new PublicKey(data.slice(8, 40)).toBase58(),
    prize_key: new PublicKey(data.slice(40, 72)).toBase58(),
    active: new TextDecoder("utf-8").decode(data.slice(72, 73)),
    hasEscrow: new TextDecoder("utf-8").decode(data.slice(73, 74)),
    end_timestamp: Number(parseUint64Le(data, 74)),
    winner_count: Number(parseUint64Le(data, 82)),
    max_entries: Number(parseUint64Le(data, 90)),
    total_entries: Number(parseUint64Le(data, 98)),
    ticket_price: Number(parseUint64Le(data, 106)),
    prize_amount_per_winner: Number(parseUint64Le(data, 114)),
    max_tickets_per_wallet: Number(parseUint64Le(data, 122)),
    ticket_mint_1: new PublicKey(data.slice(130, 162)).toBase58(),
    account_pubkey: new PublicKey(data.slice(162, 194)).toBase58(),
    ticket_mint_2: new PublicKey(data.slice(194, 226)).toBase58(),
    ticket_price_1: Number(parseUint64Le(data, 226)),
    ticket_price_2: Number(parseUint64Le(data, 234)),
    is_holder: new TextDecoder("utf-8").decode(data.slice(242, 243)),
    dao_only_cmid: new PublicKey(data.slice(243, 275)).toBase58(),
    threshold_ticket: Number(parseUint64Le(data, 275)),
    // is_cancelled: new TextDecoder("utf-8").decode(data.slice(275,276)),
    is_cancelled: Number(parseUint64Le(data, 283)),
    entries: [],
    winners: [],
    pending: [],
  };
  // console.log("test", ( new PublicKey(data.slice(298,330))).toBase58())
  let arrayAccountInfo = await connection.getAccountInfo(
    new PublicKey(data.slice(162, 194))
  );
  if (!arrayAccountInfo) return undefined;
  {
    let { data } = arrayAccountInfo;
    // console.log()
    let next;
    for (let i = 0; i < tempData.total_entries; i++) {
      tempData.entries.push(
        new PublicKey(data.slice(80 + i * 32, 80 + 32 + i * 32)).toBase58()
      );
      next = 80 + 32 + i * 32;
    }
    let next2;
    if (tempData.active == "\u0000" && (Number(parseUint64Le(data, 64)) > 0)) {
      for (
        let i = 0;
        i < Math.min(tempData.winner_count, tempData.total_entries);
        i++
      ) {
        tempData.winners.push(
          new PublicKey(
            data.slice(next + i * 32, next + 32 + i * 32)
          ).toBase58()
        );
        next2 = next + 32 + i * 32;
      }
    }
    if (tempData.active == "\u0000" && (Number(parseUint64Le(data, 64)) > 0)) {
      for (
        let i = 0;
        i < Math.min(tempData.winner_count, tempData.total_entries);
        i++
      ) {
        tempData.pending.push(
          new PublicKey(
            data.slice(next2 + i * 32, next2 + 32 + i * 32)
          ).toBase58()
        );
      }
    }
  }
  // console.log(tempData);
  return tempData;
}
const WHITELIST_PREFIX = "whitelist";
export async function getWhitelistDataAddress(
  token: PublicKey
): Promise<PublicKey> {
  let [address] = await PublicKey.findProgramAddress(
    [Buffer.from(WHITELIST_PREFIX), token.toBytes()],
    PROGRAM_ID
  );
  return address;
}

export async function getAllTokens(
  connection: Connection,
  wallet: PublicKey
): Promise<any> {
  const tokenAccounts = await connection.getTokenAccountsByOwner(wallet, {
    programId: TOKEN_PROGRAM_ID,
  });
  tokenAccounts.value.forEach(async (e) => {
    const accountInfo = AccountLayout.decode(e.account.data);

    //Tokens
    let addy = await getWhitelistDataAddress(new PublicKey(accountInfo.mint));
    let addyAccountInfo = await connection.getAccountInfo(addy);

    if (addyAccountInfo) {
      console.log(
        `${new PublicKey(accountInfo.mint)}   ${parseUint64Le(
          accountInfo.amount
        )}`
      );
    }
    // console.log(JSON.stringify(accountInfo));
  });

  //NFTs
  const walletNfts = await Metadata.findDataByOwner(connection, wallet);
  await Promise.all(
    walletNfts.map(async ({ mint, data }) => {
      if (data.creators && data.creators[0]?.verified) {
        //NFTs
        let addy = await getWhitelistDataAddress(
          new PublicKey(data.creators[0].address)
        );
        let addyAccountInfo = await connection.getAccountInfo(addy);
        if (addyAccountInfo) {
          console.log(`${new PublicKey(mint)}`);
        }
      }
    })
  );

  return;
}

export async function createAccountTransaction(
  connection: Connection,
  creator: PublicKey,
  raffleWord: String,
  winner_count: any,
  max_entries: any,
  dataAddress: PublicKey
): Promise<Transaction> {
  let transaction = new Transaction();
  // let raffleAddress = await getRaffleDataAddress(raffleWord);
  let size =
    8 +
    32 +
    32 +
    32 * max_entries +
    8 +
    32 * winner_count +
    8 +
    32 * winner_count +
    24;
  let pid = new PublicKey(PROGRAM_ID);
  let rent = await connection.getMinimumBalanceForRentExemption(size);
  const instruction = SystemProgram.createAccount({
    fromPubkey: creator,
    newAccountPubkey: dataAddress,
    space: size,
    lamports: rent,
    programId: pid,
  });
  transaction.add(instruction);
  return transaction;
}

export async function createRaffleInitTransaction(
  connection: Connection,
  creator: PublicKey,
  raffleWord: String,
  winner_count: any,
  prize_amount_per_winner: any,
  end_timestamp: any,
  max_entries: any,
  ticket_price: any,
  ticket_price_1: any,
  ticket_price_2: any,
  max_tickets_per_wallet: any,
  isDao: any,
  hasEscrow: any,
  ticket_token_1: PublicKey,
  ticket_token_2: PublicKey,
  prize_token: PublicKey,
  data_account: PublicKey,
  checkWLtoken: PublicKey,
  isHolder: any,
  isDaoOnly: any,
  dao_cmid: PublicKey,
  threshold_tickets: any
): Promise<Transaction> {
  let transaction = new Transaction();
  transaction.add(
    await createAccountTransaction(
      connection,
      creator,
      raffleWord,
      winner_count,
      max_entries,
      data_account
    )
  );
  transaction.add(
    await createRaffleInitInstruction(
      creator,
      raffleWord,
      winner_count,
      prize_amount_per_winner,
      end_timestamp,
      max_entries,
      ticket_price,
      ticket_price_1,
      ticket_price_2,
      max_tickets_per_wallet,
      isDao,
      hasEscrow,
      ticket_token_1,
      ticket_token_2,
      prize_token,
      data_account,
      checkWLtoken,
      isHolder,
      isDaoOnly,
      dao_cmid,
      threshold_tickets
    )
  );
  return transaction;
}
export async function createRaffleInitInstruction(
  creator: PublicKey,
  raffleWord: String,
  winner_count: any,
  prize_amount_per_winner: any,
  end_timestamp: any,
  max_entries: any,
  ticket_price: any,
  ticket_price_1: any,
  ticket_price_2: any,
  max_tickets_per_wallet: any,
  isDao: any,
  hasEscrow: any,
  ticket_token_1: PublicKey,
  ticket_token_2: PublicKey,
  prize_token: PublicKey,
  data_account: PublicKey,
  checkWLtoken: PublicKey,
  isHolder: any,
  isDaoOnly: any,
  dao_cmid: PublicKey,
  threshold_tickets: any
): Promise<TransactionInstruction> {
  let prizeSourceTokenAccount = await getAssociatedTokenAddress(
    creator,
    prize_token
  );

  let raffleAddress = await getRaffleDataAddress(raffleWord);

  let prizeDestinationTokenAccount = await getAssociatedTokenAddress(
    raffleAddress,
    prize_token,
    true
  );

  let tokenMintAccount_1 = await getAssociatedTokenAddress(
    raffleAddress,
    ticket_token_1,
    true
  );
  let tokenMintAccount_2 = await getAssociatedTokenAddress(
    raffleAddress,
    ticket_token_2,
    true
  );
  let whitelistDataAddress_1 = await getWhitelistDataAddress(ticket_token_1);
  let whitelistDataAddress_2 = await getWhitelistDataAddress(ticket_token_2);
  let whitelistDaoDataAddress = await getWhitelistDataAddress(creator);
  let whitelistMintDataAddress = await getWhitelistDataAddress(checkWLtoken);
  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData(
      "RaffleInit",
      raffleWord,
      winner_count,
      prize_amount_per_winner,
      end_timestamp,
      max_entries,
      ticket_price,
      max_tickets_per_wallet,
      isDao,
      hasEscrow,
      null,
      null,
      null,
      isHolder,
      ticket_price_1,
      ticket_price_2,
      isDaoOnly,
      threshold_tickets
    ),
    keys: [
      transactionKey(creator, true),

      transactionKey(prize_token, false, false),
      transactionKey(prizeSourceTokenAccount, false),
      transactionKey(prizeDestinationTokenAccount, false),

      transactionKey(SystemProgram.programId, false, false),
      transactionKey(SYSVAR_RENT_PUBKEY, false, false),

      transactionKey(TOKEN_PROGRAM_ID, false, false),
      transactionKey(ASSOCIATED_TOKEN_PROGRAM_ID, false, false),

      transactionKey(ticket_token_1, false, false),
      transactionKey(tokenMintAccount_1, false),
      transactionKey(ticket_token_2, false, false),
      transactionKey(tokenMintAccount_2, false),

      transactionKey(whitelistDaoDataAddress, false),
      transactionKey(whitelistMintDataAddress, false),
      transactionKey(checkWLtoken, false, false),
      transactionKey(whitelistDataAddress_1, false),
      transactionKey(whitelistDataAddress_2, false),

      transactionKey(dao_cmid, false, false),

      transactionKey(raffleAddress, false),
      transactionKey(data_account, false),
    ],
  });
}

export async function createRevealWinnerTransaction(
  connection: Connection,
  creator: PublicKey,
  raffleWord: String,
  ticket_token_1: PublicKey,
  ticket_token_2: PublicKey,
  data_account: PublicKey,
  isHolder: any
): Promise<Transaction> {
  let transaction = new Transaction();

  transaction.add(
    await createRevealWinnerInstruction(
      creator,
      raffleWord,
      ticket_token_1,
      ticket_token_2,
      data_account,
      isHolder
    )
  );
  return transaction;
}
export async function createRevealWinnerInstruction(
  creator: PublicKey,
  raffleWord: String,
  ticket_token_1: PublicKey,
  ticket_token_2: PublicKey,
  data_account: PublicKey,
  isHolder: any
): Promise<TransactionInstruction> {
  let raffleAddress = await getRaffleDataAddress(raffleWord);

  let feeDestAccount_1 = await getAssociatedTokenAddress(
    FEES_VAULT,
    ticket_token_1
  );
  let feeDestAccount_2 = await getAssociatedTokenAddress(
    FEES_VAULT,
    ticket_token_2
  );

  let tokenMintSrcAccount_1 = await getAssociatedTokenAddress(
    raffleAddress,
    ticket_token_1,
    true
  );
  let tokenMintDestAccount_1 = await getAssociatedTokenAddress(
    creator,
    ticket_token_1
  );
  let tokenMintSrcAccount_2 = await getAssociatedTokenAddress(
    raffleAddress,
    ticket_token_2,
    true
  );
  let tokenMintDestAccount_2 = await getAssociatedTokenAddress(
    creator,
    ticket_token_2
  );
  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData(
      "RevealWinner",
      raffleWord,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      isHolder,
      null,
      null,
      null,
      null
    ),
    keys: [
      transactionKey(creator, true),
      transactionKey(ENTROPY_PRICE, false),

      transactionKey(ticket_token_1, false, false),
      transactionKey(ticket_token_2, false, false),
      transactionKey(tokenMintSrcAccount_1, false),
      transactionKey(tokenMintDestAccount_1, false),
      transactionKey(tokenMintSrcAccount_2, false),
      transactionKey(tokenMintDestAccount_2, false),

      transactionKey(FEES_VAULT, false),
      transactionKey(feeDestAccount_1, false),
      transactionKey(feeDestAccount_2, false),

      transactionKey(SystemProgram.programId, false, false),
      transactionKey(SYSVAR_RENT_PUBKEY, false, false),

      transactionKey(TOKEN_PROGRAM_ID, false, false),
      transactionKey(ASSOCIATED_TOKEN_PROGRAM_ID, false, false),

      transactionKey(raffleAddress, false),
      transactionKey(data_account, false),
    ],
  });
}

export async function createRevealWLWinnersTransaction(
  connection: Connection,
  creator: PublicKey,
  raffleWord: String,
  ticket_token_1: PublicKey,
  ticket_token_2: PublicKey,
  winner_list: any,
  prize_token: PublicKey,
  data_account: PublicKey,
  isHolder: any
): Promise<Transaction> {
  let transaction = new Transaction();
  transaction.add(
    await createRevealWLWinnersInstruction(
      creator,
      raffleWord,
      ticket_token_1,
      ticket_token_2,
      winner_list,
      prize_token,
      data_account,
      isHolder
    )
  );
  return transaction;
}
export async function createRevealWLWinnersInstruction(
  creator: PublicKey,
  raffleWord: String,
  ticket_token_1: PublicKey,
  ticket_token_2: PublicKey,
  winner_list: any,
  prize_token: PublicKey,
  account_data: PublicKey,
  isHolder: any
): Promise<TransactionInstruction> {
  let raffleAddress = await getRaffleDataAddress(raffleWord);

  let feeDestAccount_1 = await getAssociatedTokenAddress(
    FEES_VAULT,
    ticket_token_1
  );
  let feeDestAccount_2 = await getAssociatedTokenAddress(
    FEES_VAULT,
    ticket_token_2
  );

  let tokenMintSrcAccount_1 = await getAssociatedTokenAddress(
    raffleAddress,
    ticket_token_1,
    true
  );
  let tokenMintDestAccount_1 = await getAssociatedTokenAddress(
    creator,
    ticket_token_1
  );
  let tokenMintSrcAccount_2 = await getAssociatedTokenAddress(
    raffleAddress,
    ticket_token_2,
    true
  );
  let tokenMintDestAccount_2 = await getAssociatedTokenAddress(
    creator,
    ticket_token_2
  );
  let prizeMintSrcAccount = await getAssociatedTokenAddress(
    raffleAddress,
    prize_token,
    true
  );
  let prizeMintDestAccount = await getAssociatedTokenAddress(
    creator,
    prize_token
  );
  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData(
      "RevealWLWinners",
      raffleWord,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      winner_list,
      null,
      null,
      isHolder,
      null,
      null,
      null,
      null
    ),
    keys: [
      transactionKey(creator, true),

      transactionKey(ticket_token_1, false, false),
      transactionKey(ticket_token_2, false, false),
      transactionKey(tokenMintSrcAccount_1, false),
      transactionKey(tokenMintDestAccount_1, false),
      transactionKey(tokenMintSrcAccount_2, false),
      transactionKey(tokenMintDestAccount_2, false),

      transactionKey(prize_token, false, false),
      transactionKey(prizeMintSrcAccount, false),
      transactionKey(prizeMintDestAccount, false),

      transactionKey(FEES_VAULT, false),
      transactionKey(feeDestAccount_1, false),
      transactionKey(feeDestAccount_2, false),

      transactionKey(SystemProgram.programId, false, false),
      transactionKey(SYSVAR_RENT_PUBKEY, false, false),

      transactionKey(TOKEN_PROGRAM_ID, false, false),
      transactionKey(ASSOCIATED_TOKEN_PROGRAM_ID, false, false),

      transactionKey(raffleAddress, false),
      transactionKey(account_data, false),
    ],
  });
}

export async function createBuyTicketsTransaction(
  connection: Connection,
  creator: PublicKey,
  raffleWord: String,
  ticket_token_1: PublicKey,
  ticket_token_2: PublicKey,
  ticket_amount: any,
  data_account: PublicKey,
  bought_tickets: any,
  isHolder: any
): Promise<Transaction> {
  // console.log(data_account.toBase58());
  let transaction = new Transaction();
  transaction.add(
    await createBuyTicketsInstruction(
      creator,
      raffleWord,
      ticket_token_1,
      ticket_token_2,
      ticket_amount,
      data_account,
      bought_tickets,
      isHolder
    )
  );
  return transaction;
}
export async function createBuyTicketsInstruction(
  creator: PublicKey,
  raffleWord: String,
  ticket_token_1: PublicKey,
  ticket_token_2: PublicKey,
  ticket_amount: any,
  data_account: PublicKey,
  bought_tickets: any,
  isHolder: any
): Promise<TransactionInstruction> {
  let raffleAddress = await getRaffleDataAddress(raffleWord);

  let tokenMintSrcAccount_1 = await getAssociatedTokenAddress(
    creator,
    ticket_token_1
  );
  let tokenMintDestAccount_1 = await getAssociatedTokenAddress(
    raffleAddress,
    ticket_token_1,
    true
  );
  let tokenMintSrcAccount_2 = await getAssociatedTokenAddress(
    creator,
    ticket_token_2
  );
  let tokenMintDestAccount_2 = await getAssociatedTokenAddress(
    raffleAddress,
    ticket_token_2,
    true
  );
  // console.log(bought_tickets);
  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData(
      "BuyTickets",
      raffleWord,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      ticket_amount,
      bought_tickets,
      isHolder,
      null,
      null,
      null,
      null
    ),
    keys: [
      transactionKey(creator, true),

      transactionKey(tokenMintSrcAccount_1, false),
      transactionKey(tokenMintDestAccount_1, false),
      transactionKey(tokenMintSrcAccount_2, false),
      transactionKey(tokenMintDestAccount_2, false),

      transactionKey(SystemProgram.programId, false, false),
      transactionKey(SYSVAR_RENT_PUBKEY, false, false),

      transactionKey(TOKEN_PROGRAM_ID, false, false),

      transactionKey(raffleAddress, false),
      transactionKey(data_account, false),
    ],
  });
}

export async function createClaimPrizeTransaction(
  connection: Connection,
  creator: PublicKey,
  raffleWord: String,
  prize_token: PublicKey,
  account_data: PublicKey
): Promise<Transaction> {
  let transaction = new Transaction();
  transaction.add(
    await createClaimPrizeInstruction(
      creator,
      raffleWord,
      prize_token,
      account_data
    )
  );
  return transaction;
}
export async function createClaimPrizeInstruction(
  creator: PublicKey,
  raffleWord: String,
  prize_token: PublicKey,
  account_data: PublicKey
): Promise<TransactionInstruction> {
  let raffleAddress = await getRaffleDataAddress(raffleWord);

  let prizeMintSrcAccount = await getAssociatedTokenAddress(
    raffleAddress,
    prize_token,
    true
  );
  let prizeMintDestAccount = await getAssociatedTokenAddress(
    creator,
    prize_token
  );
  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData(
      "ClaimPrize",
      raffleWord,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ),
    keys: [
      transactionKey(creator, true),

      transactionKey(prize_token, false, false),
      transactionKey(prizeMintSrcAccount, false),
      transactionKey(prizeMintDestAccount, false),

      transactionKey(SystemProgram.programId, false, false),
      transactionKey(SYSVAR_RENT_PUBKEY, false, false),

      transactionKey(TOKEN_PROGRAM_ID, false, false),
      transactionKey(ASSOCIATED_TOKEN_PROGRAM_ID, false, false),

      transactionKey(raffleAddress, false),
      transactionKey(account_data, false),
    ],
  });
}

export async function createClaimWLTransaction(
  connection: Connection,
  creator: PublicKey,
  raffleWord: String,
  account_data: PublicKey
): Promise<Transaction> {
  let transaction = new Transaction();
  transaction.add(
    await createClaimWLInstruction(creator, raffleWord, account_data)
  );
  return transaction;
}
export async function createClaimWLInstruction(
  creator: PublicKey,
  raffleWord: String,
  account_data: PublicKey
): Promise<TransactionInstruction> {
  let raffleAddress = await getRaffleDataAddress(raffleWord);

  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData(
      "ClaimWL",
      raffleWord,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ),
    keys: [
      transactionKey(creator, true),
      transactionKey(raffleAddress, false),
      transactionKey(account_data, false),
    ],
  });
}

export async function createPrizeTransferTransaction(
  connection: Connection,
  transferer: PublicKey,
  creator: PublicKey,
  raffleWord: String,
  prize_token: PublicKey,
  account_data: PublicKey
): Promise<Transaction> {
  let transaction = new Transaction();
  transaction.add(
    await createPrizeTransferInstruction(
      transferer,
      creator,
      raffleWord,
      prize_token,
      account_data
    )
  );
  return transaction;
}
export async function createPrizeTransferInstruction(
  transferer: PublicKey,
  creator: PublicKey,
  raffleWord: String,
  prize_token: PublicKey,
  account_data: PublicKey
): Promise<TransactionInstruction> {
  let raffleAddress = await getRaffleDataAddress(raffleWord);

  let prizeMintSrcAccount = await getAssociatedTokenAddress(
    raffleAddress,
    prize_token,
    true
  );
  let prizeMintDestAccount = await getAssociatedTokenAddress(
    creator,
    prize_token
  );
  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData(
      "PrizeTransfer",
      raffleWord,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ),
    keys: [
      transactionKey(transferer, true),
      transactionKey(creator, false),

      transactionKey(prize_token, false, false),
      transactionKey(prizeMintSrcAccount, false),
      transactionKey(prizeMintDestAccount, false),

      transactionKey(SystemProgram.programId, false, false),
      transactionKey(SYSVAR_RENT_PUBKEY, false, false),

      transactionKey(TOKEN_PROGRAM_ID, false, false),
      transactionKey(ASSOCIATED_TOKEN_PROGRAM_ID, false, false),

      transactionKey(raffleAddress, false),
      transactionKey(account_data, false),
    ],
  });
}

export async function createCancelRaffleTransaction(
  connection: Connection,
  creator: PublicKey,
  raffleWord: String,
  prize_token: PublicKey,
  account_data: PublicKey
): Promise<Transaction> {
  let transaction = new Transaction();
  transaction.add(
    await createCancelRaffleInstruction(
      creator,
      raffleWord,
      prize_token,
      account_data
    )
  );
  return transaction;
}
export async function createCancelRaffleInstruction(
  creator: PublicKey,
  raffleWord: String,
  prize_token: PublicKey,
  account_data: PublicKey
): Promise<TransactionInstruction> {
  let raffleAddress = await getRaffleDataAddress(raffleWord);

  let prizeMintSrcAccount = await getAssociatedTokenAddress(
    raffleAddress,
    prize_token,
    true
  );
  let prizeMintDestAccount = await getAssociatedTokenAddress(
    creator,
    prize_token
  );
  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData(
      "CancelRaffle",
      raffleWord,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ),
    keys: [
      transactionKey(creator, true),

      transactionKey(prize_token, false, false),
      transactionKey(prizeMintSrcAccount, false),
      transactionKey(prizeMintDestAccount, false),

      transactionKey(SystemProgram.programId, false, false),
      transactionKey(SYSVAR_RENT_PUBKEY, false, false),

      transactionKey(TOKEN_PROGRAM_ID, false, false),
      transactionKey(ASSOCIATED_TOKEN_PROGRAM_ID, false, false),

      transactionKey(raffleAddress, false),
      transactionKey(account_data, false),
      transactionKey(FEES_VAULT, false),
    ],
  });
}

export async function createCloseRaffleTransaction(
  connection: Connection,
  creator: PublicKey,
  raffleWord: String,
  prize_token: PublicKey,
  account_data: PublicKey
): Promise<Transaction> {
  let transaction = new Transaction();
  transaction.add(
    await createCloseRaffleInstruction(
      creator,
      raffleWord,
      prize_token,
      account_data
    )
  );
  return transaction;
}
export async function createCloseRaffleInstruction(
  creator: PublicKey,
  raffleWord: String,
  prize_token: PublicKey,
  account_data: PublicKey
): Promise<TransactionInstruction> {
  let raffleAddress = await getRaffleDataAddress(raffleWord);

  let prizeMintSrcAccount = await getAssociatedTokenAddress(
    raffleAddress,
    prize_token,
    true
  );
  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData(
      "CloseRaffle",
      raffleWord,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ),
    keys: [
      transactionKey(creator, true),
      transactionKey(prizeMintSrcAccount, false),

      transactionKey(SystemProgram.programId, false, false),
      transactionKey(SYSVAR_RENT_PUBKEY, false, false),

      transactionKey(TOKEN_PROGRAM_ID, false, false),
      transactionKey(ASSOCIATED_TOKEN_PROGRAM_ID, false, false),

      transactionKey(raffleAddress, false),
      transactionKey(account_data, false),
    ],
  });
}

export async function createAddToWLTransaction(
  connection: Connection,
  creator: PublicKey,
  wl_token: PublicKey
): Promise<Transaction> {
  let transaction = new Transaction();
  transaction.add(await createAddToWLInstruction(creator, wl_token));
  return transaction;
}
export async function createAddToWLInstruction(
  creator: PublicKey,
  wl_token: PublicKey
): Promise<TransactionInstruction> {
  let whitelistDataAddress = await getWhitelistDataAddress(wl_token);
  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData(
      "AddToWL",
      "",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ),
    keys: [
      transactionKey(creator, true),

      transactionKey(wl_token, false, false),
      transactionKey(whitelistDataAddress, false),

      transactionKey(SystemProgram.programId, false, false),
      transactionKey(SYSVAR_RENT_PUBKEY, false, false),
    ],
  });
}

export async function createClaimRefundTransaction(
  connection: Connection,
  creator: PublicKey,
  raffleWord: String,
  ticket_token_1: PublicKey,
  ticket_token_2: PublicKey,
  account_data: PublicKey
): Promise<Transaction> {
  let transaction = new Transaction();
  transaction.add(
    await createClaimRefundInstruction(
      creator,
      raffleWord,
      ticket_token_1,
      ticket_token_2,
      account_data
    )
  );
  return transaction;
}
export async function createClaimRefundInstruction(
  creator: PublicKey,
  raffleWord: String,
  ticket_token_1: PublicKey,
  ticket_token_2: PublicKey,
  account_data: PublicKey
): Promise<TransactionInstruction> {
  let raffleAddress = await getRaffleDataAddress(raffleWord);

  let ticketMintSrcAccount_1 = await getAssociatedTokenAddress(
    raffleAddress,
    ticket_token_1,
    true
  );
  let ticketMintDestAccount_1 = await getAssociatedTokenAddress(
    creator,
    ticket_token_1
  );
  let ticketMintSrcAccount_2 = await getAssociatedTokenAddress(
    raffleAddress,
    ticket_token_2,
    true
  );
  let ticketMintDestAccount_2 = await getAssociatedTokenAddress(
    creator,
    ticket_token_2
  );

  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData(
      "ClaimRefund",
      raffleWord,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ),
    keys: [
      transactionKey(creator, true),

      transactionKey(ticket_token_1, false, false),
      transactionKey(ticketMintSrcAccount_1, false),
      transactionKey(ticketMintDestAccount_1, false),
      transactionKey(ticket_token_2, false, false),
      transactionKey(ticketMintSrcAccount_2, false),
      transactionKey(ticketMintDestAccount_2, false),

      transactionKey(SystemProgram.programId, false, false),
      transactionKey(SYSVAR_RENT_PUBKEY, false, false),

      transactionKey(TOKEN_PROGRAM_ID, false, false),
      transactionKey(ASSOCIATED_TOKEN_PROGRAM_ID, false, false),

      transactionKey(raffleAddress, false),
      transactionKey(account_data, false),
    ],
  });
}

export async function getAllNFT(
  connection: Connection,
  wallet: PublicKey
): Promise<any> {
  let whitelist_array = [];
  //NFTs
  const walletNfts = await Metadata.findDataByOwner(connection, wallet);
  await Promise.all(
    walletNfts.map(async ({ mint, data }) => {
      // if (data.creators && data.creators[0]?.verified) {
      if (data.creators) {
        //NFTs
        // console.log(data)
        let addy = await getWhitelistDataAddress(
          new PublicKey(data.creators[0].address)
        );
        let addyAccountInfo = await connection.getAccountInfo(addy);
        if (addyAccountInfo) {
          // console.log(`${new PublicKey(mint)}`);

          let whitelisted_address = new PublicKey(mint);

          let tokenmetaPubkey = await Metadata.getPDA(new PublicKey(mint));
          // console.log(tokenmetaPubkey.toString());
          const tokenmeta = await Metadata.load(connection, tokenmetaPubkey);
          // console.log(tokenmeta)
          try {
            let img_response = await fetch(tokenmeta["data"]["data"]["uri"]);
            let img = await img_response.json();
          } catch (error) {
            console.log(error);
            return;
          }

          try {
            let img_response = await fetch(tokenmeta["data"]["data"]["uri"]);
            // console.log(img_response)
            let img = await img_response.json();
            let t = {
              address: whitelisted_address.toString(),
              meta_data: tokenmeta.data,
              image: img["image"],
              // name: tokenmeta["data"]["data"]["name"],
              name: img["name"],
              cmid: data.creators[0].address,
            };
            whitelist_array.push(t);
          } catch (error) {
            console.log(error);
            return;
          }

        }
      }
    })
  );

  return whitelist_array;
}
