import React, { useEffect, useState, useCallback } from "react";

import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {
  RaffleData,
  createBuyTicketsTransaction,
  createRevealWinnerTransaction,
  createRevealWLWinnersTransaction,
  createClaimPrizeTransaction,
  createClaimWLTransaction,
  createClaimRefundTransaction,
  createCancelRaffleTransaction,
  createCloseRaffleTransaction,
  createPrizeTransferTransaction,
} from "../util/raffle";
import { PublicKey, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID, AccountLayout } from "@solana/spl-token";
import moment from "moment";
import "./raffle-details.css";
import logo_white from "../images/logo-white.png";
import nft_img_1 from "../images/956.png";
import { useTimer } from "react-timer-hook";
import Swal from "sweetalert2";
import collection_img from "../images/Group 193.png";
import bacl_arrow_img from "../images/Arrow 3 (Stroke).png";
import flame_img from "../images/Flame.png";
import twitter_img from "../images/twitter.png";
import discord_img from "../images/discord.png";
import { PreLoader } from "../components/pre-loader";
import {
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import {
  Metadata,
  MetadataDataData,
} from "@metaplex-foundation/mpl-token-metadata";
import { Modal, Spinner } from "react-bootstrap";
import { stringify } from "flatted";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var flatten = require("flat");

function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => window.location.reload(),
  });

  return (
    <div>
      <div id="countdown" className="countdownHolder">
        <span className="countDays">
          <span className="position">
            <span className="digit static">{days}d</span>
          </span>
        </span>
        <span className="countDiv countDiv0"></span>
        <span className="countHours">
          <span className="position">
            {" "}
            <span className="digit static">{hours}h</span>{" "}
          </span>
        </span>
        <span className="countDiv countDiv1"></span>
        <span className="countMinutes">
          <span className="position">
            {" "}
            <span className="digit static">{minutes}m</span>{" "}
          </span>
        </span>
        <span className="countDiv countDiv1"></span>
        <span className="countSeconds">
          <span className="position">
            {" "}
            <span className="digit static">{seconds}s</span>{" "}
          </span>
        </span>
      </div>
    </div>
  );
}

export const RaffleDetail = () => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [raffle, setRaffle] = useState({});
  const [raffle1, setRaffle1] = useState({});
  const [noOfTickets, setNoOfTickets] = useState(1);
  const [currentTab, setcurrentTab] = useState("details");
  const [participants, setParticipants] = useState();
  const [showTransactionTable, setShowTransactionTable] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [TwiiterModal, setTwiiterModal] = useState(false);
  const [DiscordLink, setDiscordLink] = useState("");
  const [FloorPrice, setFloorPrice] = useState("");
  const [DiscordIds, setDiscordIds] = useState([]);
  const [individualTwitterName, setIndividualTwitterName] = useState("");
  const [show_close_btn, setshow_close_btn] = useState(false);
  const [showInlineLoader, setShowInlineLoader] = useState(false);
  const navigate = useNavigate();

  const [isPollTimeEnd, setisPollTimeEnd] = useState(false);
  const [isWinnerRevealed, setisWinnerRevealed] = useState(false);
  const ticket_list = JSON.parse(process.env.REACT_APP_TICKET_TOKEN);
  const [buyerGuideModal, setbuyerGuideModal] = useState(false);
  const admin_wallet = process.env.REACT_APP_ADMIN_ACCOUNT;

  const [detailPageActiveTab, setDetailPageActiveTab] = useState("detail");
  const [showBalance1, setShowBalance1] = useState("");
  const [showBalance2, setShowBalance2] = useState("");
  const [ticketBought, setTicketBought] = useState(0);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  const sendAndConfirmTransaction = useCallback(
    async (transaction, request_from = "", ticket_count = 0) => {
      try {
        let { blockhash } = await connection.getRecentBlockhash();
        transaction.feePayer = publicKey!;
        transaction.recentBlockhash = blockhash;

        let signature = await sendTransaction(transaction, connection,  {skipPreflight:false});
        let success_msg = "";
        if (request_from === "buy") {
          if (ticket_count === 1) {
            success_msg =
              "You have bought " + ticket_count + " ticket successfully.";
          } else {
            success_msg =
              "You have bought " + ticket_count + " tickets successfully.";
          }
        } else if (request_from === "reveal_winner") {
          success_msg = "Winner has been revealed.";
        } else if (request_from === "claim_prize") {
          success_msg = "Your prize has been successfully claimed.";
        } else if (request_from === "claim_refund") {
          success_msg = "You have claimd the refund successfully.";
        } else if (request_from === "force_cancel") {
          success_msg =
            "Your raffle has been force cancelled and NFT transferred to your wallet.";
        } else if (request_from === "cancel") {
          success_msg = "Your Raffle has been cancelled.";
        } else if (request_from === "close") {
          success_msg = "Your Raffle has been closed.";
        } else if (request_from === "prize_transfer") {
          success_msg = "Prize transferred to winner.";
        }

        await toast.promise(
          connection.confirmTransaction(signature, "finalized"),
          {
            pending: "Initiating the transaction",
            success: success_msg,
            error: "Error in transaction. Please try again later",
          }
        );
        setShowInlineLoader(false);
        // console.log(await connection.confirmTransaction(signature, "finalized"));

        return signature;
      } catch (error: any) {
        setIsLoading(false);
        setShowInlineLoader(false);
        if (error.message !== "User rejected the request.") {
          if (error.message.indexOf("custom program error: 0x1") > -1) {
            // Swal.fire({
            //   // title: "Error in transaction. Please try again later",
            //   text: "You don't have balance to complete this transaction",
            //   confirmButtonText: "Close",
            // });
            toast.warn("You don't have balance to complete this transaction", {
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
          } else if (error.message.indexOf("custom program error: 0x46") > -1) {
            // Swal.fire({
            //   // title: "Error in transaction. Please try again later",
            //   text: "Raffle is sold out",
            //   confirmButtonText: "Close",
            // });
            toast.warn("Raffle is sold out", {
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "light",
            });
          } else {
            toast.error(error.message, {
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "light",
            });

            // Swal.fire({
            //   // title: "Error in transaction. Please try again later",
            //   text: error.message,
            //   confirmButtonText: "Close",
            // });
            if (request_from !== "") {
              return "transaction_error";
            }
          }
        } else {
          toast.warn(error.message, {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      }
    },
    [connection, publicKey, sendTransaction]
  );

  const BuyTickets = useCallback(
    async (raffle, ticket_count) => {
      // console.log(ticket_count)
      if (ticket_count < 1) return;

      if (!publicKey) return;

      // if (ticket_count > raffle["max_tickets_per_wallet"]) {
      //   Swal.fire({
      //     text: "You have exceeded the maximum buying limit for this Raffle.",
      //     confirmButtonText: "Close",
      //   });
      //   return;
      // }

      // if (raffle["entries"].length > 0) {

      let count = raffle["entries"].filter(
        (x) => x === publicKey.toString()
      ).length;
      // console.log(count);

      let a_t = count + ticket_count;
      // if(count > ticket_count){
      //   a_t = count - ticket_count;
      // }else{
      //   a_t =  ticket_count - count
      // }
      if (a_t > raffle["max_tickets_per_wallet"]) {
        toast.warn(
          "You have exceeded the maximum buying limit for this Raffle",
          {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          }
        );

        return;
      }

      if (a_t > raffle["max_entries"]) {
        // setShowInlineLoader(prevState =>!prevState)

        toast.warn("Please enter less value", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });

        return;
      }
      // }

      // setIsLoading(true);
      setShowInlineLoader(true);
      let sig: any;
      sig = await sendAndConfirmTransaction(
        await createBuyTicketsTransaction(
          connection,
          publicKey,
          localStorage.getItem("raffleword"),
          new PublicKey(localStorage.getItem("ticketToken_1")),
          new PublicKey(localStorage.getItem("ticketToken_2")),
          Number(ticket_count),
          new PublicKey(localStorage.getItem("a_p")),
          count,
          0
        ),
        "buy",
        ticket_count
      );
      if (sig) {
        let access_res = await fetch(
          process.env.REACT_APP_API_URL + "purchaseTicket",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({
              raffleId: localStorage.getItem("raffleword"),
              walletAddress: publicKey.toString(),
              ticket_bought: a_t,
            }),
          }
        );
        let access_key = await access_res.json();

        setIsLoading(false);
        if (access_res.status === 200 || access_res.status === 201) {
          if (sig !== "transaction_error") {
            // Swal.fire({
            //   text: "Your ticket purchase is successful.",
            //   confirmButtonText: "Close",
            // });
          }
        }
        setNoOfTickets(1);
        get_raffle_details(localStorage.getItem("raffleid"));
      }
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  const get_raffle_details = async (raffle_id) => {
    setIsLoading(true);
    let r = await get_raffle(raffle_id);
    // console.log(r);
    if (r["close"] === 1) {
      Swal.fire({
        text: "This Raffle was closed by creator.",
        confirmButtonText: "View Active Raffles",
      }).then(() => {
        navigate("/");
      });
    } else {
      r["isH"] = Number(r["isH"]);
      r["id"] = raffle_id;

      const time = new Date(Number(r.raffleEndTime) * 1000);

      r["timer"] = time.getTime();

      let now = moment.utc();
      var day = moment.unix(Number(r.raffleEndTime));
      var daysDiff = day.diff(now, "days");
      var hoursDiff = day.diff(now, "hours");
      var minutesDiff = day.diff(now, "minutes");
      var secondsDiff = day.diff(now, "seconds");

      if (daysDiff > 0) {
        setisPollTimeEnd(false);
      } else {
        if (hoursDiff > 0 || minutesDiff > 0 || secondsDiff > 0) {
          setisPollTimeEnd(false);
        } else {
          setisPollTimeEnd(true);
        }
      }

      localStorage.setItem("ticketPrice", r["ticket_price"]);
      localStorage.setItem("ticket_price_1", r["ticket_price_1"]);
      localStorage.setItem("ticket_price_2", r["ticket_price_2"]);
      localStorage.setItem("raffleid", raffle_id);
      localStorage.setItem("raffleword", r["raffle_id"]);

      let discord_ids = [];
      if (r["claimedWinnersTwitterID"] !== null) {
        setDiscordIds(r["claimedWinnersTwitterID"]["ids"]);
        discord_ids = r["claimedWinnersTwitterID"]["ids"];
      } else {
        discord_ids = [];
      }

      await setRaffle(r);
      setIsLoading(false);

      let t = await RaffleData(connection, r["raffle_id"]);
      if (t !== undefined) {
        // t["collectionName"] = r["collectionName"];

        // t["daoName"] = r["daoName"];
        // t["id"] = raffle_id;
        // t["raffleName"] = r["raffleName"];
        // t["raffleType"] = r["raffleType"];
        // t["individualNameorAddress"] = r["individualNameorAddress"];
        // t["imageData"] = r["imageData"];
        // t["rafflePriceType"] = r["rafflePriceType"];
        // t["claimedWinners"] = r["claimedWinners"];
        // t["projectDiscordLink"] = r["projectDiscordLink"];
        // t["projectTwitterLink"] = r["projectTwitterLink"];
        // t["cancel_before_end"] = r["cancel_before_end"];
        // t["cancel"] = r["cancel"];
        // t["ticket_type"] = r["ticket_type"];
        // t["ticket_prize"] = r["ticket_prize"];
        // t["ticket_prize_type"] = r["ticket_prize_type"];
        // t["ticket_prize_1"] = r["ticket_prize_1"];
        // t["ticket_prize_type_1"] = r["ticket_prize_type_1"];
        // if (r["isH"] === "1") {
        //   t["isH"] = 1;
        // } else {
        //   t["isH"] = 0;
        // }
        // const time = new Date(t.end_timestamp * 1000);

        // t["timer"] = time.getTime();

        // let now = moment.utc();
        // var day = moment.unix(t.end_timestamp);
        // var daysDiff = day.diff(now, "days");
        // var hoursDiff = day.diff(now, "hours");
        // var minutesDiff = day.diff(now, "minutes");
        // var secondsDiff = day.diff(now, "seconds");

        // if (daysDiff > 0) {
        //   setisPollTimeEnd(false);
        // } else {
        //   if (hoursDiff > 0 || minutesDiff > 0 || secondsDiff > 0) {
        //     setisPollTimeEnd(false);
        //   } else {
        //     setisPollTimeEnd(true);
        //   }
        // }

        if (t["winners"].length > 0) {
          setisWinnerRevealed(true);
        } else {
          setisWinnerRevealed(false);
        }

        let participant = t["entries"].reduce(function (obj, b) {
          obj[b] = ++obj[b] || 1;
          return obj;
        }, {});
        setParticipants(participant);
        console.log(t);
        // console.log(participant);

        localStorage.setItem("ticketToken_1", t["ticket_mint_1"]);
        localStorage.setItem("ticketToken_2", t["ticket_mint_2"]);
        localStorage.setItem("prize_key", t["prize_key"]);
        localStorage.setItem("a_p", t["account_pubkey"]);

        // if (r["collectionName"] === "") {
        //   get_collection_name(r["id"], t["prize_key"]);
        // }

        // if (publicKey && r["creatorWalletAddress"] === publicKey.toString()) {

        // let discord_ids = await get_discord_id(r["raffle_id"]);
        let winner_with_discord = [];
        t["winners"].forEach((add) => {
          let r = discord_ids.find((element) => {
            return element.address === add;
          });
          if (r && r !== undefined) {
            winner_with_discord.push(r);
          } else {
            winner_with_discord.push({ address: add });
          }
        });
        // winner_with_discord.push({ address: "93MHJQtBv6uxwSgBLoBdLE2mM9EZeRxQv6kCpqYmeHSi", discord_id:"dd" });
        t["winners"] = winner_with_discord;
        // console.log(t['winners'],t)
        // }
        await is_show_close_button(t);

        setRaffle1(t);

        if (r["collectionName"] === "") {
          get_collection_name(r["id"], t["prize_key"]);
        }
        if (r["rafflePriceType"] === "nft") {
          if (r["collectionName"] !== "") {
            let c_r = await get_floor_price(r["collectionName"]);
            setFloorPrice(c_r);
          }
        }

        update_bought_ticket(raffle_id, t["total_entries"]);
        if (r["raffleType"] === "individual") {
          individual_details(r["individualNameorAddress"]);
        }
      } else {
        Swal.fire({
          text: "Something went to wrong.Please Contact Admin",
          confirmButtonText: "Close",
        });
      }
    }

    // get_transaction(t["account_pubkey"]);
  };

  let individual_details = async (individual_id) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getIndividualDetails/" + individual_id,
        { mode: "cors" }
      );
      if (response.status === 200) {
        const data = await response.json();
        setIndividualTwitterName(data["twiiterId"]);
      }
    } catch (e) {}
  };

  let get_bought_ticket_user = async () => {
    if (participants) {
      if (publicKey && publicKey.toString() in participants) {
        // console.log(participants[publicKey.toString()]);
        setTicketBought(participants[publicKey.toString()]);
      }
    }
  };

  const get_collection_name = async (id, nft) => {
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + "getCollectionName",
        {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({
            nft: nft,
            id: id,
          }),
        }
      );
      if (response.status === 200) {
        refreshData(id);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const get_transaction = async (public_key) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getTransactionsData/" + public_key,
        { mode: "cors" }
      );
      const data = await response.json();
    } catch (e) {
      console.log(e);
    }
  };

  const get_discord_id = async (raffle_id) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getClaimedDiscordId/" + raffle_id,
        { mode: "cors" }
      );
      const data = await response.json();
      if (data !== null) {
        setDiscordIds(data["ids"]);
        return data["ids"];
      } else {
        return [];
      }
    } catch (e) {
      console.log(e);
    }
  };

  const get_floor_price = async (c_name) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getFloorPrice/" + c_name,
        { mode: "cors" }
      );
      const data = await response.text();
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const get_raffle = async (id) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getRaffle/" + id,
        { mode: "cors" }
      );
      const data = await response.json();
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const update_bought_ticket = async (id, t_b) => {
    try {
      let response = await fetch(
        process.env.REACT_APP_API_URL + "updateBoughtTicket",
        {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({
            id: id,
            ticket_bought: t_b,
          }),
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    let url = window.location.href.split("/");
    if (url.length > 2) {
      if (url[3] === "raffle") {
        let raffle_id = url[4];
        get_raffle_details(raffle_id);
      }
    }
    // get_dao_by_id();
  }, [location]);

  useEffect(() => {
    const intervalCall = setInterval(() => {
      let url = window.location.href.split("/");
      if (url.length > 2) {
        if (url[3] === "raffle") {
          let raffle_id = url[4];
          refreshData(raffle_id);
        }
      }
    }, 100000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, []);

  useEffect(() => {
    getBalance();
    get_bought_ticket_user();
  }, [publicKey, raffle1]);

  const getBalance = async () => {
    if (!publicKey) return;
    let balance = await connection.getBalance(publicKey);
    // console.log(`${balance / LAMPORTS_PER_SOL} SOL`);

    const accounts = await connection.getParsedProgramAccounts(
      TOKEN_PROGRAM_ID, // new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA")
      {
        filters: [
          {
            dataSize: 165, // number of bytes
          },
          {
            memcmp: {
              offset: 32, // number of bytes
              bytes: publicKey.toString(), // base58 encoded string
            },
          },
        ],
      }
    );

    // console.log(
    //   `Found ${
    //     accounts.length
    //   } token account(s) for wallet ${publicKey.toString()}: `
    // );

    let ticket_token_mint_1 = "";
    let ticket_token_mint_2 = "";
    if (raffle) {
      if (raffle["ticket_type"] === "single") {
        if (raffle["ticket_prize_type"] === "SOL") {
          setShowBalance1(
            (balance / LAMPORTS_PER_SOL).toFixed(4).toString() + " SOL"
          );
          setShowBalance2("");
        } else {
          ticket_token_mint_1 = raffle1["ticket_mint_1"];
        }
      } else {
        if (raffle["ticket_prize_type"] === "SOL") {
          setShowBalance1(
            (balance / LAMPORTS_PER_SOL).toFixed(4).toString() + " SOL"
          );
          ticket_token_mint_1 = raffle1["ticket_mint_1"];
          ticket_token_mint_2 = "";
          setShowBalance2("");
        } else {
          ticket_token_mint_1 = raffle1["ticket_mint_1"];
          ticket_token_mint_2 = raffle1["ticket_mint_2"];
        }
      }
    }
    // console.log(accounts)

    accounts.forEach((account, i) => {
      if (ticket_token_mint_1 !== "") {
        if (
          ticket_token_mint_1 === account.account.data["parsed"]["info"]["mint"]
        ) {
          if (raffle["ticket_prize_type"] === "SOL") {
            setShowBalance2(
              account.account.data["parsed"]["info"]["tokenAmount"][
                "uiAmount"
              ].toString() +
                " " +
                raffle["ticket_prize_type_1"]
            );
          } else {
            setShowBalance1(
              account.account.data["parsed"]["info"]["tokenAmount"][
                "uiAmount"
              ].toString() +
                " " +
                raffle["ticket_prize_type"]
            );
          }
        }
      }
      if (ticket_token_mint_2 !== "") {
        if (
          ticket_token_mint_2 === account.account.data["parsed"]["info"]["mint"]
        ) {
          setShowBalance2(
            account.account.data["parsed"]["info"]["tokenAmount"][
              "uiAmount"
            ].toString() +
              " " +
              raffle["ticket_prize_type_2"]
          );
        }
      }
      // console.log(
      //   `-- Token Account Address ${i + 1}: ${account.pubkey.toString()} --`
      // );
      // console.log(`Mint: ${account.account.data["parsed"]["info"]["mint"]}`);
      // console.log(
      //   `Amount: ${account.account.data["parsed"]["info"]["tokenAmount"]["uiAmount"]}`
      // );
    });
  };
  const refreshData = async (raffle_id) => {
    setIsRefresh(true);
    let r = await get_raffle(raffle_id);

    if (r["rafflePriceType"] === "nft") {
      if (r["collectionName"] !== "") {
        let c_r = await get_floor_price(r["collectionName"]);
        r["floorPrice"] = c_r;
      }
    }

    r["isH"] = Number(r["isH"]);
    r["id"] = raffle_id;

    const time = new Date(Number(r.raffleEndTime) * 1000);

    r["timer"] = time.getTime();

    let now = moment.utc();
    var day = moment.unix(Number(r.raffleEndTime));
    var daysDiff = day.diff(now, "days");
    var hoursDiff = day.diff(now, "hours");
    var minutesDiff = day.diff(now, "minutes");
    var secondsDiff = day.diff(now, "seconds");

    if (daysDiff > 0) {
      setisPollTimeEnd(false);
    } else {
      if (hoursDiff > 0 || minutesDiff > 0 || secondsDiff > 0) {
        setisPollTimeEnd(false);
      } else {
        setisPollTimeEnd(true);
      }
    }

    localStorage.setItem("ticketPrice", r["ticket_price"]);
    localStorage.setItem("ticket_price_1", r["ticket_price_1"]);
    localStorage.setItem("ticket_price_2", r["ticket_price_2"]);
    localStorage.setItem("raffleid", raffle_id);
    localStorage.setItem("raffleword", r["raffle_id"]);

    let discord_ids = [];
    if (r["claimedWinnersTwitterID"] !== null) {
      setDiscordIds(r["claimedWinnersTwitterID"]["ids"]);
      discord_ids = r["claimedWinnersTwitterID"]["ids"];
    } else {
      discord_ids = [];
    }

    setRaffle(r);

    let t = await RaffleData(connection, r["raffle_id"]);

    if (t["winners"].length > 0) {
      setisWinnerRevealed(true);
    } else {
      setisWinnerRevealed(false);
    }

    let participant = t["entries"].reduce(function (obj, b) {
      obj[b] = ++obj[b] || 1;
      return obj;
    }, {});
    setParticipants(participant);

    // console.log(participant);

    localStorage.setItem("ticketToken_1", t["ticket_mint_1"]);
    localStorage.setItem("ticketToken_2", t["ticket_mint_2"]);
    localStorage.setItem("prize_key", t["prize_key"]);
    localStorage.setItem("a_p", t["account_pubkey"]);

    // let discord_ids = r['claimedWinnersTwitterID'] await get_discord_id(r["raffle_id"]);
    let winner_with_discord = [];
    t["winners"].forEach((add) => {
      let r = discord_ids.find((element) => {
        return element.address === add;
      });
      if (r && r !== undefined) {
        winner_with_discord.push(r);
      } else {
        winner_with_discord.push({ address: add });
      }
    });
    t["winners"] = winner_with_discord;
    // console.log(t["winners"]);
    // t["show_close_btn"] = await is_show_close_button(t);

    setRaffle1(t);
    await is_show_close_button(t);

    setIsRefresh(false);

    if (r["raffleType"] === "individual") {
      individual_details(r["individualNameorAddress"]);
    }
    // get_transaction(t["account_pubkey"]);
  };

  const back_to_raffle_list = async () => {
    navigate("/");
  };

  const change_navigation_tab = async (participants) => {
    setcurrentTab(participants);
    return;
  };

  const find_winner = async (isH) => {
    if (raffle["rafflePriceType"] === "nft") {
      // setIsLoading(true);
      RevealWinner(isH);
    } else {
      // setIsLoading(true);
      try {
        let access_res = await fetch(
          process.env.REACT_APP_API_URL + "fetchDataV1",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({
              winnerCount: raffle1["winner_count"],
              noOfParticipants: raffle1["total_entries"],
              dataList: raffle1["entries"],
            }),
          }
        );
        let winners = await access_res.json();

        RevealWLWinners(winners, isH);
      } catch (e) {
        console.log(e);
        Swal.fire({
          text: "Something went to wrong.Please try again later",
          confirmButtonText: "Close",
        });
        setIsLoading(false);
      }
    }
  };

  const randomInteger = async (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const RevealWinner = useCallback(
    async (isH) => {
      if (!publicKey) return;
      let sig;
      sig = await sendAndConfirmTransaction(
        await createRevealWinnerTransaction(
          connection,
          publicKey,
          localStorage.getItem("raffleword"),
          new PublicKey(localStorage.getItem("ticketToken_1")),
          new PublicKey(localStorage.getItem("ticketToken_2")),
          new PublicKey(localStorage.getItem("a_p")),
          isH
        ),
        "reveal_winner"
      );
      if (sig) {
        let t = await RaffleData(
          connection,
          localStorage.getItem("raffleword")
        );
        let access_res = await fetch(
          process.env.REACT_APP_API_URL + "revealWinner",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({
              raffleId: localStorage.getItem("raffleword"),
              winner: t["winners"][0],
            }),
          }
        );

        setIsLoading(false);

        get_raffle_details(localStorage.getItem("raffleid"));

        if (access_res.status === 200) {
          // Swal.fire({
          //   text: "Winner has been revealed.",
          //   confirmButtonText: "Close",
          // });
        }
      }
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  const RevealWLWinners = useCallback(
    async (winner_list, isH) => {
      if (!publicKey) return;
      let sig;
      sig = await sendAndConfirmTransaction(
        await createRevealWLWinnersTransaction(
          connection,
          publicKey,
          localStorage.getItem("raffleword"),
          new PublicKey(localStorage.getItem("ticketToken_1")),
          new PublicKey(localStorage.getItem("ticketToken_2")),
          winner_list,
          new PublicKey(localStorage.getItem("prize_key")),
          new PublicKey(localStorage.getItem("a_p")),
          isH
        ),
        "reveal_winner"
      );
      if (sig) {
        // Swal.fire({
        //   text: "Winner has been revealed.",
        //   confirmButtonText: "Close",
        // });
        get_raffle_details(localStorage.getItem("raffleid"));
      }
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  const claim_winner = async () => {
    if (raffle["rafflePriceType"] === "nft") {
      ClaimPrize();
    } else {
      if (raffle1["hasEscrow"] === "\u0001") {
        ClaimPrize();
      } else {
        setDiscordLink("");
        setTwiiterModal(true);
      }
    }
  };

  const tab_toggler = async () => {
    setShowTransactionTable(!showTransactionTable);
  };

  const ClaimPrize = useCallback(async () => {
    if (!publicKey) return;
    // setIsLoading(true);
    let sig: any;
    sig = await sendAndConfirmTransaction(
      await createClaimPrizeTransaction(
        connection,
        publicKey,
        localStorage.getItem("raffleword"),
        new PublicKey(localStorage.getItem("prize_key")),
        new PublicKey(localStorage.getItem("a_p"))
      ),
      "claim_prize"
    );
    if (sig) {
      // let access_res = await fetch(
      //   process.env.REACT_APP_API_URL + "updateCliamData",
      //   {
      //     method: "POST",
      //     mode: "cors",
      //     headers: new Headers({ "content-type": "application/json" }),
      //     body: JSON.stringify({
      //       raffleId: localStorage.getItem("raffleword"),
      //       walletAddress: publicKey.toString(),
      //     }),
      //   }
      // );
      // let access_key = await access_res.text();

      get_raffle_details(localStorage.getItem("raffleid"));
      // Swal.fire({
      //   text: "Your prize has been successfully claimed.",
      //   confirmButtonText: "Close",
      // });
    }
  }, [connection, publicKey, sendAndConfirmTransaction]);

  const ClaimWL = useCallback(async () => {
    if (!publicKey) return;
    // setIsLoading(true);
    let sig: any;
    sig = await sendAndConfirmTransaction(
      await createClaimWLTransaction(
        connection,
        publicKey,
        localStorage.getItem("raffleword"),
        new PublicKey(localStorage.getItem("a_p"))
      ),
      "claim_prize"
    );
    if (sig) {
      // let access_res = await fetch(
      //   process.env.REACT_APP_API_URL + "updateCliamData",
      //   {
      //     method: "POST",
      //     mode: "cors",
      //     headers: new Headers({ "content-type": "application/json" }),
      //     body: JSON.stringify({
      //       raffleId: localStorage.getItem("raffleword"),
      //       walletAddress: publicKey.toString(),
      //     }),
      //   }
      // );
      // let access_key = await access_res.text();
      get_raffle_details(localStorage.getItem("raffleid"));
      // Swal.fire({
      //   text: "Your prize has been successfully claimed.",
      //   confirmButtonText: "Close",
      // });
    }
  }, [connection, publicKey, sendAndConfirmTransaction]);

  const ClaimRefund = useCallback(async () => {
    if (!publicKey) return;
    // setIsLoading(true);
    let sig: any;
    sig = await sendAndConfirmTransaction(
      await createClaimRefundTransaction(
        connection,
        publicKey,
        localStorage.getItem("raffleword"),
        new PublicKey(localStorage.getItem("ticketToken_1")),
        new PublicKey(localStorage.getItem("ticketToken_2")),
        new PublicKey(localStorage.getItem("a_p"))
      ),
      "claim_refund"
    );

    if (sig) {
      let access_res = await fetch(
        process.env.REACT_APP_API_URL + "claimTicket",
        {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({
            raffleId: localStorage.getItem("raffleword"),
            walletAddress: publicKey.toString(),
          }),
        }
      );
      if (access_res.status === 200) {
        get_raffle_details(localStorage.getItem("raffleid"));
        // Swal.fire({
        //   text: "You have claimd the refund successfully",
        //   confirmButtonText: "Close",
        // });
      }
    }
  }, [connection, publicKey, sendAndConfirmTransaction]);

  const CancelBeforeRaffleEnd = useCallback(async () => {
    if (!publicKey) return;
    // setIsLoading(true);
    let sig: any;
    sig = await sendAndConfirmTransaction(
      await createCancelRaffleTransaction(
        connection,
        publicKey,
        localStorage.getItem("raffleword"),
        new PublicKey(localStorage.getItem("prize_key")),
        new PublicKey(localStorage.getItem("a_p"))
      ),
      "force_cancel"
    );

    if (sig) {
      let access_res = await fetch(
        process.env.REACT_APP_API_URL + "cancelBeforeRaffle",
        {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({
            raffleId: localStorage.getItem("raffleword"),
            transaction_hash: sig,
          }),
        }
      );
      if (access_res.status === 200) {
        get_raffle_details(localStorage.getItem("raffleid"));
        // Swal.fire({
        //   text: "Your raffle has been force cancelled and NFT transferred to your wallet ",
        //   confirmButtonText: "Close",
        // });
      }
      let access_key = await access_res.text();
    }
  }, [connection, publicKey, sendAndConfirmTransaction]);

  const CancelRaffle = useCallback(async () => {
    if (!publicKey) return;
    // setIsLoading(true);
    let sig: any;
    sig = await sendAndConfirmTransaction(
      await createCancelRaffleTransaction(
        connection,
        publicKey,
        localStorage.getItem("raffleword"),
        new PublicKey(localStorage.getItem("prize_key")),
        new PublicKey(localStorage.getItem("a_p"))
      ),
      "cancel"
    );

    if (sig) {
      console.log(sig);
      let access_res = await fetch(
        process.env.REACT_APP_API_URL + "closeRaffle",
        {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({
            raffleId: localStorage.getItem("raffleword"),
            transaction_hash: sig,
          }),
        }
      );
      if (access_res.status === 200) {
        // Swal.fire({
        //   text: "Your Raffle has been cancelled.",
        //   confirmButtonText: "Close",
        // });
        navigate("/");
      }
    }
  }, [connection, publicKey, sendAndConfirmTransaction]);

  let is_show_close_button = async (r_data) => {
    // console.log(r_data);
    let show = false;
    if (publicKey) {
      if (publicKey.toString() === r_data["creator"]) {
        // console.log(r_data["rafflePriceType"])
        if (r_data["rafflePriceType"] === "nft") {
          if (r_data["winners"].length > 0) {
            show = true;
            r_data["winners"].forEach((element) => {
              if (r_data["pending"].includes(element.address)) {
                setshow_close_btn(false);
                show = false;
                // console.log(show);
                return show;
              } else {
                setshow_close_btn(true);
                console.log("sss");
                show = true;
              }
            });
            return show;
          } else {
            return show;
          }
        } else {
          // console.log("dddd");
          if (r_data["winners"].length > 0) {
            show = true;
            // console.log(show);
            return show;
          } else {
            return show;
          }
        }
      } else {
        return show;
      }
    } else {
      return show;
    }
    // return show;
  };



  const CloseRaffle = useCallback(async () => {
    if (!publicKey) return;
    // setIsLoading(true);
    let sig: any;
    sig = await sendAndConfirmTransaction(
      await createCloseRaffleTransaction(
        connection,
        publicKey,
        localStorage.getItem("raffleword"),
        new PublicKey(localStorage.getItem("prize_key")),
        new PublicKey(localStorage.getItem("a_p"))
      ),
      "close"
    );

    if (sig) {
      let access_res = await fetch(
        process.env.REACT_APP_API_URL + "closeRaffle",
        {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({
            raffleId: localStorage.getItem("raffleword"),
            transaction_hash: sig,
          }),
        }
      );
      if (access_res.status === 200) {
        // Swal.fire({
        //   text: "Your Raffle has closed.",
        //   confirmButtonText: "Close",
        // });
        navigate("/");
      }
      let access_key = await access_res.text();
    }
  }, [connection, publicKey, sendAndConfirmTransaction]);

  let find_ticket_token = (ticket_price, address) => {
    if (address !== undefined && address !== null) {
      let tokenType = ticket_list.find((o) => o.Address === address);

      let p = ticket_price / Math.pow(10, tokenType["Decimal"]);
      return p + " " + tokenType["Token_name"];
    }
  };

  const handleCloseTwitterModal = () => {
    setTwiiterModal(false);
    setDiscordLink("");
  };

  const submitDiscordAndClaim = async () => {
    if (DiscordLink !== "") {
      try {
        let access_res = await fetch(
          process.env.REACT_APP_API_URL + "addDiscordIdOnClaim",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({
              address: publicKey.toString(),
              raffle_id: localStorage.getItem("raffleword"),
              discord_id: DiscordLink,
            }),
          }
        );
        if (access_res.status === 200) {
          setTwiiterModal(false);
          ClaimWL();
        } else {
          Swal.fire({
            text: "Something went to wrong.Please try again later",
            confirmButtonText: "Close",
          });
        }
      } catch (e) {
        console.log(e);
        Swal.fire({
          text: "Something went to wrong.Please try again later",
          confirmButtonText: "Close",
        });
      }
    } else {
      Swal.fire({
        text: "Please enter your discord id",
        confirmButtonText: "Close",
      });
    }
  };

  const ClaimByAdminOrCreator = (winner_key) => {
    PrizeTransfer(winner_key);
  };

  const PrizeTransfer = useCallback(
    async (winner_key) => {
      if (!publicKey) return;
      // setIsLoading(true);
      let sig = await sendAndConfirmTransaction(
        await createPrizeTransferTransaction(
          connection,
          publicKey,
          new PublicKey(winner_key),
          localStorage.getItem("raffleword"),
          new PublicKey(localStorage.getItem("prize_key")),
          new PublicKey(localStorage.getItem("a_p"))
        ),
        "prize_transfer"
      );

      if (sig) {
        get_raffle_details(localStorage.getItem("raffleid"));
        setIsLoading(false);
        // Swal.fire({
        //   text: "Prize transferred to winner",
        //   confirmButtonText: "Close",
        // });
      }
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  const handleCloseBuyerGuideModal = () => {
    setbuyerGuideModal(false);
  };

  let detail_page_tab_change = async (e) => {
    setDetailPageActiveTab(e);
  };

  let openSolscan = async (e) => {
    window.open("https://solscan.io/account/" + e, "_blank");
  };
  return (
    <>
      <ToastContainer />
      <Modal
        show={buyerGuideModal}
        onHide={handleCloseBuyerGuideModal}
        backdrop="static"
        className="token-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Buyer’s Guide for Raffle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="msg-note">Terms & Conditions</div>
          <div className="guide-container">
            <ol>
              <li>
                All NFT prizes are held by raffle in escrow and can be claimed
                by the winner or can be transferred by creator once the draw is
                done.
              </li>
              <li>
                {" "}
                Once the draw is done, Creator can reveal the winner or winner
                will be revealed automatically if creator failed to reveal the
                winner within 24 hours.{" "}
              </li>
              <li>
                If Raffle does not reach the minimum threshold value (60% of
                ticket supply with max cap of 900 tickets ) then raffle creator
                will have an option to force cancel the raffle and you can
                refund your purchased tickets.
              </li>
              <li>
                Raffle tickets cannot be refunded once bought unless the Raffle
                is forced cancellation by the Raffle Creator due to minimum
                threshold value not met.
              </li>
              <li>
                New Raffles will be automatically posted at{" "}
                <a
                  href="https://twitter.com/Raffle_X_live"
                  className="btn twiiter-connect-btn me-2"
                  target="_blank"
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    className="inline-block w-6 ml-2 text-white-40 fill-current"
                  >
                    <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                  </svg>
                  Raffle_X_live
                </a>
                Follow this twitter for more updates.
              </li>
            </ol>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={TwiiterModal}
        onHide={handleCloseTwitterModal}
        backdrop="static"
        className="twitter-link-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter your discord id to claim</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row fv-row mb-7 fv-plugins-icon-container">
              <div className="col-xl-12">
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  value={DiscordLink}
                  onChange={(e) => setDiscordLink(e.target.value)}
                  placeholder=""
                />
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <div className="text-center mt-9">
            <button
              type="button"
              className="btn modal-btn-cancel me-3"
              onClick={handleCloseTwitterModal}
            >
              Cancel
            </button>
            <button
              onClick={submitDiscordAndClaim}
              type="button"
              className="btn modal-btn-submit"
              data-kt-modal-action-type="submit"
            >
              <span className="indicator-label">Submit and claim</span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <div className="desk-top-view">
        {/* {isLoading && <PreLoader />} */}
        {isLoading && (
          <div className="loading">
            <span className="loading-dots">•</span>
            <p className="loading-text-1">
              Loading from onchain, N/W Congestion might affect the page loading
            </p>
          </div>
        )}
        {!isLoading && (
          <>
            <div className="search-container-raffle-detail pb-5 card rounded-0 bgi-no-repeat bgi-position-x-end bgi-size-cover mb-9">
              <div className="container-xxl">
                <div className="row gy-0 gx-10">
                  <div className="col-xl-3 col-md-12">
                    <div
                      className="d-flex pointer"
                      onClick={() => back_to_raffle_list()}
                    >
                      <div className="py-2">
                        <div className="back-arrow">
                          <img src={bacl_arrow_img} />
                        </div>
                      </div>

                      <div className="min-w-100px w-100 py-2 px-4 mb-3">
                        <span className="raffle-detail-label">Back to</span>

                        <div className="raffle-detail-text text-v1">
                          All Raffle
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-9 col-md-12"></div>
                </div>
                <div className="row gy-0 gx-10">
                  <div className="col-xl-3 col-md-12 raffle-detail-left">
                    {raffle["imageData"] !== null ? (
                      <img
                        alt="raffle_imagedd"
                        src={raffle["imageData"]}
                        className="raffle-image"
                      />
                    ) : (
                      <img
                        alt="raffle_image"
                        src={nft_img_1}
                        className="raffle-image"
                      />
                    )}
                    {!isPollTimeEnd &&
                      raffle1 &&
                      raffle["cancel_before_end"] !== undefined &&
                      raffle["cancel_before_end"] === 0 &&
                      publicKey &&
                      publicKey.toString() !== raffle1["creator"] && (
                        <div className="row mb-2 mt-5">
                          {raffle1["total_entries"] !==
                            raffle1["max_entries"] && (
                            <>
                              <div
                                className={`${isMobile ? "col-3" : "col-4"}`}
                              >
                                <input
                                  type="number"
                                  className="form-control ticket-price-box form-control-solid text-center"
                                  onChange={(e) =>
                                    setNoOfTickets(Number(e.target.value))
                                  }
                                  placeholder="Qty"
                                  value={noOfTickets}
                                  disabled={showInlineLoader}
                                />
                              </div>
                              <div className="col">
                                <button
                                  className="btn btn-raffle-detail"
                                  onClick={() => {
                                    BuyTickets(raffle1, noOfTickets);
                                  }}
                                  disabled={showInlineLoader}
                                >
                                  {showInlineLoader ? (
                                    <>
                                      <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                      />{" "}
                                      Buying
                                    </>
                                  ) : (
                                    "Buy Raffle Ticket"
                                  )}
                                </button>
                              </div>
                            </>
                          )}
                          {"total_entries" in raffle1 &&
                            raffle1["total_entries"] ===
                              raffle1["max_entries"] && (
                              <div className="col text-center">
                                <button className="btn-ticket-sold-out">
                                  Ticket Sold Out
                                </button>
                              </div>
                            )}
                        </div>
                      )}

                    {raffle1["entries"]?.length > 0 &&
                      !isWinnerRevealed &&
                      (isPollTimeEnd ||
                        raffle1["total_entries"] === raffle1["max_entries"]) &&
                      publicKey &&
                      raffle1["is_cancelled"] === 0 &&
                      (publicKey.toString() === raffle1["creator"] ||
                        publicKey.toString() === admin_wallet) && (
                        <div className="row mb-2">
                          <div className="col">
                            <button
                              className="btn btn-raffle-detail"
                              onClick={() => {
                                find_winner(raffle["isH"]);
                                setShowInlineLoader(true);
                              }}
                              disabled={showInlineLoader}
                            >
                              {showInlineLoader ? (
                                <>
                                  <Spinner
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                  />{" "}
                                  Revealing
                                </>
                              ) : (
                                "Reveal Winner"
                              )}
                            </button>
                            {raffle1["total_entries"] <
                              raffle1["threshold_ticket"] &&
                              raffle["cancel_before_end"] === 0 &&
                              publicKey.toString() === raffle1["creator"] && (
                                <button
                                  className="me-1 btn btn-raffle-detail btn-raffle-force-close"
                                  onClick={() => {
                                    CancelBeforeRaffleEnd();
                                    setShowInlineLoader(true);
                                  }}
                                  disabled={showInlineLoader}
                                >
                                  {showInlineLoader ? (
                                    <>
                                      <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                      />{" "}
                                      Force Canceling
                                    </>
                                  ) : (
                                    " Force Cancel"
                                  )}
                                </button>
                              )}
                          </div>
                        </div>
                      )}
                    {publicKey &&
                      publicKey.toString() === raffle1["creator"] &&
                      raffle["cancel"] === 1 && (
                        <div className="row mb-2">
                          <div className="col">
                            <button
                              className="btn btn-raffle-detail"
                              onClick={() => {
                                CloseRaffle();
                                setShowInlineLoader(true);
                              }}
                              disabled={showInlineLoader}
                            >
                              {showInlineLoader ? (
                                <>
                                  <Spinner
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                  />{" "}
                                  Closing
                                </>
                              ) : (
                                " Close Raffle"
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                    {publicKey &&
                      publicKey.toString() === raffle1["creator"] &&
                      raffle["cancel_before_end"] === 1 &&
                      raffle1["is_cancelled"] ===
                        raffle1["total_entries"] + 1 && (
                        <div className="row mb-2">
                          <div className="col">
                            <button
                              className="btn btn-raffle-detail"
                              onClick={() => {
                                CloseRaffle();
                                setShowInlineLoader(true);
                              }}
                              disabled={showInlineLoader}
                            >
                              {showInlineLoader ? (
                                <>
                                  <Spinner
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                  />{" "}
                                  Closing
                                </>
                              ) : (
                                " Close Raffle"
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                    {publicKey &&
                      publicKey.toString() === raffle1["creator"] &&
                      isWinnerRevealed &&
                      raffle1["winners"] !== undefined &&
                      raffle1['pending'].length === 1 &&
                      raffle1['pending'][0] === "11111111111111111111111111111111" &&
                    (
                        <div className="row mb-2">
                          <div className="col">
                            <button
                              className="btn btn-raffle-detail"
                              onClick={() => {
                                CloseRaffle();
                                setShowInlineLoader(true);
                              }}
                              disabled={showInlineLoader}
                            >
                              {showInlineLoader ? (
                                <>
                                  <Spinner
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                  />{" "}
                                  Closing
                                </>
                              ) : (
                                " Close Raffle"
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                    {publicKey &&
                      publicKey.toString() === raffle1["creator"] &&
                      raffle1["total_entries"] === 0 &&
                      raffle1["active"] !== "\u0000" && (
                        <div className="row mb-2">
                          <div className="col">
                            <button
                              className="btn btn-raffle-detail"
                              onClick={() => {
                                CancelRaffle();
                                setShowInlineLoader(true);
                              }}
                              disabled={showInlineLoader}
                            >
                              {showInlineLoader ? (
                                <>
                                  <Spinner
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                  />{" "}
                                  Canceling
                                </>
                              ) : (
                                "Cancel Raffle"
                              )}
                            </button>
                          </div>
                        </div>
                      )}

                    {publicKey &&
                      publicKey.toString() !== raffle1["creator"] &&
                      raffle1["entries"] !== undefined &&
                      raffle["cancel_before_end"] !== undefined &&
                      raffle["cancel_before_end"] === 1 && (
                        <div className="row mb-2 mt-2">
                          <div className="col text-center">
                            {raffle1["entries"].includes(
                              publicKey.toString()
                            ) ? (
                              <>
                                <div className="text-yellow fs-4 mb-2">
                                  The Raffler has cancelled the raffle. so
                                  please claim your ticket refund.{" "}
                                </div>
                                <button
                                  className="btn btn-raffle-detail"
                                  onClick={() => {
                                    ClaimRefund();
                                    setShowInlineLoader(true);
                                  }}
                                  disabled={showInlineLoader}
                                >
                                  {showInlineLoader ? (
                                    <>
                                      <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                      />{" "}
                                      Claiming
                                    </>
                                  ) : (
                                    "Claim Refund"
                                  )}
                                </button>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      )}
                    {publicKey &&
                      publicKey.toString() !== raffle1["creator"] &&
                      "total_entries" in raffle1 &&
                      raffle1["total_entries"] !== raffle1["max_entries"] &&
                      !isPollTimeEnd &&
                      showBalance1 !== "" && (
                        <div className="balance-avial">
                          <div>You have</div>
                          <div>
                            <span> {showBalance1}</span>
                            {showBalance2 !== "" && (
                              <span> and {showBalance2}</span>
                            )}
                          </div>
                        </div>
                      )}

                    {isWinnerRevealed &&
                      raffle["cancel_before_end"] === 0 &&
                      raffle1["winners"] !== undefined &&
                      raffle1["winners"].length > 0 && (
                        <div
                          className="winner-wrap"
                          onClick={() =>
                            openSolscan(raffle1["winners"][0]["address"])
                          }
                        >
                          {/* <div className="border"></div> */}
                          <div className="medal-box">
                            <i className="fas fa-medal"></i>
                          </div>
                          <h2>Winner</h2>
                          <div className="winner-ribbon">
                            {" "}
                            {raffle1["winners"][0]["address"].substring(0, 4)}
                            ...
                            {raffle1["winners"][0]["address"].slice(-4)}{" "}
                          </div>
                          <div className="right-ribbon"></div>
                          <div className="left-ribbon"></div>
                        </div>
                      )}
                    {isWinnerRevealed &&
                      (isPollTimeEnd ||
                        raffle1["total_entries"] === raffle1["max_entries"]) &&
                      publicKey &&
                      raffle["rafflePriceType"] === "nft" &&
                      raffle1["winners"] !== undefined &&
                      raffle1["winners"][0]["address"] ===
                        publicKey.toString() && (
                        <div className="row mb-2">
                          <div className="col">
                            {raffle1["pending"].includes(
                              publicKey.toString()
                            ) ? (
                              <>
                                <div className="text-yellow fs-4 mb-2">
                                  Congratulations! You Won the Raffle.{" "}
                                </div>
                                <button
                                  className="btn btn-raffle-detail"
                                  onClick={() => {
                                    claim_winner();
                                    setShowInlineLoader(true);
                                  }}
                                  disabled={showInlineLoader}
                                >
                                  {showInlineLoader ? (
                                    <>
                                      <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                      />{" "}
                                      Claiming
                                    </>
                                  ) : (
                                    "Claim Prize"
                                  )}
                                </button>
                              </>
                            ) : (
                              <div className="text-yellow text-center fs-4 mb-2 mt-5">
                                Congratulations!. You won the raffle and Your
                                claim was successful
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                    {publicKey &&
                    isWinnerRevealed &&
                    raffle1["winners"] !== undefined &&
                    raffle["rafflePriceType"] === "nft" &&
                    (publicKey.toString() === raffle1["creator"] ||
                      publicKey.toString() === admin_wallet) ? (
                      raffle1["pending"].includes(
                        raffle1["winners"][0]["address"]
                      ) ? (
                        <button
                          className="btn btn-raffle-detail"
                          onClick={() => {
                            ClaimByAdminOrCreator(
                              raffle1["winners"][0]["address"]
                            );
                            setShowInlineLoader(true);
                          }}
                          disabled={showInlineLoader}
                        >
                          {showInlineLoader ? (
                            <>
                              <Spinner
                                as="span"
                                variant="light"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                animation="border"
                              />{" "}
                              Transferring
                            </>
                          ) : (
                            "Transfer Prize"
                          )}
                        </button>
                      ) : (
                        <div className="claim-status">Prize Claimed</div>
                      )
                    ) : (
                      ""
                    )}
                    <div className="row mb-2 mt-4">
                      {!publicKey && (
                        <div className="col text-center">
                          <WalletMultiButton className="btn connect-wallet-btn connect-wallet-r-d-page fw-bolder">
                            Connect Wallet
                          </WalletMultiButton>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-9 col-md-12">
                    <div className="card raffle-detail-card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-8">
                            <div className=" me-6">
                              {raffle["rafflePriceType"] === "nft" ? (
                                <div className="detail-page-collection-name text-left pl-0">
                                  {raffle["collectionName"]}{" "}
                                  <img
                                    className="collection-img"
                                    src={collection_img}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="raf-name color-dark mb-0">
                                {raffle["raffleName"]}
                              </div>
                            </div>
                            {FloorPrice !== "" && FloorPrice !== null ? (
                              <div className="">
                                <span className="floor-price">
                                  Floor Price: {FloorPrice} ◎
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-4">
                            <div
                              className={`${
                                isMobile
                                  ? "buyer-guide mb-4"
                                  : "buyer-guide float-right"
                              }`}
                              onClick={() => {
                                setbuyerGuideModal(true);
                              }}
                            >
                              <i className="bi bi-info-square"></i> Buyer’s
                              guide
                            </div>
                          </div>
                        </div>

                        <div className="row pt-5">
                          <div className="col-xl-8">
                            <div className="d-flex">
                              <div
                                onClick={() => detail_page_tab_change("detail")}
                                className={`${
                                  detailPageActiveTab === "detail"
                                    ? "detail-page-tab active py-3 px-5 mx-2 mb-3"
                                    : "detail-page-tab py-3 px-5 mx-2 mb-3"
                                }`}
                              >
                                Details
                              </div>
                              <div
                                onClick={() =>
                                  detail_page_tab_change("participant")
                                }
                                className={`${
                                  detailPageActiveTab === "participant"
                                    ? "detail-page-tab active py-3 px-5 mx-2 mb-3"
                                    : "detail-page-tab  py-3 px-5 mx-2 mb-3"
                                }`}
                              >
                                Participants
                              </div>
                              {/* <div
                              onClick={() => detail_page_tab_change("winner")}
                              className={`${
                                detailPageActiveTab === "winner"
                                  ? "detail-page-tab active py-3 px-5 mx-2 mb-3"
                                  : "detail-page-tab py-3 px-5 mx-2 mb-3"
                              }`}
                            >
                              Winner
                            </div> */}
                            </div>
                          </div>
                        </div>
                        {detailPageActiveTab === "detail" && (
                          <>
                            <div className="detail-grid t-border pt-3">
                              <div className="">
                                <span className="raffle-detail-label">
                                  Ticket Cost
                                </span>

                                <div className="raffle-detail-text">
                                  {raffle["ticket_prize"]}{" "}
                                  {raffle["ticket_prize_type"]}
                                  {raffle["ticket_type"] === "multi" &&
                                    "  + " +
                                      raffle["ticket_prize_1"] +
                                      " " +
                                      raffle["ticket_prize_type_1"]}
                                  {/* {find_ticket_token(
                                raffle["ticket_price"],
                                raffle["ticket_mint"]
                              )} */}
                                </div>
                              </div>
                              <div className="">
                                <span className="raffle-detail-label ">
                                  Ticket Sold
                                </span>

                                <div className="raffle-detail-text">
                                  {raffle["ticket_bought"]}/
                                  {raffle["tickt_size"]}
                                </div>
                              </div>
                              <div className="">
                                <span className="raffle-detail-label">
                                  Created By
                                </span>

                                <div className="raffle-detail-text">
                                  {raffle["raffleType"] === "dao" && (
                                    <Link
                                      to={`/dao/${raffle["daoName"]}`}
                                      className="dao-link-raffle-detail-page"
                                    >
                                      {" "}
                                      {raffle["daoName"]}
                                    </Link>
                                  )}
                                  {raffle["raffleType"] === "individual" && (
                                    <Link
                                      to={`/individual/${raffle["individualNameorAddress"]}`}
                                      className="dao-link-raffle-detail-page"
                                    >
                                      {" "}
                                      {individualTwitterName !== "" ? (
                                        <div className="f-size-20 twitter-user-name">
                                          {individualTwitterName}
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                            className="inline-block w-6 ml-2 text-blue-400 fill-current"
                                          >
                                            <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                                          </svg>
                                        </div>
                                      ) : (
                                        raffle["individualNameorAddress"]
                                      )}
                                    </Link>
                                  )}
                                </div>
                              </div>
                              <div className="">
                                <span className="raffle-detail-label">
                                  {isPollTimeEnd
                                    ? "Ticket Sales Ended On"
                                    : "Ticket Sales Ends In"}
                                </span>

                                <div className="raffle-detail-text">
                                  {!isPollTimeEnd &&
                                    raffle["raffleEndTime"] !== undefined && (
                                      <>
                                        {" "}
                                        <MyTimer
                                          expiryTimestamp={raffle["timer"]}
                                        />
                                      </>
                                    )}
                                  {isPollTimeEnd && (
                                    <>
                                      {moment
                                        .unix(Number(raffle["raffleEndTime"]))
                                        .format("MMMM DD, YYYY")}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="">
                                <span className="raffle-detail-label">
                                  Start Date
                                </span>
                                {!isLoading && (
                                  <div className="raffle-detail-text">
                                    {moment(raffle["created_at"]).format(
                                      "MMMM DD, YYYY"
                                    )}
                                  </div>
                                )}
                              </div>
                              <div className="">
                                <span className="raffle-detail-label">
                                  Max Ticket Per Wallet
                                </span>

                                <div className="raffle-detail-text">
                                  {raffle1["max_tickets_per_wallet"] ===
                                  raffle1["max_entries"]
                                    ? "No Limit"
                                    : raffle1["max_tickets_per_wallet"]}
                                </div>
                              </div>
                            </div>
                            <div className="detail-grid-1">
                              <div className="">
                                <span className="raffle-detail-label">
                                  Minimum Threshold Value
                                </span>

                                <div className="raffle-detail-text">
                                  {raffle1["threshold_ticket"]}
                                  {raffle1["total_entries"] <
                                  raffle1["threshold_ticket"] ? (
                                    <>
                                      <i className="ticket-threshold-icon-warning fa fa-exclamation-circle"></i>
                                      <span className="ticket-threshold-warning-text">
                                        {" "}
                                        Ticket sales has not yet met the
                                        threshold value{" "}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <i className="ticket-threshold-icon-success fa fa-exclamation-circle"></i>
                                      <span className="ticket-threshold-success-text">
                                        {" "}
                                        Ticket sales met the minimum threshold
                                        value{" "}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                              {ticketBought > 0 && (
                                <div className="">
                                  <span className="raffle-detail-label">
                                    Ticket Bought
                                  </span>

                                  <div className="raffle-detail-text">
                                    {ticketBought}
                                    {isPollTimeEnd ? (
                                      ""
                                    ) : (
                                      <span className="winning-change">
                                        {"("}
                                        {(
                                          (100 /
                                            Number(raffle1["total_entries"])) *
                                          Number(ticketBought)
                                        ).toFixed(2)}
                                        {" % Winning Chance)"}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                        {detailPageActiveTab === "participant" && (
                          <div className="raffle-details-table-card">
                            <div className="paticipant-list t-border b-border pt-3">
                              <div className="table-responsive participant-table">
                                <table className="table align-middle gs-0 gy-4">
                                  <thead>
                                    <tr className="fw-bold text-muted">
                                      {/* <th className="text-blue">#</th> */}
                                      <th className="text-blue_dark min-w-150px">
                                        Wallet
                                      </th>
                                      <th className="text-blue_dark">
                                        Tickets Bought
                                      </th>
                                    </tr>
                                  </thead>
                                  {participants && (
                                    <tbody>
                                      {Object.entries(participants).map(
                                        (t, k) => (
                                          <>
                                            {t[0] !==
                                              "11111111111111111111111111111111" && (
                                              <tr>
                                                {/* <td className="text-white">{k + 1}</td> */}
                                                <td>
                                                  <div className="d-flex align-items-center">
                                                    <div className="d-flex justify-content-start flex-column">
                                                      <div className="text-table-black fw-bold fs-4">
                                                        {t[0]}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="text-left text-table-black fw-bold d-block fs-4">
                                                    {t[1]}
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </>
                                        )
                                      )}
                                    </tbody>
                                  )}
                                </table>
                              </div>
                            </div>
                          </div>
                        )}
                        {detailPageActiveTab === "winner" && (
                          <div className="raffle-details-table-card">
                            {raffle["cancel_before_end"] === 0 &&
                              raffle1["winners"] !== undefined &&
                              raffle1["winners"].length > 0 && (
                                <div className="t-border b-border pt-3">
                                  <div className="table-responsive">
                                    <table className="table align-middle gs-0 gy-4">
                                      <thead>
                                        <tr className="fw-bold text-muted">
                                          <th className="text-blue_dark min-w-150px">
                                            Wallet Address
                                          </th>

                                          <th className="text-blue_dark">
                                            Claim
                                          </th>
                                          {publicKey &&
                                            publicKey.toString() ===
                                              raffle1["creator"] &&
                                            raffle["rafflePriceType"] !==
                                              "nft" && (
                                              <th className="text-blue_dark">
                                                Discord Id
                                              </th>
                                            )}
                                        </tr>
                                      </thead>
                                      {raffle1["winners"] !== undefined &&
                                        raffle1["winners"].length > 0 && (
                                          <tbody>
                                            {raffle1["winners"].map((t, k) => (
                                              <>
                                                {t["address"] !==
                                                  "11111111111111111111111111111111" && (
                                                  <tr>
                                                    <td>
                                                      <div className="d-flex align-items-center">
                                                        <div className="d-flex justify-content-start flex-column">
                                                          <div className="text-table-black fw-bold  fs-4">
                                                            {t["address"]}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>

                                                    <td>
                                                      <div className="text-yellow fw-bold fs-4">
                                                        {" "}
                                                        {raffle1[
                                                          "pending"
                                                        ].includes(
                                                          t["address"]
                                                        ) ? (
                                                          publicKey &&
                                                          (publicKey.toString() ===
                                                            raffle1[
                                                              "creator"
                                                            ] ||
                                                            publicKey.toString() ===
                                                              admin_wallet) &&
                                                          raffle[
                                                            "rafflePriceType"
                                                          ] === "nft" ? (
                                                            <button
                                                              className="btn btn-raffle-detail"
                                                              onClick={() => {
                                                                ClaimByAdminOrCreator(
                                                                  t["address"]
                                                                );
                                                                setShowInlineLoader(
                                                                  true
                                                                );
                                                              }}
                                                              disabled={
                                                                showInlineLoader
                                                              }
                                                            >
                                                              {showInlineLoader ? (
                                                                <>
                                                                  <Spinner
                                                                    as="span"
                                                                    variant="light"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                    animation="border"
                                                                  />{" "}
                                                                  Transferring
                                                                </>
                                                              ) : (
                                                                "Transfer Prize"
                                                              )}
                                                            </button>
                                                          ) : (
                                                            "Not Claimed"
                                                          )
                                                        ) : (
                                                          "Claimed"
                                                        )}
                                                      </div>
                                                    </td>

                                                    {publicKey &&
                                                      publicKey.toString() ===
                                                        raffle1["creator"] &&
                                                      raffle[
                                                        "rafflePriceType"
                                                      ] !== "nft" && (
                                                        <td className="text-table-black fw-bold  fs-4">
                                                          {t["discord_id"]}
                                                        </td>
                                                      )}
                                                  </tr>
                                                )}
                                              </>
                                            ))}
                                          </tbody>
                                        )}
                                    </table>
                                  </div>
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {!isMobile && (
                  <div className="row gy-0 gx-10">
                    <div className="col-3">
                      <div className="d-flex">
                        <div className="py-2 px-4 me-6 mb-3">
                          {raffle["projectDiscordLink"] !== "" &&
                            raffle["projectDiscordLink"] !== undefined &&
                            raffle["projectDiscordLink"] !== null && (
                              <div className="d-flex">
                                <div className="discord-link">
                                  <a
                                    href={raffle["projectDiscordLink"]}
                                    target="_blank"
                                  >
                                    <img src={discord_img} alt="discord" />
                                  </a>
                                </div>
                                <div className="twitter-link">
                                  <a
                                    href={raffle["projectTwitterLink"]}
                                    target="_blank"
                                  >
                                    <img src={twitter_img} alt="twitter" />
                                  </a>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="mobile-view text-white fs-2">
        <img src={logo_white} />
        <div>Please use this app in desktop for better experience</div>
      </div>
      {isRefresh && (
        <div className="refresh-loader">
          <p>Refreshing Raffles</p>
          <div className="loader5"></div>
        </div>
      )}
    </>
  );
};
