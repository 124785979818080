import React, { useEffect, useState, useCallback } from "react";

import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {
  RaffleData,
  createBuyTicketsTransaction,
  createRevealWinnerTransaction,
  createRevealWLWinnersTransaction,
  createClaimPrizeTransaction,
  createCancelRaffleTransaction,
} from "../util/raffle";
import { PublicKey } from "@solana/web3.js";
import moment from "moment";
import "./dao.css";
import nft_img_1 from "../images/956.png";
import bacl_arrow_img from "../images/Arrow 3 (Stroke).png";
import collection_img from "../images/Group 193.png";
import live_raffle_img from "../images/time-not-end.png";
import ended_raffle_img from "../images/time-end.png";
import raffle_by_nft_img from "../images/nft.png";
import raffle_by_wallet_img from "../images/wallet-1.png";
import flame_img from "../images/Flame.png";
import no_raffle_img from "../images/empty-box.png";
import { useTimer } from "react-timer-hook";
import Swal from "sweetalert2";
import { PreLoader } from "../components/pre-loader";
import {
  Metadata,
  MetadataDataData,
} from "@metaplex-foundation/mpl-token-metadata";

function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => window.location.reload(),
  });

  return (
    <div
      style={{ textAlign: "center" }}
      className="raffle-time badge  fs-8 fw-bold"
    >
      <div id="countdown" className="countdownHolder">
        Ends In :{" "}
        <span className="countDays">
          <span className="position">
            <span className="digit static">{days}d</span>
          </span>
        </span>
        <span className="countDiv countDiv0"></span>
        <span className="countHours">
          <span className="position">
            {" "}
            <span className="digit static">{hours}h</span>{" "}
          </span>
        </span>
        <span className="countDiv countDiv1"></span>
        <span className="countMinutes">
          <span className="position">
            {" "}
            <span className="digit static">{minutes}m</span>{" "}
          </span>
        </span>
        <span className="countDiv countDiv1"></span>
        <span className="countSeconds">
          <span className="position">
            {" "}
            <span className="digit static">{seconds}s</span>{" "}
          </span>
        </span>
      </div>
    </div>
  );
}

export const Dao = () => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [raffle, setRaffle] = useState({});
  const [noOfTickets, setNoOfTickets] = useState(1);
  const [currentTab, setcurrentTab] = useState("details");
  const [notFound, setNotFound] = useState(false);
  const navigate = useNavigate();
  const [pastRaffle, setpastRaffle] = useState([]);
  const [recentRaffle, setrecentRaffle] = useState([]);
  const [daoName, setDaoName] = useState("");
  const [daoDescription, setDaoDescription] = useState("");
  const [daoImage, setdaoImage] = useState("");
  const [noOfNFTRaffle, setNoOfNFTRaffle] = useState(0);
  const [noOfWalletRaffle, setNoOfWalletRaffle] = useState(0);
  const [isPollTimeEnd, setisPollTimeEnd] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const ticket_list = JSON.parse(process.env.REACT_APP_TICKET_TOKEN);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  const sendAndConfirmTransaction = useCallback(
    async (transaction) => {
      let { blockhash } = await connection.getRecentBlockhash();
      transaction.feePayer = publicKey!;
      transaction.recentBlockhash = blockhash;

      let signature = await sendTransaction(transaction, connection);
      await connection.confirmTransaction(signature, "confirmed");

      // if (onChange) onChange();

      return signature;
    },
    [connection, publicKey, sendTransaction]
  );

  const get_raffle_by_dao = async (dao_name) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getAllRaffleByDaoName/" + dao_name,
        { mode: "cors" }
      );
      let data = await response.json();

      if (data.length > 0) {
        let a = [];
        let b = [];
        let nft_raffle = 0;
        let wallet_raffle = 0;

        for (const raffle of data.reverse()) {
          if (
            raffle["transactionHash"] !== null &&
            raffle["transactionHash"] !== ""
          ) {
            let t = raffle;
            // let t = await RaffleData(connection, raffle["raffle_id"]);
            // if (t !== undefined) {
            // t["id"] = raffle["id"];
            // t["raffle_id"] = raffle["raffle_id"];
            // t["raffleName"] = raffle["raffleName"];
            // t["collectionName"] = raffle["collectionName"];
            // t["raffleType"] = raffle["raffleType"];
            // t["rafflePriceType"] = raffle["rafflePriceType"];
            // t["imageData"] = raffle["imageData"];

            const time = new Date(Number(t.raffleEndTime) * 1000);

            t["timer"] = time.getTime();

            let now = moment.utc();
            var day = moment.unix(Number(t.raffleEndTime));
            var daysDiff = day.diff(now, "days");
            var hoursDiff = day.diff(now, "hours");
            var minutesDiff = day.diff(now, "minutes");
            var secondsDiff = day.diff(now, "seconds");

            if (daysDiff > 0) {
              t["raffle_ended"] = false;
            } else {
              if (hoursDiff > 0 || minutesDiff > 0 || secondsDiff > 0) {
                t["raffle_ended"] = false;
              } else {
                t["raffle_ended"] = true;
              }
            }

            if (raffle["rafflePriceType"] === "wallet") {
              wallet_raffle = wallet_raffle + 1;
            }
            if (raffle["rafflePriceType"] === "nft") {
              nft_raffle = nft_raffle + 1;
              // let tokenmetaPubkey = await Metadata.getPDA(
              //   new PublicKey(t["nft_mint_address"])
              // );

              // const tokenmeta = await Metadata.load(
              //   connection,
              //   tokenmetaPubkey
              // );

              // let img_response = await fetch(
              //   tokenmeta["data"]["data"]["uri"]
              // );
              // let img = await img_response.json();

              // t["nft_image"] = img["image"];
              // t["raffleName"] = img["name"];
            }
            if (t["raffle_ended"] === true || raffle["close"] === 1) {
              b.push(t);
            } else {
              a.push(t);
            }
            // }
          }
        }
        setNoOfNFTRaffle(nft_raffle);
        setNoOfWalletRaffle(wallet_raffle);
        setrecentRaffle(a);
        await setpastRaffle(b);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }

      // await setFoundUsers(data);
      // await setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const get_raffle = async (id) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getRaffle/" + id,
        { mode: "cors" }
      );
      const data = await response.json();
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    let url = window.location.href.split("/");
    if (url.length > 2) {
      if (url[3] === "dao") {
        let dao_name = url[4];
        setDaoName(dao_name);
        get_raffle_by_dao(dao_name);
        daoDetails(dao_name);
      }
    }
    // get_dao_by_id();
  }, [location]);

  const back_to_raffle_list = async () => {
    navigate("/");
  };

  const change_navigation_tab = async (participants) => {
    setcurrentTab(participants);
    return;
  };

  let daoDetails = async (dao_name) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getDaoDetails/" + dao_name,
        { mode: "cors" }
      );
      if (response.status === 200) {
        const data = await response.json();
        setDaoDescription(data["daoDescription"]);
        setdaoImage(data["imageData"]);
      }
    } catch (e) {}
  };

  let call_raffle_details = (param) => (e) => {
    if (param.close) {
      Swal.fire({
        text: "This Raffle was closed by creator.",
        confirmButtonText: "Close",
      });
    } else {
      navigate("/raffle/" + param.id);
    }
  };

  let find_ticket_token = (ticket_price, address) => {
    if (address !== undefined && address !== null) {
      let tokenType = ticket_list.find((o) => o.Address === address);

      let p = ticket_price / Math.pow(10, tokenType["Decimal"]);
      return p + " " + tokenType["Token_name"];
    }
  };

  return (
    <>
      {isLoading && <PreLoader />}
      {/* {!isLoading && ( */}
      <>
        {notFound === false ? (
          <>
            <div className="search-container-raffle-detail pb-5 card rounded-0 bgi-no-repeat bgi-position-x-end bgi-size-cover mb-9">
              <div className="container-xxl">
                <div className="row gy-0 gx-10">
                  <div className="col-xl-3 col-md-12">
                    <div
                      className="d-flex pointer"
                      onClick={() => back_to_raffle_list()}
                    >
                      <div className="py-2">
                        <div className="back-arrow">
                          <img src={bacl_arrow_img} />
                        </div>
                      </div>

                      <div className="min-w-100px w-100 py-2 px-4 mb-3">
                        <span className="raffle-detail-label">Back to</span>

                        <div className="raffle-detail-text text-v1">All Raffle</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-9 col-md-12">
                    <div className="dao-name fw-bold mb-0">{daoName}</div>
                  </div>
                </div>
                <div className="row gy-0 gx-10">
                  <div className="col-xl-3 col-md-12">
                    <img
                      alt="raffle_image"
                      src={daoImage !== null ? daoImage : nft_img_1}
                      className="dao-raffle-image"
                    />
                  </div>
                  <div className="col-xl-9 col-md-12">
                    <div className="card raffle-detail-card">
                      <div className="card-body">
                        <div className="dao-desc">{daoDescription}</div>
                        <div className="row g-5 g-xl-8">
                          <div className="col-xl-4">
                            <div className="d-flex align-items-center dao-head-details">
                              <div className="d-flex flex-column flex-grow-1">
                                <span className="dao-head-details-label">
                                  Raffles - Live
                                </span>
                                <span className="dao-head-details-text">
                                  {recentRaffle.length}
                                </span>
                              </div>
                              <img
                                src={live_raffle_img}
                                className="align-self-end"
                              />
                            </div>
                          </div>
                          <div className="col-xl-4">
                            <div className="d-flex align-items-center dao-head-details">
                              <div className="d-flex flex-column flex-grow-1">
                                <span className="dao-head-details-label">
                                  Raffles - Ended
                                </span>
                                <span className="dao-head-details-text">
                                  {pastRaffle.length}
                                </span>
                              </div>
                              <img
                                src={ended_raffle_img}
                                className="align-self-end"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row g-5 g-xl-8 mt-1">
                          <div className="col-xl-4">
                            <div className="d-flex align-items-center dao-head-details">
                              <div className="d-flex flex-column flex-grow-1">
                                <span className="dao-head-details-label">
                                  Raffles - NFT
                                </span>
                                <span className="dao-head-details-text">
                                  {noOfNFTRaffle}
                                </span>
                              </div>
                              <img
                                src={raffle_by_nft_img}
                                className="align-self-end"
                              />
                            </div>
                          </div>
                          <div className="col-xl-4">
                            <div className="d-flex align-items-center dao-head-details">
                              <div className="d-flex flex-column flex-grow-1">
                                <span className="dao-head-details-label">
                                  Raffles - Whitelist
                                </span>
                                <span className="dao-head-details-text">
                                  {noOfWalletRaffle}
                                </span>
                              </div>
                              <img
                                src={raffle_by_wallet_img}
                                className="align-self-end"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!isMobile && (
                  <div className="row gy-0 gx-10">
                    <div className="col-3">
                      <div className="d-flex">
                        <div className="py-2 px-4 me-6 mb-3">
                          {raffle["rafflePriceType"] === "nft" ? (
                            <div className="detail-page-collection-name text-left pl-0">
                              {raffle["collectionName"]}{" "}
                              <img
                                className="collection-img"
                                src={collection_img}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="raffle-detail-text fw-bold mb-0">
                            {raffle["raffleName"]}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6"></div>
                  </div>
                )}
              </div>
            </div>
            <div className="container-xxl dao-content-page" id="kt_content">
              <div className="row gy-5 g-xl-10 mt-5">
                <div className="col-xl-8">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="fw-bold text-white recent-raffle-text">
                      <img className="flame-img" src={flame_img} /> Raffles
                      Active - Latest
                      <img className="flame-img" src={flame_img} />
                    </span>
                  </h3>
                </div>

                <div className="col-xl-4">
                  <div className="card-toolbar mb-10">
                    {/* <div className="d-flex align-items-center float-right">
                    <span className="fs-7 fw-bold text-gray-700 pe-4 text-nowrap d-none d-xxl-block">
                      Sort By:
                    </span>

                    <select
                      className="form-select form-select-sm form-select-solid sort-by-select"
                      aria-hidden="true"
                    >
                      <option
                        value=""
                        data-select2-id="select2-data-128-3ifn"
                      ></option>
                      <option value="1" data-select2-id="select2-data-9-0hl4">
                        All
                      </option>
                      <option value="2" data-select2-id="select2-data-129-sfxc">
                        Individual
                      </option>
                      <option value="3" data-select2-id="select2-data-130-pd72">
                        DAO
                      </option>
                    </select>
                  </div> */}
                  </div>
                </div>
              </div>
              {recentRaffle.length > 0 && (
                <div className="row gy-5 g-xl-10 mt-5">
                  {recentRaffle.map((raffle, index) => (
                    <div className="col-xl-3">
                      <div
                        className={`${
                          publicKey &&
                          raffle["winnerList"] !== null &&
                          raffle["winnerList"]["list"].length === 1 &&
                          raffle["winnerList"]["list"][0] ===
                            publicKey.toString()
                            ? "card card-xl-stretch mb-xl-10 theme-dark-bg-body raffle-home-card winner"
                            : "card card-xl-stretch mb-xl-10 theme-dark-bg-body raffle-home-card"
                        }`}
                      >
                        <div className="card-body d-flex  flex-column">
                          <div className="raffle-img-on-card text-center">
                            {raffle["imageData"] !== null ? (
                              <img
                                alt="raffle_imagedd"
                                src={raffle["imageData"]}
                              />
                            ) : (
                              <img alt="raffle_image" src={nft_img_1} />
                            )}
                          </div>
                          {!raffle["raffle_ended"] && (
                            <MyTimer expiryTimestamp={raffle["timer"]} />
                          )}
                          {raffle["raffle_ended"] && (
                            <div className="raffle-time fs-8 fw-bold">
                              Ended On :{" "}
                              {moment
                                .unix(raffle["raffleEndTime"])
                                .format("MMM Do, YY")}
                            </div>
                          )}
                          {raffle["rafflePriceType"] === "nft" ? (
                            <div className="collection-name pl-0">
                              {raffle["collectionName"]}{" "}
                              <img
                                className="collection-img"
                                src={collection_img}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="raffle-name mb-0">
                            {raffle["raffleName"]}
                          </div>

                          {/* <div className="fw-semibold text-gray-400 mb-6 overflow">
                      {raffle["creator"]}
                    </div> */}

                          <div className="mb-1 ticket-container">
                            <div className="d-flex">
                              <div className="py-3 mx-2 tickets-sold">
                                <div className="fw-semibold ticket-sold-label">
                                  Tickets Sold
                                </div>
                                <div className="fs-6 fw-bold text-blue">
                                  <span className="ticket-sold">
                                    {" "}
                                    {raffle["ticket_bought"]}{" "}
                                  </span>
                                  /
                                  <span className="ticket-remain">
                                    {raffle["tickt_size"]}
                                  </span>
                                </div>
                              </div>

                              <div className="py-3 mx-2">
                                <div className="fw-semibold ticket-sold-label">
                                  Threshold
                                </div>
                                <div className="fs-6 fw-bold text-blue">
                                  <span className="ticket-sold">
                                    {" "}
                                    {Number(raffle["threshold"]).toFixed()}{" "}
                                    {raffle["ticket_bought"] <
                                    raffle["threshold"] ? (
                                      <>
                                        <i className="ticket-threshold-icon-warning fa fa-exclamation-circle"></i>
                                      </>
                                    ) : (
                                      <>
                                        <i className="ticket-threshold-icon-success fa fa-check-circle"></i>
                                      </>
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="py-2 mx-2">
                              <div className="fw-semibold ticket-price-label">
                                Ticket Price
                              </div>
                              <div className="fs-6 fw-bold">
                                <span className="ticket-price">
                                  {" "}
                                  {raffle["ticket_prize"]}{" "}
                                  {raffle["ticket_prize_type"]}
                                  {/* {find_ticket_token(
                                  raffle["ticket_price"],
                                  raffle["ticket_mint"]
                                )} */}
                                </span>
                                {raffle["ticket_type"] === "multi" && (
                                  <span className="ticket-price-2">
                                    {"+"} {raffle["ticket_prize_1"]}{" "}
                                    {raffle["ticket_prize_type_1"]}
                                    {/* {find_ticket_token(
                                  raffle["ticket_price"],
                                  raffle["ticket_mint"]
                                )} */}
                                  </span>
                                )}
                              </div>
                            </div>
                            {raffle["winnerList"] !== null &&
                            raffle["winnerList"]["list"].length === 1 ? (
                              <div className="py-2 mx-2">
                                <div className="fw-semibold ticket-price-label">
                                  Winner
                                </div>
                                <div className="fs-6 fw-bold">
                                  <span className="raffle-winner">
                                    {raffle["winnerList"]["list"][0].substring(
                                      0,
                                      4
                                    )}
                                    ...
                                    {raffle["winnerList"]["list"][0].slice(-4)}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <button
                            className="btn view-raffle-btn"
                            onClick={call_raffle_details(raffle)}
                          >
                            {publicKey &&
                            raffle["winnerList"] !== null &&
                            raffle["winnerList"]["list"].length === 1 &&
                            raffle["winnerList"]["list"][0] ===
                              publicKey.toString() ? (
                              <i className="bi bi-bookmark-star"></i>
                            ) : (
                              ""
                            )}
                            <span className="view-raffle-text">
                              {raffle.close === 1
                                ? "Raffle Closed"
                                : "View Raffle"}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {recentRaffle.length === 0 && (
                <div className="row gy-5 g-xl-10">
                  <div className="col-xl-12 text-center">
                    <img width="170" src={no_raffle_img} alt="no_raffle" />
                    <div className="fs-5 text-muted">No Active Raffles</div>
                  </div>
                </div>
              )}
              <div className="row gy-5 g-xl-10 mt-5">
                <div className="col-xl-8">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="fw-bold text-white recent-raffle-text">
                      <img className="flame-img" src={flame_img} /> Past Raffles
                      <img className="flame-img" src={flame_img} />
                    </span>
                  </h3>
                </div>
                <div className="col-xl-4">
                  <div className="card-toolbar mb-10">
                    {/* <div className="d-flex align-items-center float-right">
                    <span className="fs-7 fw-bold text-gray-700 pe-4 text-nowrap d-none d-xxl-block">
                      Sort By:
                    </span>

                    <select
                      className="form-select form-select-sm form-select-solid sort-by-select"
                      aria-hidden="true"
                    >
                      <option
                        value=""
                        data-select2-id="select2-data-128-3ifn"
                      ></option>
                      <option value="1" data-select2-id="select2-data-9-0hl4">
                        All
                      </option>
                      <option value="2" data-select2-id="select2-data-129-sfxc">
                        Individual
                      </option>
                      <option value="3" data-select2-id="select2-data-130-pd72">
                        DAO
                      </option>
                    </select>
                  </div> */}
                  </div>
                </div>
              </div>
              {pastRaffle.length !== 0 && (
                <div className="row gy-5 g-xl-10 mt-5">
                  {pastRaffle.map((raffle, index) => (
                    <div className="col-xl-3">
                      <div
                        className={`${
                          publicKey &&
                          raffle["winnerList"] !== null &&
                          raffle["winnerList"]["list"].length === 1 &&
                          raffle["winnerList"]["list"][0] ===
                            publicKey.toString()
                            ? "card card-xl-stretch mb-xl-10 theme-dark-bg-body raffle-home-card winner"
                            : "card card-xl-stretch mb-xl-10 theme-dark-bg-body raffle-home-card"
                        }`}
                      >
                        <div className="card-body d-flex  flex-column">
                          <div className="raffle-img-on-card text-center">
                            {raffle["imageData"] !== null ? (
                              <img
                                alt="raffle_imagedd"
                                src={raffle["imageData"]}
                              />
                            ) : (
                              <img alt="raffle_image" src={nft_img_1} />
                            )}
                          </div>
                          <div className="raffle-time fs-8 fw-bold">
                            Ended On :{" "}
                            {moment
                              .unix(raffle["raffleEndTime"])
                              .format("MMM Do, YY")}
                          </div>
                          {raffle["rafflePriceType"] === "nft" ? (
                            <div className="collection-name pl-0">
                              {raffle["collectionName"]}{" "}
                              <img
                                className="collection-img"
                                src={collection_img}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="raffle-name mb-0">
                            {raffle["raffleName"]}
                          </div>

                          {/* <div className="fw-semibold text-gray-400 mb-6 overflow">
                      {raffle["creator"]}
                    </div> */}

                          <div className="mb-1 ticket-container">
                            <div className="d-flex">
                              <div className="py-3 mx-2 tickets-sold">
                                <div className="fw-semibold ticket-sold-label">
                                  Tickets Sold
                                </div>
                                <div className="fs-6 fw-bold text-blue">
                                  <span className="ticket-sold">
                                    {" "}
                                    {raffle["ticket_bought"]}{" "}
                                  </span>
                                  /
                                  <span className="ticket-remain">
                                    {raffle["tickt_size"]}
                                  </span>
                                </div>
                              </div>

                              <div className="py-3 mx-2">
                                <div className="fw-semibold ticket-sold-label">
                                  Threshold
                                </div>
                                <div className="fs-6 fw-bold text-blue">
                                  <span className="ticket-sold">
                                    {" "}
                                    {Number(raffle["threshold"]).toFixed()}{" "}
                                    {raffle["ticket_bought"] <
                                    raffle["threshold"] ? (
                                      <>
                                        <i className="ticket-threshold-icon-warning fa fa-exclamation-circle"></i>
                                      </>
                                    ) : (
                                      <>
                                        <i className="ticket-threshold-icon-success fa fa-check-circle"></i>
                                      </>
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="py-2 mx-2">
                              <div className="fw-semibold ticket-price-label">
                                Ticket Price
                              </div>
                              <div className="fs-6 fw-bold">
                                <span className="ticket-price">
                                  {" "}
                                  {raffle["ticket_prize"]}{" "}
                                  {raffle["ticket_prize_type"]}
                                  {/* {find_ticket_token(
                                  raffle["ticket_price"],
                                  raffle["ticket_mint"]
                                )} */}
                                </span>
                                {raffle["ticket_type"] === "multi" && (
                                  <span className="ticket-price-2">
                                    {"+"} {raffle["ticket_prize_1"]}{" "}
                                    {raffle["ticket_prize_type_1"]}
                                    {/* {find_ticket_token(
                                  raffle["ticket_price"],
                                  raffle["ticket_mint"]
                                )} */}
                                  </span>
                                )}
                              </div>
                            </div>
                            {raffle["winnerList"] !== null &&
                            raffle["winnerList"]["list"].length === 1 ? (
                              <div className="py-2 mx-2">
                                <div className="fw-semibold ticket-price-label">
                                  Winner
                                </div>
                                <div className="fs-6 fw-bold">
                                  <span className="raffle-winner">
                                    {raffle["winnerList"]["list"][0].substring(
                                      0,
                                      4
                                    )}
                                    ...
                                    {raffle["winnerList"]["list"][0].slice(-4)}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <button
                            className="btn view-raffle-btn"
                            onClick={call_raffle_details(raffle)}
                          >
                            {publicKey &&
                            raffle["winnerList"] !== null &&
                            raffle["winnerList"]["list"].length === 1 &&
                            raffle["winnerList"]["list"][0] ===
                              publicKey.toString() ? (
                              <i className="bi bi-bookmark-star"></i>
                            ) : (
                              ""
                            )}
                            <span className="view-raffle-text">
                              {raffle.close === 1
                                ? "Raffle Closed"
                                : "View Raffle"}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {pastRaffle.length === 0 && (
                <div className="row gy-5 g-xl-10 mb-5">
                  <div className="col-xl-12 text-center">
                    <img width="170" src={no_raffle_img} alt="no_raffle" />
                    <div className="fs-5 text-muted">No Past Raffles</div>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div>
            <svg
              className="svg-not-found"
              width="380px"
              height="500px"
              viewBox="0 0 837 1045"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <path
                  d="M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z"
                  id="Polygon-1"
                  stroke="#007FB2"
                  strokeWidth="6"
                ></path>
                <path
                  d="M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z"
                  id="Polygon-2"
                  stroke="#EF4A5B"
                  strokeWidth="6"
                ></path>
                <path
                  d="M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z"
                  id="Polygon-3"
                  stroke="#795D9C"
                  strokeWidth="6"
                ></path>
                <path
                  d="M639,529 L773,607.846761 L773,763.091627 L639,839 L505,763.091627 L505,607.846761 L639,529 Z"
                  id="Polygon-4"
                  stroke="#F2773F"
                  strokeWidth="6"
                ></path>
                <path
                  d="M281,801 L383,861.025276 L383,979.21169 L281,1037 L179,979.21169 L179,861.025276 L281,801 Z"
                  id="Polygon-5"
                  stroke="#36B455"
                  strokeWidth="6"
                ></path>
              </g>
            </svg>
            <div className="message-box">
              <h1 className="text-white">404</h1>
              <p>Page not found</p>
              <div className="buttons-con">
                <div className="action-link-wrap">
                  <Link
                    className="btn create-dao-btn fw-bolder w-100 w-lg-auto"
                    to={{ pathname: "/" }}
                    state={{ from: "dao-detail" }}
                  >
                    <i className="bi bi-home"></i>
                    Go to Home Page
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
      {/* )} */}
    </>
  );
};
